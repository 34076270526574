import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { DeleteForever } from '@mui/icons-material';
import ResponsiveDialog from '../Popup/Confirm';
import { useSelector } from 'react-redux';

function DeleteButton({ entityType, entityId, entityName, index, handleDeleteRow, titleName }) {
  const userPermissions = useSelector((state)=>state.userPermissions)
  const canEdit = userPermissions[entityType] && userPermissions[entityType].delete;
  if (canEdit) {
    return (
      <ResponsiveDialog
        name={entityName}
        id={entityId}
        index={index}
        head={
          <Tooltip arrow placement="left" title={`Delete ${titleName}`}>
            <DeleteForever />
          </Tooltip>
        }
        action="delete"
        onPress={handleDeleteRow}
      ></ResponsiveDialog>
    );
  }

  return null;
}

export default DeleteButton;