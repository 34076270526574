import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { EditSettings } from '../../api/settings';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './style';
import { getUserRoles } from "../../api/userRole";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const SettingsForm = (props) => {
    const [formData, setFormData] = useState({
        admin_email: "",
        signup_user_role: '',
        facebook: "",
        twitter: "",
        youtube: "",
        instagram: "",
    });
    
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { classes } = useStyles();
    const [roleList, setRoleList] = useState([]);

    async function fetchRoleData() {
        const roleData = await getUserRoles();
        if (roleData?.data) {
          setRoleList(roleData.data);
        }
      }

    useEffect(() => {
        fetchRoleData();
        if (props.settingsData.admin_email) {
            setFormData(props.settingsData);
        }
    }, []);

    const updateData = async (formData) => {
        try {
            dispatch(startLoading());
            // Make the API call to update the data
            const response = await EditSettings(formData);
            // Check if the API call was successful
            switch (response.status) {
                case "success":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "success" })
                    break;
                case "failed":
                    dispatch(stopLoading());
                    enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
                    break;
                case "error":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "error" });
                    break;    
                default:
                    dispatch(stopLoading());
                    console.log('Error updating data');
                    break;
            }
        } catch (error) {
            // Handle any network or API call errors
            console.log('An error occurred', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData, [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateData(formData);
    };

    const isRoleValid = roleList.some((role) => role.id === formData.signup_user_role);

    return (
        <>
            <Grid container spacing={2}>
                {isLoading && <Loader />}
                <Paper className={classes.paperCss}>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                label="Admin Email"
                                value={formData.admin_email}
                                onChange={handleInputChange}
                                id="admin_email"
                                name="admin_email"
                                margin="normal"
                                type="email"
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <InputLabel id="role-label">Signup Role</InputLabel>
                                <Select
                                labelId="role-label"
                                className={classes.selectBoxCss}
                                name="signup_user_role"
                                label="Signup Role"
                                value={isRoleValid ? formData.signup_user_role : ''}
                                onChange={handleInputChange}
                                >
                                {roleList.map((role) => (
                                    <MenuItem value={role.id} key={role.id}>{role.name}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={classes.gridTextInputCss}
                                label="Facebook"
                                value={formData.facebook}
                                onChange={handleInputChange}
                                name="facebook"   
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={classes.gridTextInputCss}
                                label="Twitter"
                                value={formData.twitter}
                                onChange={handleInputChange}
                                name="twitter"
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={classes.gridTextInputCss}
                                label="Youtube"
                                value={formData.youtube}
                                onChange={handleInputChange}
                                name="youtube"
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={classes.gridTextInputCss}
                                label="Instagram"
                                value={formData.instagram}
                                onChange={handleInputChange}
                                name="instagram"
                            />
                        </Grid>
                    </Box>
                    <Grid className={classes.gridButtonCss}>
                        <Button onClick={handleSubmit} variant="contained" color="primary">
                           Update
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default SettingsForm