
import {useStyles} from "./error.style"

export function ErrorPage() {
  const {classes}= useStyles();

    return (
      <div className={classes.root}>
      <h1 className={classes.errorMessage}>Oops!</h1>
      <h2 className={classes.errorStatus}>404 Not Found</h2>
     <p className={classes.errorDescription}>The link you followed may be broken, or the page may have been removed.</p> 
    </div>
  );

  
}

