import React, { useState, useEffect } from 'react';
import DestinationForm from './form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/destination';
import DestinationMedia from './DestinationMedia';
import DestinationFaq from './DestinationFaq';

const UpdateDestination = () => {
  const { id } = useParams();
  const [destinationData, setDestination] = useState();

  const fetchdestinationSingleData = async () => {
    try {
      const response = await SingleList(id);
      setDestination(response.data);
    } catch (error) {
      console.error('Error fetching destination data:', error);
    }
  };

  useEffect(() => {
    fetchdestinationSingleData();
  }, []);

  if (destinationData === undefined) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <DestinationForm destinationId={id} destinationData={destinationData} />
      <DestinationMedia destinationId={id} />
      <DestinationFaq destinationId={id} />
    </>
  );
};

export default UpdateDestination;
