import React, { useState, useEffect } from 'react';
import SettingsForm from './form';
import { SingleList } from '../../api/settings';

const UpdateSettings = () => {
  const [settingsData, setSettingsData] = useState();

  const fetchSettingsSingleData = async () => {
    try {
      const response = await SingleList();
      setSettingsData(response.data);
    } catch (error) {
      console.error('Error fetching Settings data:', error);
    }
  };
  useEffect(() => {
    fetchSettingsSingleData();
  }, []);

  if (settingsData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <SettingsForm settingsData={settingsData} />
    </>
  );
};

export default UpdateSettings;
