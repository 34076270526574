import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import { GetJob, DeleteJob } from "../../api/job";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { useStyles } from './Style';
import { Box, IconButton, Tooltip, Button, Paper, Typography } from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material";
import { ApiConfig } from "../../api/config/ApiConfig";
import ResponsiveDialog from "../../common/Popup/Confirm";
import EditButton from '../../common/EditButton/EditButton';
import DeleteButton from '../../common/DeleteButton/DeleteButton';
import CreateButton from '../../common/CreateButton/CreateButton';
import { useSelector } from 'react-redux';

const JobsListing = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [validationErrors, setValidationErrors] = useState({});
  const MemoizedMaterialReactTable = memo(MaterialReactTable);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const userPermissions = useSelector((state)=>state.userPermissions)

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { baseUrl, getJobsListing } = ApiConfig;


  const fetchInfo = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const page = pagination.pageIndex + 1;
    const per_page = pagination.pageSize;
    const url = new URL(
      getJobsListing,
      baseUrl,
    );
    url.searchParams.set(
      'page',
      `${page}`,
    );
    url.searchParams.set('per_page', `${per_page}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('globalFilter', globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    const response = await GetJob(url);
    switch (response.status) {
      case "success":
        const { data, totalCount } = await response;
        setData(data);
        setRowCount(totalCount);
        break;
      case "failed":
        enqueueSnackbar(response.message, { variant: "error" });
        break;
      default:
        dispatch({ type: "API_ERROR", payload: response });
        navigate("/admin/error");
        break;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  useEffect(() => {
    fetchInfo();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
      },
      {
        accessorKey: "is_active",
        header: "Active",
      },

    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (clicked, id, index,name) => {
      if (clicked) {
        const response = await DeleteJob(id);
        // ResponsiveDialog("delete",id)
        switch (response.status) {
          case "success":
            enqueueSnackbar(response.message, { variant: "success" });
            data.splice(index, 1);
            setData([...data]);
            fetchInfo();
            break;
          case "failed":
            enqueueSnackbar(response.message, { variant: "warning" });
            break;
          case "error":
            enqueueSnackbar(response.message, { variant: "error" });

            break;
          default:
            // dispatch({ type: "API_ERROR", payload: response });
            // navigate("/admin/error");
            break;
        }
      }
    },
    [dispatch, navigate, data]
  );

  return (
    <>
      <Paper className={classes.paperRoles}>
        <Typography gutterBottom className={classes.paperusers}>
          Jobs
        </Typography>
        <CreateButton entityType="jobs" titleName="Job" />
      </Paper>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableEditing={
          userPermissions?.jobs &&
          userPermissions.jobs.delete ||
          userPermissions.jobs.edit
            ? true
            : false
        }
        getRowId={(row) => row.id}
        initialState={{ showColumnFilters: false }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error loading data',
            }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <EditButton entityType="jobs" entityId={row.original.id} titleName="Job" />
            <DeleteButton entityType="jobs" entityId={row.original.id} entityName={row.original.name} index={row.index} handleDeleteRow={handleDeleteRow} titleName="Job" />
          </Box>
        )}
      />
    </>
  )
}

export default JobsListing