import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, FormControl, CircularProgress, MenuItem, Typography, Autocomplete } from '@mui/material';
import { InsertVillage, EditVillage } from '../../api/village';
import { GetState } from '../../api/statepage';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './style';
import { ApiConfig } from "../../api/config/ApiConfig";
import { GetCountry } from '../../api/country';
import { GetCity } from "../../api/citypage"
import { mediaBaseUrl } from '../../constants';
import LocationSearch from '../../common/SearchLocation/LocationSearch';
import { slugify } from '../../helper';
import DescriptionEditor from "../../common/TextEditor/DescriptionEditor";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const { baseUrl, getState, getCountry, getCity } = ApiConfig;

const VillageForm = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading)
    const [formData, setFormData] = useState({
        name: "",
        slug: "",
        country_id: "",
        state_id: "",
        city_id: "",
        latitude: '',
        longitude: '',
        image_upload: "",
        featured_image_name: "",
        description: ""
    });

    const [data, setData] = useState([]);
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [location, setLocation] = useState({
        lat: null,
        lng: null,
    });
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { classes } = useStyles();

    async function fetchCountryData() {
        const url = new URL(
            getCountry,
            baseUrl,
        );
        dispatch(startLoading());
        const fullUrl = `${url}?per_page=1000`
        const countryData = await GetCountry(fullUrl);
        switch (countryData.status) {
            case "success": 
                dispatch(stopLoading());
                setCountryList(countryData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(countryData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: countryData });
              navigate("/admin/error");
              break;
        }
    }

    async function fetchStateData(country_id) {
        const url = new URL(
            getState,
            baseUrl,
        );
        dispatch(startLoading());
        const fullUrl = `${url}?country_id=${country_id}&per_page=1000`
        const stateData = await GetState(fullUrl);
        if (stateData?.data) {
            setStateList(stateData.data);
        }
        switch (stateData.status) {
            case "success": 
                dispatch(stopLoading());
                setStateList(stateData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(stateData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: stateData });
              navigate("/admin/error");
              break;
        }
    }

    async function fetchCityData(state_id) {
        const url = new URL(
            getCity,
            baseUrl,
        );
        dispatch(startLoading());
        const fulUrl = `${url}?state_id=${state_id}&per_page=1000`
        const cityData = await GetCity(fulUrl);
        switch (cityData.status) {
            case "success": 
                dispatch(stopLoading());
                setCityList(cityData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(cityData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: cityData });
              navigate("/admin/error");
              break;
        }
    }

    useEffect(() => {
        fetchCountryData()
        if (props.villageId) {
            setIsEditing(true);
            if (props.villageData.country_id) {
                fetchStateData(props.villageData.country_id);
            }
            setFormData(props.villageData);
        }
        if (formData.state_id) {
            fetchCityData(formData.state_id);
        }
    }, [formData.state_id]);

    const insertData = async (formData) => {
        try {
            dispatch(startLoading());
            const newData = { ...formData };
            setData((prevData) => [...prevData, newData]);
            // Make the API call to update the data
            const response = await InsertVillage(newData);
            // Check if the API call was successful
            switch (response.status) {
                case "success":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/admin/village');
                    break;
                case "failed":
                    dispatch(stopLoading());
                    enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
                    break;
                case "error":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "error" });
                    break;
                default:
                    dispatch(stopLoading());
                    console.log('Error insert data');
                    break;
            }
        } catch (error) {
            dispatch(stopLoading());
            // Handle any network or API call errors
            console.log('An error occurred', error);
        }
    };

    const updateData = async (formData) => {
        try {
            dispatch(startLoading());
            // Make the API call to update the data
            const response = await EditVillage(formData);
            // Check if the API call was successful
            switch (response.status) {
                case "success":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/admin/village');
                    break;
                case "failed":
                    dispatch(stopLoading());
                    enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
                    break;
                case "error":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "error" });
                    break;
                default:
                    dispatch(stopLoading());
                    console.log('Error updating data');
                    break;
            }
        } catch (error) {
            dispatch(stopLoading());
            // Handle any network or API call errors
            console.log('An error occurred', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image_upload") {
            setFormData((prevData) => ({
                ...prevData,
                image_upload: files[0], // Store the selected file in the formData
            }));
        } else if( name === 'slug') {
            setFormData((prevData) => ({
              ...prevData,
              slug: slugify(value),
            }));
          } else {
            // For other inputs (non-file inputs), update the form data as before
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
        if (name === "country_id") {
            fetchStateData(value);
        }
    };

    const handleTextEditorChange = (content) => {
        setFormData((prevData) => ({
            ...prevData,
            description: content,
        }));
      }; 

    const handleLocationChange = (latLng) => {
        setLocation(latLng);

        setFormData((prevData) => ({
            ...prevData,
            latitude: latLng.lat,
            longitude: latLng.lng,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const isFormValid = validateForm(formData);
        if (isFormValid) {
            if (isEditing) {
                // Handle update logic
                updateData(formData);
            } else {
                // Handle create logic
                insertData(formData);
            }
        } else {
            enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
        }
    };

    const validateForm = (formData) => {
        // Perform validation checks based on your requirements
        const { name, country_id, state_id, city_id, } = formData;

        // Check if required fields are filled
        if (!name || !country_id || !state_id || !city_id ) {
            return false;
        }

        // Additional validation checks...

        return true;
    };

    return (
        <>
            <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
                <Typography gutterBottom className={classes.paperusers}>
                    {isEditing ? 'Edit Town/Village' : 'Create Town/Village'}
                </Typography>
            </Paper>
            <Grid container spacing={0}>
                {isLoading && <Loader />}
                <Paper className={classes.paperCss}>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                label="Name"
                                value={formData.name}
                                onChange={handleInputChange}
                                id="name"
                                name="name"
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={classes.gridTextInputCss}
                                label="Slug"
                                value={formData.slug}
                                onChange={handleInputChange}
                                id="slug"
                                name="slug"
                                required
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <Autocomplete
                                    options={countryList}
                                    getOptionLabel={(country) =>
                                        country.name
                                    }
                                    clearOnEscape
                                    clearOnBlur
                                    value={countryList.find((country) => country.id === formData.country_id) || null}
                                    onChange={(event, newValue) => {
                                        if (newValue?.id) {
                                            handleInputChange({ target: { name: 'country_id', value: newValue ? newValue.id : '' } });
                                        } else {
                                            setFormData(prev => ({ ...prev, state_id: "", country_id: '' }))
                                            setStateList([])
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        className={classes.selectBoxCss}
                                        name="country_id"
                                        label="Country"
                                    />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <Autocomplete
                                    options={stateList}
                                    getOptionLabel={(state) =>
                                        state.name
                                    }
                                    clearOnEscape
                                    clearOnBlur
                                    value={stateList.find((state) => state.id === formData.state_id) || null}
                                    onChange={(event, newValue) => {
                                        if (newValue?.id) {
                                            handleInputChange({ target: { name: 'state_id', value: newValue ? newValue.id : '' } });
                                        } else {
                                            setFormData(prev => ({ ...prev, city_id: "", state_id: '' }))
                                            setCityList([])
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        className={classes.selectBoxCss}
                                        name="state_id"
                                        label="State"
                                    />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <Autocomplete
                                    options={cityList}
                                    getOptionLabel={(city) =>
                                        city.name
                                    }
                                    clearOnEscape
                                    clearOnBlur
                                    value={cityList.find((city) => city.id === formData.city_id) || null}
                                    onChange={(event, newValue) => {
                                        if (newValue?.id) {
                                            handleInputChange({ target: { name: 'city_id', value: newValue ? newValue.id : '' } });
                                        } else {
                                            setFormData(prev => ({ ...prev, city_id: '' }))
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        className={classes.selectBoxCss}
                                        name="city_id"
                                        label="City"
                                    />}
                                />
                            </FormControl>
                        </Grid>
                        <LocationSearch onLocationChange={handleLocationChange} />
                        <Grid className={classes.gridCss}>
                        <TextField
                            className={classes.gridTextInputCss}
                            label="Lattitude"
                            value={formData.latitude}
                            onChange={handleInputChange}
                            name="latitude"
                        />
                        </Grid>
                        <Grid className={classes.gridCss}>
                        <TextField
                            className={classes.gridTextInputCss}
                            label="longitude"
                            value={formData.longitude}
                            onChange={handleInputChange}
                            name="longitude"
                        />
                        </Grid>
                        <Grid className={classes.gridCss} style={{ width: '98%' }}>
                            <DescriptionEditor content={formData.description} handleEditorChange={handleTextEditorChange} />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                inputProps={{ accept: 'image/*' }}
                                label="Image"
                                onChange={handleInputChange}
                                name="image_upload"
                                multiple
                                type="file"
                            />
                            {formData.featured_image_name &&
                                    <img src={mediaBaseUrl + 'uploads/' + formData.featured_image_name} width={200} />
                            }
                        </Grid>
                    </Box>
                    <Grid className={classes.gridButtonCss}>
                        <Button onClick={handleSubmit} variant="contained" color="primary">
                            {isEditing ? 'Update' : 'Create'}
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default VillageForm
