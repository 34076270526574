import React, { useState, useEffect } from 'react';
import CountryForm from '../Country/Form';


const CreateEntity = () => {
    return (
    <>
      <CountryForm />
    </>  
  )
}

export default CreateEntity