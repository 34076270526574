import React, { useState, useEffect } from 'react';
import DestinationForm from "./form";


const CreateDestination = () => {
    return (
    <>
      <DestinationForm />
    </>  
  )
}

export default CreateDestination