import React, { useState, useEffect } from 'react';
import CountryForm from '../Country/Form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/country';

const UpdateCountry = () => {
  const { id } = useParams();
  const [countryData, setCountryData] = useState();

  const fetchCountrySingleData = async () => {
    try {
      const response = await SingleList(id);
      setCountryData(response.data);
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  };

  useEffect(() => {
    fetchCountrySingleData();
  }, []);

  if (countryData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <CountryForm countryId={id} countryData={countryData} />
    </>
  );
};

export default UpdateCountry;
