import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Grid, Paper, Box, FormControl, InputLabel, Select, MenuItem, Typography, Autocomplete, CircularProgress } from '@mui/material';
import { InsertDestination } from '../../api/destination';
import { getStateListing } from "../../api/state";
import { getCityListing } from "../../api/city";
import { GetDestinationTypeList } from "../../api/destinationType";
import { GetTags } from '../../api/tags';
import { GetVillage } from "../../api/village";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Style';
import { EditDestination } from '../../api/destination';
import { ApiConfig } from "../../api/config/ApiConfig";
import { mediaBaseUrl } from '../../constants';
import LocationSearch from '../../common/SearchLocation/LocationSearch';
import { slugify } from '../../helper';
import DescriptionEditor from "../../common/TextEditor/DescriptionEditor";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const DestinationForm = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading)

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    slug: "",
    details: "",
    state_id: "",
    city_id: "",
    village_id: "",
    latitude: "",
    longitude: "",
    destination_type: [],
    destination_tags: [],
    parking_available: "",
    locker_available: "",
    address: "",
    mondayOpeningTime:"",
    mondayClosingTime:"",
    tuesdayOpeningTime:"",
    tuesdayClosingTime:"",
    wednesdayOpeningTime:"",
    wednesdayClosingTime:"",
    thursdayOpeningTime:"",
    thursdayClosingTime:"",
    fridayOpeningTime:"",
    fridayClosingTime:"",
    saturdayOpeningTime:"",
    saturdayClosingTime:"",
    sundayOpeningTime:"",
    sundayClosingTime:"",   
    image_upload: "",
    featured_image_name: "",
    visit_hour: "",
    is_parent: false
  });

  const [data, setData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [villageList, setvillageList] = useState([]);
  const [destinationTypeList, setDestinationTypeList] = useState([]);
  const [destinationTagList, setDestinationTagList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  })
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { classes } = useStyles();


  async function fetchStateData() {
    dispatch(startLoading());
    const stateData = await getStateListing({ country_id: 1 });
    switch (stateData.status) {
      case "success": 
          dispatch(stopLoading());
          setStateList(stateData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(stateData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: stateData });
        navigate("/admin/error");
        break;
    }
  }

  async function fetchCityData(state_id) {
    dispatch(startLoading());
    const cityData = await getCityListing({ state_id });
    switch (cityData.status) {
      case "success": 
          dispatch(stopLoading());
          setCityList(cityData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(cityData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: cityData });
        navigate("/admin/error");
        break;
    }
  }

  async function fetchDestinationTypeData() {
    dispatch(startLoading());
    const typeData = await GetDestinationTypeList();
    switch (typeData.status) {
      case "success": 
          dispatch(stopLoading());
          setDestinationTypeList(typeData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(typeData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: typeData });
        navigate("/admin/error");
        break;
    }
  }
  const { baseUrl, getVillage, uploadMedialisting, getTags } = ApiConfig;

  async function fetchDestinationTagData() {
    dispatch(startLoading());
    const url = new URL(
      getTags,
      baseUrl,
    );
    const tagData = await GetTags(url);
    switch (tagData.status) {
      case "success": 
          dispatch(stopLoading());
          setDestinationTagList(tagData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(tagData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: tagData });
        navigate("/admin/error");
        break;
    }
  }


  async function fetchVillageData(city_id) {
    dispatch(startLoading());
    const url = `${baseUrl}${getVillage}?city_id=${city_id}`;
    const vilageData = await GetVillage(url);
    switch (vilageData.status) {
      case "success": 
          dispatch(stopLoading());
          setvillageList(vilageData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(vilageData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: vilageData });
        navigate("/admin/error");
        break;
    }
  }

  useEffect(() => {
    fetchStateData();
    fetchDestinationTypeData();
    fetchDestinationTagData();
    if (props.destinationId) {
      setIsEditing(true);
      if (props.destinationData.state_id) {
        fetchCityData(props.destinationData.state_id);
      }

      if (props.destinationData.city_id) {
        fetchVillageData(props.destinationData.city_id);
      }

      function dayOfWeekToField(dayOfWeek) {
        switch (dayOfWeek) {
          case 1:
            return "monday";
          case 2:
            return "tuesday";
          case 3:
            return "wednesday";
          case 4:
            return "thursday";
          case 5:
            return "friday";
          case 6:
            return "saturday";
          case 7:
            return "sunday";
          default:
            return "";
        }
      }      

      const updatedFormData = { ...formData };
      props.destinationData.destination_schedule.forEach(schedule => {
        const { dayOfWeek, opening_time, closing_time } = schedule;

        const openingTimeField = `${dayOfWeekToField(dayOfWeek)}OpeningTime`;
        const closingTimeField = `${dayOfWeekToField(dayOfWeek)}ClosingTime`;

        updatedFormData[openingTimeField] = opening_time;
        updatedFormData[closingTimeField] = closing_time;
      });

      // Combine updatedFormData and props.destinationData
      const combinedData = {
        ...updatedFormData,
        ...props.destinationData,
      };
      setFormData(combinedData);
    }
  }, []);

  const insertData = async (formData) => {
    try {
      dispatch(startLoading());
      const newData = { ...formData };
      setData((prevData) => [...prevData, newData]);
      // Make the API call to update the data
      const response = await InsertDestination(newData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" });
          navigate('/admin/destination');
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
    setIsSubmitting(false)
  };

  const updateData = async (formData) => {
    try {
      dispatch(startLoading());
      // Make the API call to update the data
      const response = await EditDestination(formData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" });
          navigate('/admin/destination');
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
    setIsSubmitting(false)
  };

  const handleInputChange = (e) => {

    const { name, value, type, checked, files } = e.target;
    // return
    if (name === "image_upload") {
      setFormData((prevData) => ({
        ...prevData,
        image_upload: files[0],
      }));
    } else if( name === 'slug') {
      setFormData((prevData) => ({
        ...prevData,
        slug: slugify(value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === 'is_parent' ? (checked ? 1 : 0) : value,
      }));
    }

    if (name === "state_id") {
      fetchCityData(value);
    }
    if (name === "city_id") {
      fetchVillageData(value);
    }
  };

  const handleTextEditorChange = (content) => {
    setFormData((prevData) => ({
        ...prevData,
        details: content,
    }));
  };   

  const handleLocationChange = (latLng) => {
    setLocation(latLng);

    setFormData((prevData) => ({
      ...prevData,
      latitude: latLng.lat,
      longitude: latLng.lng,
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true)
    if (isEditing) {
      // Handle update logic
      updateData(formData);
    } else {
      // Handle create logic
      insertData(formData);
    }
  };

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    const commonOpeningTime = formData.mondayOpeningTime
    const commonClosingTime = formData.mondayClosingTime
    if (isChecked && commonOpeningTime && commonClosingTime ) {
      setCheckAll(true);
      setFormData((prevData) => ({
        ...prevData,
        tuesdayOpeningTime: commonOpeningTime,
        tuesdayClosingTime: commonClosingTime,
        wednesdayOpeningTime: commonOpeningTime,
        wednesdayClosingTime: commonClosingTime,
        thursdayOpeningTime: commonOpeningTime,
        thursdayClosingTime: commonClosingTime,
        fridayOpeningTime: commonOpeningTime,
        fridayClosingTime: commonClosingTime,
        saturdayOpeningTime: commonOpeningTime,
        saturdayClosingTime: commonClosingTime,
        sundayOpeningTime: commonOpeningTime,
        sundayClosingTime: commonClosingTime,
      }));  
    } else if (!isChecked) {
      setCheckAll(false);
      setFormData((prevData) => ({
        ...prevData,
        tuesdayOpeningTime: '',
        tuesdayClosingTime: '',
        wednesdayOpeningTime: '',
        wednesdayClosingTime: '',
        thursdayOpeningTime: '',
        thursdayClosingTime: '',
        fridayOpeningTime: '',
        fridayClosingTime: '',
        saturdayOpeningTime: '',
        saturdayClosingTime: '',
        sundayOpeningTime: '',
        sundayClosingTime: '',
      }));
    } else {
      enqueueSnackbar("Please choose monday Opening and closing time for fill all time", { variant: "warning" });
    }
  }

  return (
    <>
      <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
        <Typography gutterBottom className={classes.paperusers}>
          {isEditing ? 'Edit Destination' : 'Create Destination'}
        </Typography>
      </Paper>
      <Grid container spacing={0}>
        {isLoading && <Loader />}
        <Paper className={classes.paperCss}>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid className={classes.gridCss}>
              <TextField
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                label="Name"
                value={formData.name}
                onChange={handleInputChange}
                id="name"
                name="name"
                margin="normal"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="Slug"
                value={formData.slug}
                onChange={handleInputChange}
                id="slug"
                name="slug"
                required
              />
            </Grid>
            <Grid className={classes.gridCss} style={{ width: '98%' }}>
              <DescriptionEditor content={formData.details ? formData.details : ''} handleEditorChange={handleTextEditorChange} />
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <Autocomplete
                  options={stateList}
                  getOptionLabel={(state) =>
                    state.name
                  }
                  clearOnEscape
                  clearOnBlur
                  value={stateList.find((state) => state.id === formData.state_id) || null}
                  onChange={(event, newValue) => {
                    if (newValue?.id) {
                      handleInputChange({ target: { name: 'state_id', value: newValue ? newValue.id : '' } });
                    } else {
                      setFormData(prev => ({ ...prev, city_id: "", state_id: '' }))
                      setCityList([])
                    }
                  }}
                  renderInput={(params) => <TextField {...params}
                    className={classes.selectBoxCss}
                    name="state_id"
                    label="State"
                  />}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <Autocomplete
                  options={cityList}
                  getOptionLabel={(city) =>
                    city.name
                  }
                  clearOnEscape
                  clearOnBlur
                  value={cityList.find((city) => city.id === formData.city_id) || null}
                  onChange={(event, newValue) => {
                    if (newValue?.id) {
                      handleInputChange({ target: { name: 'city_id', value: newValue ? newValue.id : '' } });
                    } else {
                      setFormData(prev => ({ ...prev, village_id: "", city_id: '' }))
                      setvillageList([])
                    }
                  }}
                  renderInput={(params) => <TextField {...params}
                    className={classes.selectBoxCss}
                    name="city_id"
                    label="City"
                  />}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <Autocomplete
                  options={villageList}
                  getOptionLabel={(village) =>
                    village.name
                  }
                  clearOnEscape
                  clearOnBlur
                  value={villageList.find((village) => village.id === formData.village_id) || null}
                  onChange={(event, newValue) => {
                    if (newValue?.id) {
                      handleInputChange({ target: { name: 'village_id', value: newValue ? newValue.id : '' } });
                    } else {
                      setFormData(prev => ({ ...prev, village_id: "", village_id: '' }))
                    }
                  }}
                  renderInput={(params) => <TextField {...params}
                    className={classes.selectBoxCss}
                    name="village_id"
                    label="Village"
                  />}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="Visit Hours"
                value={formData.visit_hour}
                onChange={handleInputChange}
                name="visit_hour"
                type='number'
              />
            </Grid>
            <LocationSearch onLocationChange={handleLocationChange} />
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="Lattitude"
                value={formData.latitude}
                onChange={handleInputChange}
                name="latitude"
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="longitude"
                value={formData.longitude}
                onChange={handleInputChange}
                name="longitude"
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                  <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={destinationTypeList}
                  getOptionLabel={(destinationType) => destinationType.name}
                  value={destinationTypeList.filter((destinationType) => formData.destination_type.includes(destinationType.id))}
                  onChange={(event, newValue) => {
                      if (newValue && newValue.length > 0) {
                        const selectedIds = newValue.map((selectedDestinationType) => selectedDestinationType.id);
                        handleInputChange({ target: { name: 'destination_type', value: selectedIds } });
                      } else {
                        setFormData((prev) => ({ ...prev, destination_type: [] }));
                      }
                    }}
                  filterSelectedOptions
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      name="destination_type"
                      label="Destination Type"
                      />
                  )}
                  />
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                  <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={destinationTagList}
                  getOptionLabel={(destinationTag) => destinationTag.name}
                  value={destinationTagList.filter((destinationTag) => formData.destination_tags.includes(destinationTag.name))}
                  onChange={(event, newValue) => {
                      if (newValue && newValue.length > 0) {
                        const selectedName = newValue.map((selectedDestinationTag) => selectedDestinationTag.name);
                        handleInputChange({ target: { name: 'destination_tags', value: selectedName } });
                      } else {
                        setFormData((prev) => ({ ...prev, destination_tags: [] }));
                      }
                    }}
                  filterSelectedOptions
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      name="destination_tags"
                      label="Destination Tags"
                      />
                  )}
                  />
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="parking-available-label">Parking Available</InputLabel>
                <Select
                  className={classes.selectBoxCss}
                  labelId="parking-available-label"
                  label="Parking Available"
                  value={formData.parking_available}
                  onChange={handleInputChange}
                  name="parking_available"
                >
                  <MenuItem value="no">No</MenuItem>
                  <MenuItem value="free">Free</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="parking-available-label">Locker Available</InputLabel>
                <Select
                  className={classes.selectBoxCss}
                  labelId="parking-available-label"
                  label="Locker Available"
                  value={formData.locker_available}
                  onChange={handleInputChange}
                  name="locker_available"
                >
                  <MenuItem value="no">No</MenuItem>
                  <MenuItem value="free">Free</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="Address"
                value={formData.address}
                onChange={handleInputChange}
                name="address"
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                inputProps={{ accept: 'image/*' }}
                label="Image"
                onChange={handleInputChange}
                name="image_upload"
                multiple
                type="file"
              />
              {formData.featured_image_name &&
                <img src={mediaBaseUrl + 'uploads/' + formData.featured_image_name} width={200} />
              }
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkAll}
                    onChange={(e) => handleCheckAll(e)}
                  />
                }
                label="Fill monday time to all"
              />
            </Grid>
            <div className={classes.scheduleSection}>
              <Grid className={classes.gridCss}>
                <span className={classes.timeTitleCss}>Monday</span>
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Opening Time"
                  value={formData.mondayOpeningTime}
                  onChange={handleInputChange}
                  name="mondayOpeningTime"
                />
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Closing Time"
                  value={formData.mondayClosingTime}
                  onChange={handleInputChange}
                  name="mondayClosingTime"
                />
              </Grid>
              <Grid className={classes.gridCss}>
                <span className={classes.timeTitleCss}>Tuesday</span>
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Opening Time"
                  value={formData.tuesdayOpeningTime}
                  onChange={handleInputChange}
                  name="tuesdayOpeningTime"
                />
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Closing Time"
                  value={formData.tuesdayClosingTime}
                  onChange={handleInputChange}
                  name="tuesdayClosingTime"
                />
              </Grid>
              <Grid className={classes.gridCss}>
                <span className={classes.timeTitleCss}>Wednesday</span>
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Opening Time"
                  value={formData.wednesdayOpeningTime}
                  onChange={handleInputChange}
                  name="wednesdayOpeningTime"
                />
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Closing Time"
                  value={formData.wednesdayClosingTime}
                  onChange={handleInputChange}
                  name="wednesdayClosingTime"
                />
              </Grid>
              <Grid className={classes.gridCss}>
                <span className={classes.timeTitleCss}>Thursday</span>
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Opening Time"
                  value={formData.thursdayOpeningTime}
                  onChange={handleInputChange}
                  name="thursdayOpeningTime"
                />
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Closing Time"
                  value={formData.thursdayClosingTime}
                  onChange={handleInputChange}
                  name="thursdayClosingTime"
                />
              </Grid>
              <Grid className={classes.gridCss}>
                <span className={classes.timeTitleCss}>Friday</span>
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Opening Time"
                  value={formData.fridayOpeningTime}
                  onChange={handleInputChange}
                  name="fridayOpeningTime"
                />
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Closing Time"
                  value={formData.fridayClosingTime}
                  onChange={handleInputChange}
                  name="fridayClosingTime"
                />
              </Grid>
              <Grid className={classes.gridCss}>
                <span className={classes.timeTitleCss}>Saturday</span>
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Opening Time"
                  value={formData.saturdayOpeningTime}
                  onChange={handleInputChange}
                  name="saturdayOpeningTime"
                />
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Closing Time"
                  value={formData.saturdayClosingTime}
                  onChange={handleInputChange}
                  name="saturdayClosingTime"
                />
              </Grid>
              <Grid className={classes.gridCss}>
                <span className={classes.timeTitleCss}>Sunday</span>
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Opening Time"
                  value={formData.sundayOpeningTime}
                  onChange={handleInputChange}
                  name="sundayOpeningTime"
                />
                <TextField
                  type="time"
                  className={classes.gridTextInputHalfCss}
                  label="Closing Time"
                  value={formData.sundayClosingTime}
                  onChange={handleInputChange}
                  name="sundayClosingTime"
                />
              </Grid>
              
              <Grid className={classes.gridCss}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.is_parent === 1}
                      onChange={handleInputChange}
                      name="is_parent"
                    />
                  }
                  label="Is Parent"
                />
              </Grid>
            </div>
          </Box>
          <Grid className={classes.gridButtonCss}>
            <Button disabled={isSubmitting} onClick={handleSubmit} variant="contained" color="primary">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default DestinationForm