import React, { useState, useEffect } from 'react';
import SeasonForm from './Form';


const CreateSeason = () => {
    return (
    <>
      <SeasonForm />
    </>  
  )
}

export default CreateSeason