import { ApiCallPost } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs"


export const LoginApi = async (email, password) => {

      const { baseUrl, login } = ApiConfig;
  
      const url = baseUrl + login;
  
      const params = qs.stringify({
        'email': email,
        'password': password,
      });
  
      // ConsoleLogs(TAG + ", login", `url : ' + ${url}`);
      // ConsoleLogs(
      //   TAG + ", login",
      //   `loginRequestParams : ' + ${JSON.stringify(params)}`
      // );
  
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
      
  
      return await ApiCallPost(url,params,headers);
}
