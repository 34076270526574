import React, { useState, useEffect } from 'react';
import DestinationTypeForm from './Form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/destinationType';

const UpdateDestinationType = () => {
  const { id } = useParams();
  const [typeData, setTypeData] = useState();

  const fetchData = async () => {
    try {
      const response = await SingleList(id);
      setTypeData(response.data);
    } catch (error) {
      console.error('Error fetching season data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (typeData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <DestinationTypeForm typeId={id} typeData={typeData} />
    </>
  );
};

export default UpdateDestinationType;
