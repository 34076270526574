import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, FormControl, InputLabel, Select, MenuItem, Typography, CircularProgress } from '@mui/material';
import { InsertEntity, EditEntity } from '../../api/entity';
import { getStateListing } from "../../api/state";
import { getCityListing } from "../../api/city";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './style';
import LocationSearch from '../../common/SearchLocation/LocationSearch';
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const EntityForm = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading)

  const [formData, setFormData] = useState({
    name: "",
    country_id: 1,
    state_id: "",
    city_id: "",
    mobile_no: "",
    stay_type: "",
    parking_available: "",
    lattitude: "",
    longitude: "",
    price: "",
    room_carpet_area_feet: "",
  });

  const [data, setData] = useState([]);
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const { enqueueSnackbar } = useSnackbar();
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  });
  const navigate = useNavigate();
  const { classes } = useStyles();
  async function fetchStateData() {
    dispatch(startLoading());
    const stateData = await getStateListing({ country_id: 1 });
    switch (stateData.status) {
      case "success": 
          dispatch(stopLoading());
          setStateList(stateData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(stateData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: stateData });
        navigate("/admin/error");
        break;
    }
  }

  async function fetchCityData(state_id) {
    dispatch(startLoading());
    const cityData = await getCityListing({ state_id });
    switch (cityData.status) {
      case "success": 
          dispatch(stopLoading());
          setCityList(cityData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(cityData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: cityData });
        navigate("/admin/error");
        break;
    }
  }

  useEffect(() => {
    fetchStateData();
    if (props.entityId) {
      setIsEditing(true);
      if (props.entityData.state_id) {
        fetchCityData(props.entityData.state_id);
      }
      setFormData(props.entityData);
    }
  }, []);

  const isStateValid = stateList.some((state) => state.id === formData.state_id);
  const isCityValid = cityList.some((city) => city.id === formData.city_id);

  const insertData = async (formData) => {
    try {
      dispatch(startLoading());
      const newData = { ...formData };
      setData((prevData) => [...prevData, newData]);
      // Make the API call to update the data
      const response = await InsertEntity(newData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" })
          navigate('/admin/entity');
          break;
        default:
          dispatch(stopLoading());
          console.log('Error insert data');
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const updateData = async (formData) => {
    try {
      dispatch(startLoading());
      // Make the API call to update the data
      const response = await EditEntity(formData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" })
          navigate('/admin/entity');
          break;
        default:
          dispatch(stopLoading());
          console.log('Error updating data');
          break;
      }
    } catch (error) {
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData, [name]: value,
    }));
    if (name === "state_id") {
      fetchCityData(value);
    }
  };

  const handleLocationChange = (latLng) => {
    setLocation(latLng);

    setFormData((prevData) => ({
      ...prevData,
      lattitude: latLng.lat,
      longitude: latLng.lng,
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      if (isEditing) {
        // Handle update logic
        updateData(formData);
      } else {
        // Handle create logic
        insertData(formData);
      }
    } else {
      enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
    }
  };

  const validateForm = (formData) => {
    // Perform validation checks based on your requirements
    const { name, state_id, city_id, mobile_no } = formData;

    // Check if required fields are filled
    if (!name || !state_id || !city_id || !mobile_no) {
      return false;
    }

    // Additional validation checks...

    return true;
  };

  return (
    <>
      <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
        <Typography gutterBottom className={classes.paperusers}>
          {isEditing ? 'Edit Entity' : 'Create Entity'}
        </Typography>
      </Paper>
      <Grid container spacing={0}>
        {isLoading && <Loader />}
        <Paper className={classes.paperCss}>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid className={classes.gridCss}>
              <TextField
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                label="Name"
                value={formData.name}
                onChange={handleInputChange}
                id="name"
                name="name"
                margin="normal"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="Mobile No"
                value={formData.mobile_no}
                onChange={handleInputChange}
                name="mobile_no"
                type="number"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  className={classes.selectBoxCss}
                  name="state_id"
                  label="State"
                  value={isStateValid ? formData.state_id : ''}
                  onChange={handleInputChange}
                >
                  {stateList.map((state) => (
                    <MenuItem value={state.id} key={state.id}>{state.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="city-label">City</InputLabel>
                <Select
                  labelId="city-label"
                  className={classes.selectBoxCss}
                  label="City"
                  name="city_id"
                  value={isCityValid ? formData.city_id : ''}
                  onChange={handleInputChange}
                >
                  {cityList.map((city) => (
                    <MenuItem value={city.id} key={city.id}>{city.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="stay-type-label">Stay Type</InputLabel>
                <Select
                  labelId="stay-type-label"
                  className={classes.selectBoxCss}
                  label="Stay Type"
                  value={formData.stay_type}
                  onChange={handleInputChange}
                  name="stay_type"
                >
                  <MenuItem value="hotel">Hotel</MenuItem>
                  <MenuItem value="resort">Resort</MenuItem>
                  <MenuItem value="home">Home</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="parking-available-label">Parking Available</InputLabel>
                <Select
                  className={classes.selectBoxCss}
                  labelId="parking-available-label"
                  label="Parking Available"
                  value={formData.parking_available}
                  onChange={handleInputChange}
                  name="parking_available"
                >
                  <MenuItem value="no">No</MenuItem>
                  <MenuItem value="free">Free</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <LocationSearch onLocationChange={handleLocationChange} />
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="Lattitude"
                value={formData.lattitude}
                onChange={handleInputChange}
                name="lattitude"
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="longitude"
                value={formData.longitude}
                onChange={handleInputChange}
                name="longitude"
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="Price"
                value={formData.price}
                onChange={handleInputChange}
                name="price"
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="Room Carpet Area Feet"
                value={formData.room_carpet_area_feet}
                onChange={handleInputChange}
                name="room_carpet_area_feet"
                type="number"
              />
            </Grid>
          </Box>
          <Grid className={classes.gridButtonCss}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default EntityForm