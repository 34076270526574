import { Dialog, DialogContent, IconButton, Tooltip } from '@mui/material';
import { Close as CloseIcon, DeleteForever } from '@mui/icons-material';
import ResponsiveDialog from "../../common/Popup/Confirm";
import { useStyles } from '../../Components/Destination/Style';

const ModalImage = ({ imageUrl, onClose }) => {
  const { classes } = useStyles();
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          className={classes.iconButtonForClose}
        >
          <CloseIcon />
        </IconButton>
        <img src={imageUrl} alt="Popup Image" className={classes.popupImageForDestinationMedia} />
      </DialogContent>
    </Dialog>
  );
};


export default ModalImage;