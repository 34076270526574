import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import { RemoveRedEye } from '@mui/icons-material';
import { siteUrl } from '../../constants';
import { useSelector } from 'react-redux';

function EyeButton({ entityType, entityUrl, entityId, titleName }) {
  const navigate = useNavigate();
  const userPermissions = useSelector((state)=>state.userPermissions)
  const canEdit = userPermissions[entityType] && userPermissions[entityType].view;
  if (canEdit) {
    const url = `${siteUrl}/${entityUrl}/${entityId}`;
    return (
      <Tooltip arrow placement="left" title={`View ${titleName}`}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <IconButton>
            <RemoveRedEye />
          </IconButton>
        </a>
      </Tooltip>
    );
  }

  return null;
}

export default EyeButton;