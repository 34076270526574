import React from 'react';
import LongWeekendForm from './form';


const CreateLongWeekend = () => {
    return (
    <>
      <LongWeekendForm />
    </>  
  )
}

export default CreateLongWeekend