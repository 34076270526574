import { ApiCallGet, ApiCallPost, ApiCallDelete } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";

export const getCityListing = async(props) => {
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };
  const { baseUrl, getCities } = ApiConfig;
  const url = `${baseUrl}${getCities}?state_id=${props.state_id}&per_page=1000`;
  return await ApiCallGet(url, headers);
}
