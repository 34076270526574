import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetDestinationTypeList = async () => {
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };
  const { baseUrl, getDestinationTypeListing } = ApiConfig;
  const url = `${baseUrl}${getDestinationTypeListing}/?per_page=1000`;

  return await ApiCallGet(url, headers);
};

export const GetDestinationType = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertDestinationType = async (props) => {
  const { baseUrl, destinationType } = ApiConfig;

  const url =
    baseUrl +
    destinationType

    let params = qs.stringify({
      "name": props.name,
      "type": props.type
    });
  
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};


export const EditDestinationType = async (props) => {
  const { baseUrl, destinationType } = ApiConfig;

  const url =
    baseUrl +
    destinationType + `?id=${props.id}`

    let params = qs.stringify({
      "name": props.name,
      "type": props.type
    });

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (id) => {
  const { baseUrl, destinationType } = ApiConfig;

  const url =
    baseUrl +
    destinationType + `?id=${id}`

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallGet(url, headers);
};

export const DeleteDestinationType = async (id) => {
  const { baseUrl, destinationType } = ApiConfig;

  const url =
    baseUrl +
    destinationType + `?id=${id}`

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};