import React, { useState, useEffect } from 'react';
import QuoteForm from './Form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/quote';

const UpdateQuote = () => {
  const { id } = useParams();
  const [data, setData] = useState();

  const fetchData = async () => {
    try {
      const response = await SingleList(id);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching quote data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (data === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <QuoteForm id={id} data={data} />
    </>
  );
};

export default UpdateQuote;
