export const permissons = () => {
    const permission = {
      "users" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
        "reset_password": false,
      },
      "guestUser" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
        "move_to_users": false,
      },
      "destinations" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
        "view": false,
      },
      "destinationType" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "tags" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "entities" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "ctabanners" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "reviews" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "countries" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "states" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "cities" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "roles" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "village" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "settings" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "longWeekend" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "mediaListing" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "seasons" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "trips" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
        "view": false,
      },
      "quotes" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "jobs" : {
        "listing" : false,
        "add": false,
        "edit" : false,
        "delete": false,
      },
      "applicants" : {
        "listing" : false,
        "delete": false,
      },
    }
    return permission;
}