import React, { useState } from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './style.css';

const DescriptionEditor = (props) => {
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    // Call the parent component's function to update content
    props.handleEditorChange(data);
  };
    return (
        <CKEditor
        editor={ClassicEditor}
        data={props.content}
        onChange={handleEditorChange}
        config={{
          toolbar: {
            items: [
              'heading',
              '|',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
              'blockQuote',
              'undo',
              'redo'
            ],
          },
        }}
      />
    );
  };
  
  
  export default DescriptionEditor;