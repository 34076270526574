export const 
ApiConfig = {
    baseUrl: 'https://api.tripkhabri.com/',
    login:"user/login",
    getUsers: "user/listing",
    getUser: "user/get?id=",
    createUser: "user/create",
    editUser: "user/update?id=",
    deleteUser: "user/delete?id=",
    passwordChange: "user/change-password",
    getReview:"reviews/filter-review",
    review:"reviews/review",
    approve:"reviews/approve?id=",
    editReview:"reviews/review?id=",
    insertReviewMedia: "reviews/upload-review-media",
    reviewMedialisting: "reviews/media-listing?review_id=",
    deleteReviewMedia: "reviews/delete-media",
    getdestination:"destinations/filter-destination",
    destination:"destinations/destination",
    editdestination:"destinations/destination?id=",
    getTags:"tags/listing",
    tag:"tags/tag",
    editTag:"tags/tag?id=",
    getEntity:"entities/filter-entity",
    entity:"entities/entity",
    editEntity:"entities/entity?id=",
    getStates: "states/listing",
    getCities: "cities/listing",
    getRoles: "user-role/role",
    getRolesListing: "user-role/roles",
    editRole: "user-role/edit?id=",
    createRole: "user-role/role",
    updateRole: "user-role/role?id=",
    deleteRole: "user-role/role?id=",
    getCountry:"countries/listing",
    country:"countries/country",
    editCountry:"countries/country?id=",
    getState:"states/listing",
    state:"states/state",
    editState:"states/state?id=",
    getCity:"cities/listing",
    city:"cities/city",
    editCity:"cities/city?id=",
    getVillage:"villages/listing",
    village:"villages/village",
    editVillage:"villages/village?id=",
    settings:"settings/setting",
    getGuestUser:"user/guest-signup-listing",
    moveGuestUsersToUsers:"user/guest-user-to-user",
    getLongWeekend:"longWeekends/listing",
    longWeekend:"longWeekends/longWeekend",
    editLongWeekend:"longWeekends/longWeekend?id=",
    medialisting:"media/listing-media",
    uploadMedialisting: "destinations/media-listing?destination_id=",
    insertMedia: "destinations/upload-destination-media",
    deleteMedia: "destinations/delete-media",
    updateMediaName: "media/update-media-name",
    mediaupdatename: "media/update-media-name",
    mediadeletename: "media/media-delete?media_id=",
    getSeasonListing:"seasons/filter-season",
    season:"seasons/season",
    getDestinationTypeListing:"destination-types/filter-type",
    destinationType:"destination-types/type",
    tripListing: "trips/trip-listing",
    editTrip:"trips/trip?id=",
    getTripList: "trips/list",
    getCtaBanners: "cta-banners/filter-banner",
    ctaBanners: "cta-banners/banner",
    getQuoteListing:"quotes/filter-quotes",
    quote:"quotes/quote",
    getItinerariesList: "itineraries/list",
    getTravelModeList: "travel-modes/list",
    getJobsListing:"jobs/filter-jobs",
    job:"jobs/job",
    getJobApplicantsListing:"applicants/filter-applicants",
    applicant:"applicants/applicant",
    getUsersList: "user/list",
    resetPasswordByID: "user/reset-password-by-ID?id=",
    getDashboardData: "dashboard/get-dashboard-data",
    getDestinaitonFaq: "destinations/get-faqs",
    deleteDestinaitonFaq: "destinations/delete-faq",
    createDestinaitonFaq: "destinations/create-faq",
    updateDestinaitonFaq: "destinations/update-faq",
    singleDestinaitonFaq: "destinations/single-list-faq",
}
