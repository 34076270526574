import { makeStyles } from "tss-react/mui";
// import { makeStyles } from "@mui/material";

export const useStyles = makeStyles()((theme) => ({
  paperusers: {
    fontSize: 18,
    fontWeight: "600",
    padding: "5px"
  },
  metricValue: {
    textAlign: "right",
  }

}));
