const initialState = {
    apiError: null,
    token:localStorage.getItem("app-token"),
    authToken: localStorage.getItem("app-token"),
    userRole: localStorage.getItem("user-role") || null,
    userPermissions: JSON.parse(localStorage.getItem("user-permissions")) || {},
    loading: false,
};

export const actionTypes = {
    API_ERROR: "API_ERROR",
    ADD_TOKEN: "ADD_TOKEN",
    AUTH_TOKEN: "AUTH_TOKEN",
    USER_ROLE: "USER_ROLE",
    USER_PERMISSIONS: "USER_PERMISSIONS",
    START_LOADING: "START_LOADING",
    STOP_LOADING: "STOP_LOADING",
};

export const reducer = (state=initialState, action ) => {
    switch(action.type){
        case actionTypes.API_ERROR:{
            return {...state, apiError: action.payload}
        }
        case actionTypes.ADD_TOKEN:{
            return {...state,token:action.payload}
        }
        case actionTypes.AUTH_TOKEN: {
            return { ...state, authToken: action.payload };
          }
        case actionTypes.USER_ROLE: {
            return { ...state, userRole: action.payload };
        }
        case actionTypes.USER_PERMISSIONS: {
            return { ...state, userPermissions: action.payload };
        }
        case actionTypes.START_LOADING: {
            return { ...state, loading: true };
        }
        case actionTypes.STOP_LOADING: {
            return { ...state, loading: false };
        }
        default:
            return state
    }
}

export const startLoading = () => ({
    type: actionTypes.START_LOADING,
})

export const stopLoading = () => ({
    type: actionTypes.STOP_LOADING,
})