import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, FormControl, InputLabel, Select, MenuItem, Typography, CircularProgress } from '@mui/material';
import { InsertUser } from '../../api/user';
import { getUserRoles } from "../../api/userRole";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Style';
import { EditUser } from '../../api/user';
import { slugify } from '../../helper';
import DescriptionEditor from "../../common/TextEditor/DescriptionEditor"
import { mediaBaseUrl } from '../../constants';
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const UserForm = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    phone_number: "",
    password: "",
    user_role: "",
    instagram_link: "",
    description: '',
    image_upload: "",
    profile_image: "",
    is_active: 1,
    old_user_role: null
  });

  const [data, setData] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const currentUserEmail = localStorage.getItem("user-email");
  async function fetchRoleData() {
    dispatch(startLoading());
    const roleData = await getUserRoles();
    switch (roleData.status) {
      case "success": 
          dispatch(stopLoading());
          setRoleList(roleData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(roleData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: roleData });
        navigate("/admin/error");
        break;
    }
  }

  useEffect(() => {
    fetchRoleData();
    if (props.userId) {
      setIsEditing(true);
      setFormData((prevData) => ({
        ...prevData,
        ...props.userData,
        old_user_role: props.userData.user_role
      }));
    }
  }, []);

  const isRoleValid = roleList.some((role) => role.id === formData.user_role);

  const insertData = async (formData) => {
    try {      
      dispatch(startLoading());
      const newData = { ...formData };
      setData((prevData) => [...prevData, newData]);
      // Make the API call to update the data
      const response = await InsertUser(newData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" });
          navigate('/admin/users');
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('app-token')
    navigate('/');
  };

  const updateData = async (formData) => {
    try {
      dispatch(startLoading());
      delete formData.password
      // Make the API call to update the data
      const response = await EditUser(formData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" });
          navigate('/admin/users');
          if(formData.user_role !== formData.old_user_role) {
            if (currentUserEmail && currentUserEmail == formData.email) {
              logout();
            }
          }
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image_upload") {
      setFormData((prevData) => ({
        ...prevData,
        image_upload: files[0], // Store the selected file in the formData
      }));
    } else if( name === 'username') {
        setFormData((prevData) => ({
          ...prevData,
          username: slugify(value),
        }));
      } else {
      // For other inputs (non-file inputs), update the form data as before
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }

  const handleTextEditorChange = (content) => {
    setFormData((prevData) => ({
        ...prevData,
        description: content,
    }));
  }    

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      try {
        if (isEditing) {
          // Handle update logic
          delete formData.password
          updateData(formData);
        } else {
          // Handle create logic
          insertData(formData);
        }
      } catch {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } else {
      enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
    }
  };

  const validateForm = (formData) => {
    // Perform validation checks based on your requirements
    const { first_name, last_name, email, phone_number, password, username } = formData;

    // Check if required fields are filled
    if (!first_name || !last_name || !email || !phone_number || !username || (!isEditing && !password)) {
      return false;
    }

    // Additional validation checks...

    return true;
  };
  
  return (
    <>
      <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
        <Typography gutterBottom className={classes.paperusers}>
          {isEditing ? 'Edit User' : 'Create User'}
        </Typography>
      </Paper>
      <Grid container spacing={0}>
        {isLoading && <Loader />}
        <Paper className={classes.paperCss}>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid className={classes.gridCss}>
              <TextField
                autoComplete="off"
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                label="First Name"
                value={formData.first_name}
                onChange={handleInputChange}
                id="first_name"
                name="first_name"
                margin="normal"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                autoComplete="off"
                className={classes.gridTextInputCss}
                label="Last Name"
                value={formData.last_name}
                onChange={handleInputChange}
                name="last_name"
                type="text"
                required
              />
            </Grid>

            <Grid className={classes.gridCss}>
              <TextField
                autoComplete="off"
                className={classes.gridTextInputCss}
                label="Email"
                value={formData.email}
                onChange={handleInputChange}
                name="email"
                type="email"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
                <TextField
                    autoComplete="off"
                    className={classes.gridTextInputCss}
                    label="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    id="username"
                    name="username"
                    required
                />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                autoComplete="off"
                className={classes.gridTextInputCss}
                label="Phone Number"
                value={formData.phone_number}
                onChange={handleInputChange}
                name="phone_number"
                type="text"
                required
              />
            </Grid>
            {!isEditing &&
              <Grid className={classes.gridCss}>
                <TextField
                  autoComplete="off"
                  className={classes.gridTextInputCss}
                  label="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  name="password"
                  type="password"
                  required
                />
              </Grid>
            }
            <Grid className={classes.gridCss}>
                <TextField
                    autoComplete="off"
                    className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                    label="Instagram Handle"
                    value={formData.instagram_link}
                    onChange={handleInputChange}
                    id="instagram_link"
                    name="instagram_link"
                    margin="normal"
                />
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  className={classes.selectBoxCss}
                  name="user_role"
                  label="Role"
                  value={isRoleValid ? formData.user_role : ''}
                  onChange={handleInputChange}
                >
                  {roleList.map((role) => (
                    <MenuItem value={role.id} key={role.id}>{role.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
                <textarea
                    className={`${classes.gridTextInputCss}`}
                    rows="5"
                    type="text"
                    placeholder="Write some details about yourself"
                    maxLength={250}
                    name='description'
                    value={formData.description}
                    onChange={handleInputChange}
                />
                
            </Grid>
            <Grid className={classes.gridCss}>
                <TextField
                    className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                    inputProps={{ accept: 'image/*' }}
                    label="Profile Photo"
                    onChange={handleInputChange}
                    name="image_upload"
                    type="file"
                />
                {formData.profile_image &&
                        <img src={mediaBaseUrl + 'uploads/' + formData.profile_image} width={200} />
                  }
            </Grid>

          </Box>
          <Grid className={classes.gridButtonCss}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default UserForm