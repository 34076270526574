import React, { useState, useEffect } from 'react';
import BannerForm from './Form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/ctaBanner';

const UpdateCtaBanner = () => {
  const { id } = useParams();
  const [ctaBannerData, setCtaBannerData] = useState();

  const fetchSingleData = async () => {
    try {
      const response = await SingleList(id);
      setCtaBannerData(response.data);
    } catch (error) {
      console.error('Error fetching cta banner data:', error);
    }
  };

  useEffect(() => {
    fetchSingleData();
  }, []);

  if (ctaBannerData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <BannerForm ctaBannerId={id} ctaBannerData={ctaBannerData} />
    </>
  );
};

export default UpdateCtaBanner;
