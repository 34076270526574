import React, { useState, useEffect } from 'react';
import {Paper, Typography } from '@mui/material';
import { mediaBaseUrl } from '../../constants';

const ComparisonTripData = (props) => {
    const { seasonsList, travelModeList, tripTempData, tripData } = props;

    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
      
        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2.slice().sort();

        for (let i = 0; i < sortedArr1.length; i++) {
          if (sortedArr1[i] !== sortedArr2[i]) return false;
        }
      
        return true;
      }

    const getNames = ( list, ids ) => {
        const selectedNames = ids.map(id => {
            const item = list.find(item => item.id === id);
            return item ? item.name : null;
          });
        return selectedNames
    }
  
    const renderDifference = (label, realValue, changeValue) => {
      return (
        <div>
          <p>{`Real ${label}: ${realValue}`}</p>
          <p>{`Change ${label}: ${changeValue}`}</p>
        </div>
      );
    };
  
    const itinerariesChanged =
    areArraysEqual(tripTempData.itineraries, tripData.itineraries);
    
    const seasonChanged =
    areArraysEqual(tripTempData.seasons, tripData.seasons) ;

      const travelModeChanged =
      areArraysEqual(tripTempData.travel_modes, tripData.travel_modes);


    return (
      <>
        {tripTempData?.id && (
          <Paper sx={{ marginTop: 2, padding: 1.3 }}>
            <Typography>Changes</Typography>
            {tripTempData.title !== tripData.title &&
              renderDifference("title", tripData.title, tripTempData.title)}
            {tripTempData.instagram_link !== tripData.instagram_link &&
              renderDifference(
                "insta link",
                tripData.instagram_link,
                tripTempData.instagram_link
              )}
            {tripTempData.image_name && tripTempData.image_name !== tripData.image_name && (
                <div>
                <p>Real Image <img src={mediaBaseUrl + 'uploads/' + tripData.image_name} width={200} /></p>
                <p>Change Image <img src={mediaBaseUrl + 'uploads/' + tripTempData.image_name} width={200} /></p>
              </div>
            )}

            {!itinerariesChanged &&
              renderDifference(
                "itineraries",
                tripData.itineraries.join(", "),
                tripTempData.itineraries.join(", ")
              )}
              {!seasonChanged &&
              renderDifference(
                "Season",
                getNames(seasonsList, tripData.seasons).join(", "),
                getNames(seasonsList, tripTempData.seasons).join(", ")
              )}
              {!travelModeChanged &&
              renderDifference(
                "Travel Mode",
                getNames(travelModeList, tripData.travel_modes).join(", "),
                getNames(travelModeList, tripTempData.travel_modes).join(", ")
              )}
          </Paper>
        )}
      </>
    )
  }

export default ComparisonTripData