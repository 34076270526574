import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/review';
import  CreateReview  from './CreateReview';
import ReviewMedia from './ReviewMedia';

const UpdateReview = () => {
  const { id } = useParams();
  const [reviewData, setReviewData] = useState();

  const fetchReviewSingleData = async () => {
    try {
      const response = await SingleList(id);
      setReviewData(response.data);
    } catch (error) {
      console.error('Error fetching review data:', error);
    }
  };

  useEffect(() => {
    fetchReviewSingleData();
  }, []);

  if (reviewData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <CreateReview reviewId={id} reviewData={reviewData} />
      <ReviewMedia reviewId={id} />
    </>
  );
};

export default UpdateReview;