import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetReview = async (url) => {
  const headers =  {
    "Content-Type": "application/json",
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };
  return await ApiCallGet(url, headers);
};

export const CreateReview = async (props) => {
  const { baseUrl, review } = ApiConfig;

  const url =
    baseUrl +
    review

  let params = qs.stringify({
    "reviewer_id": props.reviewer_id,
    "rating": props.rating,
    "details": props.details,
    "type": props.type,
    "item_id": props.item_id,
    "status": props.status
  });

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};

export const EditReview = async (props) => {
  const { baseUrl, editReview } = ApiConfig;

  const url =
    baseUrl +
    editReview + props.id

  let params = qs.stringify({
    "reviewer_id": props.reviewer_id,
    "rating": props.rating,
    "details": props.details,
    "type": props.type,
    "item_id": props.item_id,
    "status": props.status
  });

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (reviewes_id) => {
  const { baseUrl, editReview } = ApiConfig;

  const url =
    baseUrl +
    editReview + reviewes_id

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const DeleteReview = async (id) => {
  const { baseUrl, review } = ApiConfig;

  const url =
    baseUrl +
    review +
    `?id=${id}`;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};

export const ApproveReview = async (id) => {
  const { baseUrl, approve } = ApiConfig;

  const url = `${baseUrl}${approve}${id}`;
    
  let params = qs.stringify({
    // "id": id,
    "status": "approved"
  });

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const GetReviewMedia = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertMedia = async (review_id, files) => {
  const { baseUrl, insertReviewMedia } = ApiConfig;

  const params = new FormData();
  params.append("review_id", review_id);
  files.forEach( (file) => {
    params.append('files', file);
  });
  const url =
    baseUrl +
    insertReviewMedia 
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallPost(url, params, headers);
};

export const DeleteMedia = async (review_id, media_id) => {
  const { baseUrl, deleteReviewMedia } = ApiConfig;
  const qs = require('qs');
  let params = qs.stringify({
    'review_id': review_id,
    'media_id': media_id
  });

  const url =
    baseUrl +
    deleteReviewMedia;

  const headers = {
      'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

  return await ApiCallPost(url, params, headers);
};

export const Mediaupdate = async (props) => {
  const { baseUrl, updateMediaName } = ApiConfig;

  const url =
    baseUrl +
    updateMediaName

  let params = qs.stringify({
    "media_id": props.media_id,
    "new_media_name": props.new_media_name,
    "media_alt": props.media_alt,
  });  

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallPost(url, params, headers);
};

