import React, { useState, useEffect } from 'react';
import DestinationTypeForm from './Form';


const CreateDestinationType = () => {
    return (
    <>
      <DestinationTypeForm />
    </>  
  )
}

export default CreateDestinationType