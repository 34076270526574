import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, Typography, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { InsertJob, EditJob } from '../../api/job';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Style';
import { mediaBaseUrl } from '../../constants';
import DescriptionEditor from "../../common/TextEditor/DescriptionEditor";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const JobForm = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    posted_at: "",
    deadline: "",
    is_active: false,
  });

  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { classes } = useStyles();

  useEffect(() => {
    if (props.jobId) {
      setIsEditing(true);
      setFormData(props.jobData);
    }
  }, []);



  const insertData = async (formData) => {
    try {
      dispatch(startLoading());
      const newData = { ...formData };
      setData((prevData) => [...prevData, newData]);
      // Make the API call to update the data
      const response = await InsertJob(newData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" })
          navigate('/admin/jobs');
          break;
        default:
          dispatch(stopLoading());
          console.log('Error insert data');
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const updateData = async (formData) => {
    try {
      dispatch(startLoading());
      // Make the API call to update the data
      const response = await EditJob(formData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" })
          navigate('/admin/jobs');
          break;
        default:
          dispatch(stopLoading());
          console.log('Error updating data');
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };

  const handleTextEditorChange = (content) => {
    setFormData((prevData) => ({
        ...prevData,
        description: content,
    }));
  }; 

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      if (isEditing) {
        // Handle update logic
        updateData(formData);
      } else {
        // Handle create logic
        insertData(formData);
      }
    } else {
      enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
    }
  };

  const validateForm = (formData) => {
    // Perform validation checks based on your requirements
    const { title, description, posted_at, deadline } = formData;

    // Check if required fields are filled
    if (!title || !description || !posted_at || !deadline) {
      return false;
    }
    return true;
  };

  return (
    <>
    <Paper sx={{height: 60,marginBottom:2,padding:1.3,}}>
        <Typography  gutterBottom className={classes.paperusers}>
        {isEditing ? 'Edit Job' : 'Create Job'}
        </Typography>
      </Paper>
      <Grid container spacing={0}>
        {isLoading && <Loader />}
        <Paper className={classes.paperCss}>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Grid className={classes.gridCss}>
              <TextField
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                label="Title"
                value={formData.title}
                onChange={handleInputChange}
                id="title"
                name="title"
                margin="normal"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
                <TextField
                    style={{ marginRight: "10px" }}
                    label="Posted at"
                    value={formData.posted_at || ''}
                    onChange={handleInputChange}
                    id="posted_at"
                    name="posted_at"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Deadline"
                    value={formData.deadline || ''}
                    onChange={handleInputChange}
                    id="deadline"
                    name="deadline"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid className={classes.gridCss}>
                <FormControl style={{ width: "100%" }} size="small">
                    <InputLabel id="stay-status-label">Status</InputLabel>
                    <Select
                    labelId="Status"
                    className={classes.selectBoxCss}
                    label="Status"
                    value={formData.is_active }
                    onChange={handleInputChange}
                    name="is_active"
                    >
                    <MenuItem value="true">Active</MenuItem>
                    <MenuItem value="false">InActive</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid className={classes.gridCss} style={{ width: '98%' }}>
                <DescriptionEditor content={formData.description ? formData.description : ''} handleEditorChange={handleTextEditorChange} />
            </Grid>
          </Box>
          <Grid className={classes.gridButtonCss}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default JobForm