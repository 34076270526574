
import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, FormControl, Autocomplete, CircularProgress, MenuItem, Typography } from '@mui/material';
import { InsertState, EditState } from '../../api/statepage';
import { GetCountry } from '../../api/country';
import { GetSeason } from '../../api/season';
import { useSnackbar } from 'notistack';
import { ApiConfig } from "../../api/config/ApiConfig";
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Style';
import { mediaBaseUrl } from '../../constants';
import { getCityListing } from "../../api/city";
import LocationSearch from '../../common/SearchLocation/LocationSearch';
import { slugify } from '../../helper';
import DescriptionEditor from "../../common/TextEditor/DescriptionEditor";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const { baseUrl, getCountry, getSeasonListing } = ApiConfig;

const StateForm = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading)
    const [formData, setFormData] = useState({
        name: "",
        slug: "",
        country_id: "",
        latitude: '',
        longitude: '',
        image_upload: "",
        featured_image_name: "",
        description: "",
        starting_city_id: "",
    });

    const [data, setData] = useState([]);
    const [stateList, setStateList] = useState([])
    const [seasonList, setSeasonList] = useState([]);
    const [seasonDates, setSeasonDates] = useState({});
    const [cityList, setCityList] = useState([]);
    const [location, setLocation] = useState({
        lat: null,
        lng: null,
    });

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { classes } = useStyles();
    async function fetchStateData() {
        const url = new URL(
            getCountry,
            baseUrl,
        );
        dispatch(startLoading());
        const fullurl = `${url}?page&per_page=1000`;
        const stateData = await GetCountry(fullurl);
        switch (stateData.status) {
            case "success": 
                dispatch(stopLoading());
                setStateList(stateData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(stateData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: stateData });
              navigate("/admin/error");
              break;
        }
    }

    async function fetchSeasonsData() {
        const url = new URL(
            getSeasonListing,
            baseUrl,
        );
        dispatch(startLoading());
        const fullurl = `${url}?page&per_page=1000`;
        const seasonData = await GetSeason(fullurl);
        switch (seasonData.status) {
            case "success": 
                dispatch(stopLoading());
                setSeasonList(seasonData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(seasonData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: seasonData });
              navigate("/admin/error");
              break;
        }
    }

    async function fetchCityData(state_id) {
        const cityData = await getCityListing({ state_id });
        dispatch(startLoading());
        switch (cityData.status) {
            case "success": 
                dispatch(stopLoading());
                setCityList(cityData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(cityData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: cityData });
              navigate("/admin/error");
              break;
        }
    }

    useEffect(() => {
        fetchStateData();
        fetchSeasonsData();
        if (props.stateId) {
            setIsEditing(true);
            fetchCityData(props.stateId);
            setFormData(props.stateData);
            const transformedData = props.stateData.seasons.reduce((result, item) => {
                const sessionId = item.season_id;
                if (!result[sessionId]) {
                  result[sessionId] = {
                    start: item.start_month,
                    end: item.end_month
                  };
                }
                return result;
              }, {});
            setSeasonDates(transformedData)
        }
    }, []);    


    const handleSeasonDateChange = (seasonId, dateType, dateValue) => {
        setSeasonDates(prevDates => ({
          ...prevDates,
          [seasonId]: {
            ...prevDates[seasonId],
            [dateType]: dateValue,
          },
        }));
      };

    const insertData = async (formData) => {
        try {
            dispatch(startLoading());
            const newData = { ...formData };
            setData((prevData) => [...prevData, newData]);
            // Make the API call to update the data
            const response = await InsertState(newData);
            // Check if the API call was successful
            switch (response.status) {
                case "Success":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/admin/state');
                    break;
                case "failed":
                    dispatch(stopLoading());
                    enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
                    break;
                case "error":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "error" });
                    break;
                default:
                    dispatch(stopLoading());
                    console.log('Error insert data');
                    break;
            }
        } catch (error) {
            // Handle any network or API call errors
            console.log('An error occurred', error);
        }
    };

    const updateData = async (formData) => {
        try {
            dispatch(startLoading());
            // Make the API call to update the data
            const response = await EditState(formData);
            // Check if the API call was successful
            switch (response.status) {
                case "success":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/admin/state');
                    break;
                case "failed":
                    dispatch(stopLoading());
                    enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
                    break;
                case "error":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "error" });
                    break;
                default:
                    dispatch(stopLoading());
                    console.log('Error updating data');
                    break;
            }
        } catch (error) {
            // Handle any network or API call errors
            console.log('An error occurred', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image_upload") {
          setFormData((prevData) => ({
            ...prevData,
            image_upload: files[0], // Store the selected file in the formData
          }));
        } else if( name === 'slug') {
            setFormData((prevData) => ({
              ...prevData,
              slug: slugify(value),
            }));
          } else {
          // For other inputs (non-file inputs), update the form data as before
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
    
      };

      const handleTextEditorChange = (content) => {
        setFormData((prevData) => ({
            ...prevData,
            description: content,
        }));
      }; 

    const handleLocationChange = (latLng) => {
        setLocation(latLng);
    
        setFormData((prevData) => ({
          ...prevData,
          latitude: latLng.lat,
          longitude: latLng.lng,
        }));
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        const isFormValid = validateForm(formData);
        if (isFormValid) {
            const finalFormData = {...formData, seasonData: seasonList.map(season => ({
                season_id: season.id,
                start_date: seasonDates[season.id]?.start || '',
                end_date: seasonDates[season.id]?.end || '',
            }))}
            
            if (isEditing) {
                // Handle update logic
                updateData(finalFormData);
            } else {
                // Handle create logic
                insertData(finalFormData);
            }
        } else {
            enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
        }
    };

    const validateForm = (formData) => {
        // Perform validation checks based on your requirements
        const { name, slug, country_id, description, } = formData;

        // Check if required fields are filled
        if (!name || !slug || !country_id|| !description) {
            return false;
        }

        // Additional validation checks...

        return true;
    };

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];
    
    const renderMonthOptions = () => {
        return months.map((month, index) => (
            <MenuItem key={index} value={index + 1}>
            {month}
            </MenuItem>
        ));
    };

    return (
        <>
            <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
                <Typography gutterBottom className={classes.paperusers}>
                    {isEditing ? 'Edit State' : 'Create State'}
                </Typography>
            </Paper>
            <Grid container spacing={0}>
                {isLoading && <Loader />}
                <Paper className={classes.paperCss}>
                    <Box noValidate sx={{ mt: 1 }}>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                label="Name"
                                value={formData.name}
                                onChange={handleInputChange}
                                id="name"
                                name="name"
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={classes.gridTextInputCss}
                                label="Slug"
                                value={formData.slug}
                                onChange={handleInputChange}
                                id="slug"
                                name="slug"
                                required
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <Autocomplete
                                    options={stateList}
                                    getOptionLabel={(country) =>
                                        country.name
                                    }
                                    clearOnEscape
                                    clearOnBlur
                                    value={stateList.find((country) => country.id === formData.country_id) || null}
                                    onChange={(event, newValue) => {
                                        if (newValue?.id) {
                                            handleInputChange({ target: { name: 'country_id', value: newValue ? newValue.id : '' } });
                                        } else {
                                            setFormData(prev => ({ ...prev, state_id: "", country_id: '' }))
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        className={classes.selectBoxCss}
                                        name="country_id"
                                        label="Country"
                                    />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <Autocomplete
                                options={cityList}
                                getOptionLabel={(city) =>
                                    city.name
                                }
                                clearOnEscape
                                clearOnBlur
                                value={cityList.find((city) => city.id === formData.starting_city_id) || null}
                                onChange={(event, newValue) => {
                                    if (newValue?.id) {
                                    handleInputChange({ target: { name: 'starting_city_id', value: newValue ? newValue.id : '' } });
                                    } else {
                                    setFormData(prev => ({ ...prev, state_id: "", starting_city_id: '' }))
                                    }
                                }}
                                renderInput={(params) => <TextField {...params}
                                    className={classes.selectBoxCss}
                                    name="starting_city_id"
                                    label="Starting city"
                                />}
                                />
                            </FormControl>
                        </Grid>
                        <LocationSearch onLocationChange={handleLocationChange} />
                        <Grid className={classes.gridCss}>
                        <TextField
                            className={classes.gridTextInputCss}
                            label="Lattitude"
                            value={formData.latitude}
                            onChange={handleInputChange}
                            name="latitude"
                        />
                        </Grid>
                        <Grid className={classes.gridCss}>
                        <TextField
                            className={classes.gridTextInputCss}
                            label="longitude"
                            value={formData.longitude}
                            onChange={handleInputChange}
                            name="longitude"
                        />
                        </Grid>
                        <Grid className={classes.gridCss} style={{ width: '98%' }}>
                            <DescriptionEditor content={formData.description ? formData.description : ''} handleEditorChange={handleTextEditorChange} />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                inputProps={{ accept: 'image/*' }}
                                label="Image"
                                onChange={handleInputChange}
                                name="image_upload"
                                multiple
                                type="file"
                            />
                            {formData.featured_image_name &&
                                    <img src={mediaBaseUrl + 'uploads/' + formData.featured_image_name} width={200} />
                             }
                        </Grid>
                    </Box>
                    <Box noValidate sx={{ mt: 1 }}>
                        <Typography className={classes.paperusers} width={100}>
                            Seasons
                        </Typography>
                        
                        { seasonList.map (season => (
                            <Grid key={season.id} className={classes.gridContainerCss}>
                                <Grid className={classes.grid3Box}>
                                    <Typography className={classes.paperusers}>
                                    {season.name}
                                    </Typography>
                                </Grid>
                                <Grid className={classes.grid3Box}>
                                    <TextField
                                    className={classes.gridTextInputCss}
                                    select
                                    label="Start Month"
                                    value={seasonDates[season.id]?.start || ''}
                                    onChange={(e) => handleSeasonDateChange(season.id, 'start', e.target.value)}
                                    id={`season-${season.id}-start`}
                                    >
                                    {renderMonthOptions()}
                                    </TextField>
                                </Grid>
                                <Grid className={classes.grid3Box}>
                                    <TextField
                                    className={classes.gridTextInputCss}
                                    select
                                    label="End Month"
                                    value={seasonDates[season.id]?.end || ''}
                                    onChange={(e) => handleSeasonDateChange(season.id, 'end', e.target.value)}
                                    id={`season-${season.id}-end`}
                                    >
                                    {renderMonthOptions()}
                                    </TextField>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                    <Grid className={classes.gridButtonCss}>
                        <Button onClick={handleSubmit} variant="contained" color="primary">
                            {isEditing ? 'Update' : 'Create'}
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default StateForm