import React, { useState, useEffect } from 'react';
import SeasonForm from './Form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/season';

const UpdateSeason = () => {
  const { id } = useParams();
  const [seasonData, setSeasonData] = useState();

  const fetchData = async () => {
    try {
      const response = await SingleList(id);
      setSeasonData(response.data);
    } catch (error) {
      console.error('Error fetching season data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (seasonData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <SeasonForm seasonId={id} seasonData={seasonData} />
    </>
  );
};

export default UpdateSeason;
