import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetGuestUser = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};
