import React, { useState, useEffect } from 'react';
import QuoteForm from './Form';


const CreateQuote = () => {
    return (
    <>
      <QuoteForm />
    </>  
  )
}

export default CreateQuote