import React from 'react';
import TagForm from './form';


const CreateTag = () => {
    return (
    <>
      <TagForm />
    </>  
  )
}

export default CreateTag