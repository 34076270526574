import React, {useState, useEffect, useMemo, memo, useCallback} from 'react';
import { GetDestination,DeleteDestination } from '../../api/destination';
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { useStyles } from './Style';
import { Box, Button, Paper, Typography } from "@mui/material";
import { ApiConfig } from "../../api/config/ApiConfig";
import EditButton from '../../common/EditButton/EditButton';
import DeleteButton from '../../common/DeleteButton/DeleteButton';
import CreateButton from '../../common/CreateButton/CreateButton';
import EyeButton from '../../common/EyeButton/EyeButton';
import { useSelector } from 'react-redux';

const Destination = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [validationErrors, setValidationErrors] = useState({});
  const MemoizedMaterialReactTable = memo(MaterialReactTable);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const userPermissions = useSelector((state)=>state.userPermissions);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { baseUrl, getdestination } = ApiConfig;

  const fetchInfo = async () => { 
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const page=pagination.pageIndex+1;
    const per_page=pagination.pageSize;
    const url = new URL(
      getdestination,
      baseUrl,
    );
    url.searchParams.set(
      'page',
      `${page}`,
    );
    url.searchParams.set('per_page', `${per_page}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('globalFilter', globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    const response = await GetDestination(url);
    switch (response.status) {
      case "success":
        const { data, totalCount } = await response;
        setData(data);
        setRowCount(totalCount);
        break;
      case "failed":
        enqueueSnackbar(response.message, { variant: "error" });
        break;
      default:
        dispatch({ type: "API_ERROR", payload: response });
        navigate("/admin/error");
        break;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }
  
  useEffect(() => {
    fetchInfo();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "state",
        header: "State",
      },
      {
        accessorKey: "city",
        header: "City",
      },
      {
        accessorKey: "village",
        header: "Village",
      },
      {
        accessorKey: "address",
        header: "Address",
      },
      {
        accessorKey: "destination_type",
        header: "Destination type",
      },
      {
        accessorKey: "parking_available",
        header: "Parking available",
      },
      {
        accessorKey: "locker_available",
        header: "Locker available",
      },
    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (clicked, id, index,name) => {
      if (clicked) {
        const response = await DeleteDestination(id,name);
        switch (response.status) {
          case "success":
            enqueueSnackbar(response.message, { variant: "success" });
            data.splice(index, 1);
            setData([...data]);
            fetchInfo();
            break;
          case "failed":
            enqueueSnackbar(response.message, { variant: "warning" });
            break;
          case "error":
            enqueueSnackbar(response.message, { variant: "error" });

            break;
          default:
            dispatch({ type: "API_ERROR", payload: response });
            navigate("/admin/error");
            break;
        }
      }
    },
    [dispatch, navigate, data]
  );
  
  return (
    <>
    <Paper className={classes.paperRoles}>
        <Typography  gutterBottom className={classes.paperusers}>
          Destinations
        </Typography>
        <CreateButton entityType="destinations" titleName="Destination" />
      </Paper>
      <MaterialReactTable
      columns={columns}
      data={data}
      enableEditing={
        userPermissions?.destinations &&
        userPermissions.destinations.delete ||
        userPermissions.destinations.edit ||
        userPermissions.destinations.view
          ? true
          : false
      }
      getRowId={(row) => row.id}
      initialState={{ showColumnFilters: false }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <EditButton entityType="destinations" entityId={row.original.id} titleName="Destination" />
          <DeleteButton entityType="destinations" entityId={row.original.id} entityName={row.original.name} index={row.index} handleDeleteRow={handleDeleteRow} titleName="Destination" />
          <EyeButton entityType="destinations" entityUrl="destination" entityId={row.original.slug} titleName="Destination" />
        </Box>
      )}
    />
    </>
  )
}

export default Destination