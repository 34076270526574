import  React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LocationCitySharpIcon from '@mui/icons-material/LocationCitySharp';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MmsIcon from '@mui/icons-material/Mms';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const MainListItems = () => {
  const userPermissions = useSelector((state)=>state.userPermissions)

  return(
    <React.Fragment>
    <NavLink to="/admin" end>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </NavLink>
    {userPermissions?.users && userPermissions.users.listing && (
    <NavLink to="users">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.guestUser && userPermissions.guestUser.listing && (
    <NavLink to="guestuser">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Guest user" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.roles && userPermissions.roles.listing && (
    <NavLink to="roles">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Roles" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.ctabanners && userPermissions.ctabanners.listing && (
    <NavLink to="ctabanners">
      <ListItemButton>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="CTA Banners" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.reviews && userPermissions.reviews.listing && (
    <NavLink to="review">
      <ListItemButton>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Reviews" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.seasons && userPermissions.seasons.listing && (
    <NavLink to="seasons">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Seasons" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.destinationType && userPermissions.destinationType.listing && (
    <NavLink to="destination-types">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Destination Types" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.destinations && userPermissions.destinations.listing && (
    <NavLink to="destination">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Destinations" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.trips && userPermissions.trips.listing && (
    <NavLink to="trips">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Trips" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.tags && userPermissions.tags.listing && (
    <NavLink to="tags">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Tags" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.entities && userPermissions.entities.listing && (
    <NavLink to="entity">
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Entity" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.countries && userPermissions.countries.listing && (
    <NavLink to="country">
      <ListItemButton>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Country" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.states && userPermissions.states.listing && (
    <NavLink to="state">
      <ListItemButton>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="State" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.cities && userPermissions.cities.listing && (
    <NavLink to="city">
      <ListItemButton>
        <ListItemIcon>
          <LocationCitySharpIcon />
        </ListItemIcon>
        <ListItemText primary="City" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.village && userPermissions.village.listing && (
    <NavLink to="village">
      <ListItemButton>
        <ListItemIcon>
          <HolidayVillageIcon />
        </ListItemIcon>
        <ListItemText primary="Village" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.jobs && userPermissions.jobs.listing && (
    <NavLink to="jobs">
      <ListItemButton>
        <ListItemIcon>
          <HolidayVillageIcon />
        </ListItemIcon>
        <ListItemText primary="Jobs" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.applicants && userPermissions.applicants.listing && (
    <NavLink to="applicants">
      <ListItemButton>
        <ListItemIcon>
          <HolidayVillageIcon />
        </ListItemIcon>
        <ListItemText primary="Job Applicants" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.quotes && userPermissions.quotes.listing && (
    <NavLink to="quotes">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Quotes" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.settings && userPermissions.settings.listing && (
    <NavLink to="settings/update">
      <ListItemButton>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.longWeekend && userPermissions.longWeekend.listing && (
    <NavLink to="longweekend">
      <ListItemButton>
        <ListItemIcon>
          <ReceiptLongIcon />
        </ListItemIcon>
        <ListItemText primary="Long Weekend" />
      </ListItemButton>
    </NavLink>
    )}
    {userPermissions?.mediaListing && userPermissions.mediaListing.listing && (
    <NavLink to="medialisting">
      <ListItemButton>
        <ListItemIcon>
          <MmsIcon />
        </ListItemIcon>
        <ListItemText primary="Media listing" />
      </ListItemButton>
    </NavLink>
    )}

  </React.Fragment>
  )

}