
import { makeStyles } from 'tss-react/mui';
// import { makeStyles } from "@mui/material";

export const useStyles = makeStyles()((theme) => ({
  media_name_box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  media_url_box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    gap: '1rem',
  },
  // modal_box: {
  //   position: 'absolute', 
  //   top: '50%', 
  //   left: '50%', 
  //   transform: 'translate(-50%, -50%)', 
  //   boxShadow: 24, 
  //   p: 4, 
  //   width: "25%",
  //   height: "170px",
  //   backgroundColor: "#f9f6f6",
  //   borderRadias: "10px"
  // },
}));