import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { Grid, Paper, Typography, Box } from '@mui/material';
import { useStyles } from './DashboardStyle';
import { ApiConfig } from "../../api/config/ApiConfig";
import { GetDestination } from '../../api/destination';

const DashboardData = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    active_users: 0,
    approved_trips: 0,
    active_jobs: 0,
    job_applicants: 0,
  });

  const { baseUrl, getDashboardData } = ApiConfig;

  const fetchData = async () => {
    const url = new URL(
      getDashboardData,
      baseUrl,
    );
    const response = await GetDestination(url);
    switch (response.status) {
      case "success":
        setData(response.data);
        break;
      case "failed":
        enqueueSnackbar(response.message, { variant: "error" });
        break;
      default:
        dispatch({ type: "API_ERROR", payload: response });
        navigate("/admin/error");
        break;
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
  <>
  <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
        <Typography gutterBottom className={classes.paperusers}>
          Dashboard
        </Typography>
      </Paper>
      <Grid container spacing={2}>
        {/* Users */}
        <Grid item xs={12} sm={3}>
          <Box
            component={Paper}
            className={classes.dashboardBox}
            sx={{ padding: 2 }}
          >
            <Typography variant="h6" className={classes.metricTitle}>
              Users
            </Typography>
            <Typography variant="h4" className={classes.metricValue}>
              {data.active_users}
            </Typography>
          </Box>
        </Grid>

        {/* Trips */}
        <Grid item xs={12} sm={3}>
          <Box
            component={Paper}
            className={classes.dashboardBox}
            sx={{ padding: 2 }}
          >
            <Typography variant="h6" className={classes.metricTitle}>
              Trips
            </Typography>
            <Typography variant="h4" className={classes.metricValue}>
            {data.approved_trips}
            </Typography>
          </Box>
        </Grid>

        {/* Jobs */}
        <Grid item xs={12} sm={3}>
          <Box
            component={Paper}
            className={classes.dashboardBox}
            sx={{ padding: 2 }}
          >
            <Typography variant="h6" className={classes.metricTitle}>
              Jobs
            </Typography>
            <Typography variant="h4" className={classes.metricValue}>
            {data.active_jobs}
            </Typography>
          </Box>
        </Grid>
        {/* Job Applicants */}
        <Grid item xs={12} sm={3}>
          <Box
            component={Paper}
            className={classes.dashboardBox}
            sx={{ padding: 2 }}
          >
            <Typography variant="h6" className={classes.metricTitle}>
              Job Applicants
            </Typography>
            <Typography variant="h4" className={classes.metricValue}>
              {data.job_applicants}
            </Typography>
          </Box>
        </Grid>
      </Grid>
  </>  
)
}

export default DashboardData