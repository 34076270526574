import { makeStyles } from "tss-react/mui";
// import { makeStyles } from "@mui/material";

export const useStyles = makeStyles()((theme) => ({
  paperCss: {
    width: '100%',
    padding: '2% 0 2% 2%'
  },
  gridCss: {
    float: 'left',
    width: '48%',
    marginRight: "2%",
    marginBottom: 20,
  },
  firstChild: {
    margin: 0,
  },
  gridTextInputCss: {
    width: '100%',
  },
  gridTextInputHalfCss: {
    width: '40%',
    marginRight: "10px",
  },
  gridButtonCss: {
    width: '100%',
    float: 'left',
  },
  selectBoxCss: {
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: '16.5px 14px',
    }
  },
  paperRoles: {
    height: 60,
    marginBottom: "15px",
    alignItems: "center",
    padding: "10px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between"
  },
  paperusers: {
    fontSize: 18,
    fontWeight: "600",
    padding: "5px"
  },
  image_style: {
    width: '100%',
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "25px",
    paddingBottom: "25px",
  },
  image_effect: {
    width: "100%",
    height: "150px",
    objectFit: "cover",
    border: "1px solid #f1f1f1",
    background: "#fff",
    padding: "5px",
    '&:hover': {
      cursor: 'pointer',
      boxShadow: "revert-layer"
    },
  },  
  iconButtonForClose: {
    position: "absolute", 
    right: "10px", 
    top: "10px"
  },
  popupImageForDestinationMedia: {
    width: "100%", 
    height: "auto"
  },
  image_wrapper: {
    position: 'relative',
    display: 'inline-block',
    width: "20%",
    "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root": {
      position: "absolute",
      left: "260px",
      color: "red",
      zIndex: '1'
    },
    "& .css-78trlr-MuiButtonBase-root-MuiIconButton-root": {
      position: "absolute",
      right: "50px",
      color: "#1976d2",
      zIndex: '1'
    }
  },
  responsive_dialog: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", /* Adjust the transparency as needed */
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  responsive_dialog: {
    display: "flex",
  },
  timeTitleCss: {
    width: "13%",
    display: "flex",
    float: "left",
    marginTop: "14px",
  },
  scheduleSection: {
    display: "inline-block",
  }

}));
