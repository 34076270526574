import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
export function ServerError() {

  const navigate =useNavigate();
  const apiError =useSelector((state)=>state.apiError)
  const check =apiError && apiError.code === "ERR_NETWORK"
    ? "error"
    : null;

  function onPress (){
    navigate('/')
  }
  if(check === "error"){
    return (
      <div style={{ textAlign: 'center',margin:50}}>
      <Typography variant="h1">500</Typography>
      <Typography variant="h4">Internal Server Error</Typography>
      <Typography variant="body1">
        Sorry, something went wrong in our server. Please try again later.
      </Typography>
      <Button variant="contained" color="primary" onClick={onPress}>
        Go Back Home
      </Button>
    </div>
    )
  }
   else {

    return <div>Oops something went wrong</div>;
  }
  
}
