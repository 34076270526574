import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, CircularProgress, Typography } from '@mui/material';
import { InsertLongWeekend, EditLongWeekend } from '../../api/longweekend';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './style';
import DescriptionEditor from "../../common/TextEditor/DescriptionEditor";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const LongWeekendForm = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading);
    const [formData, setFormData] = useState({
        name: "",
        start_date: "",
        end_date: "",
        sort_description: "",
        description: "",
    });

    const [data, setData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { classes } = useStyles();


    useEffect(() => {
        if (props.longWeekendId) {
            setIsEditing(true);
            setFormData(props.longWeekendData);
        }
    }, []);

    const insertData = async (formData) => {
        try {
            dispatch(startLoading());
            const newData = { ...formData };
            setData((prevData) => [...prevData, newData]);
            // Make the API call to update the data
            const response = await InsertLongWeekend(newData);
            // Check if the API call was successful
            switch (response.status) {
                case "success":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/admin/longWeekend');
                    break;
                default:
                    dispatch(stopLoading());
                    console.log('Error insert data');
                    break;
            }
        } catch (error) {
            dispatch(stopLoading());
            // Handle any network or API call errors
            console.log('An error occurred', error);
        }
    };

    const updateData = async (formData) => {
        try {
            dispatch(startLoading());
            // Make the API call to update the data
            const response = await EditLongWeekend(formData);
            // Check if the API call was successful
            switch (response.status) {
                case "success":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/admin/longWeekend');
                    break;
                default:
                    dispatch(stopLoading());
                    console.log('Error updating data');
                    break;
            }
        } catch (error) {
            dispatch(stopLoading());
            // Handle any network or API call errors
            console.log('An error occurred', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData, [name]: value,
        }));
    };

    const handleTextEditorChange = (content) => {
        setFormData((prevData) => ({
            ...prevData,
            description: content,
        }));
      }; 

    const handleSubmit = (e) => {
        e.preventDefault();
        const isFormValid = validateForm(formData);
        if (isFormValid) {
            if (isEditing) {
                // Handle update logic
                updateData(formData);
            } else {
                // Handle create logic
                insertData(formData);
            }
        } else {
            enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
        }
    };

    const validateForm = (formData) => {
        // Perform validation checks based on your requirements
        const { name, description } = formData;

        // Check if required fields are filled
        if (!name || !description) {
            return false;
        }
        return true;
    };

    return (
        <>
            <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
                <Typography gutterBottom className={classes.paperusers}>
                    {isEditing ? 'Edit Long Weekend' : 'Create Long Weekend'}
                </Typography>
            </Paper>
            <Grid container spacing={0}>
                {isLoading && <Loader />}
                <Paper className={classes.paperCss}>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                label="Name"
                                value={formData.name}
                                onChange={handleInputChange}
                                id="name"
                                name="name"
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={classes.gridTextInputCss}
                                label="Start Date"
                                value={formData.start_date}
                                onChange={handleInputChange}
                                name="start_date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={classes.gridTextInputCss}
                                label="End Date"
                                value={formData.end_date}
                                onChange={handleInputChange}
                                name="end_date"
                                type="date"
                                format="MM-DD-YYYY"
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={classes.gridTextInputCss}
                                label="Sort Description"
                                value={formData.sort_description}
                                onChange={handleInputChange}
                                name="sort_description"
                            />
                        </Grid>
                        <Grid className={classes.gridCss} style={{ width: '98%' }}>
                            <DescriptionEditor content={formData.description ? formData.description : ''} handleEditorChange={handleTextEditorChange} />
                        </Grid>
                    </Box>
                    <Grid className={classes.gridButtonCss}>
                        <Button onClick={handleSubmit} variant="contained" color="primary">
                            {isEditing ? 'Update' : 'Create'}
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default LongWeekendForm