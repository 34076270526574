import React, { useState } from 'react';
import { LoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { googleMapsApiKey } from '../../constants';

const containerStyle = {
  width: '98%',
  marginBottom: '35px',
  float: 'left',
};
const inputStyle = {
  padding: '15px',
  width: '100%',
  border: '1px solid rgb(196 196 196)',
  borderRadius: '4px',
};
const autocompleteContainerStyle = {
  position: 'absolute',
  zIndex: 99999,
  background: 'white',
  width: '100%',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',
};
const inputMainDiv = {
  position: "relative",
  float: 'left',
  width: '100%',
}

const googleLibraries = ['places'];

const LocationSearch = ({ google, onLocationChange }) => {
  const [address, setAddress] = useState('');

  const handleAddressChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      onLocationChange(latLng)
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  return (
    <div style={containerStyle}>
      <LoadScript libraries={googleLibraries} googleMapsApiKey={googleMapsApiKey}>
        <PlacesAutocomplete
          value={address}
          onChange={handleAddressChange}
          onSelect={handleSelect}
          searchOptions={{
            types: false,
            componentRestrictions: { country: 'IN' }, // Limit suggestions to India
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div style={inputMainDiv}>
              <input
                {...getInputProps({
                  placeholder: 'Find location for lat and long',
                  className: 'location-search-input',
                  style: inputStyle,
                })}
              />
              <div
                className="autocomplete-dropdown-container"
                style={autocompleteContainerStyle}
              >
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  return (
                    <div
                      key={suggestion.placeId}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </LoadScript>
    </div>
  );
};

export default LocationSearch;
