
import { makeStyles } from 'tss-react/mui';
// import { makeStyles } from "@mui/material";

export const useStyles = makeStyles()((theme) => ({
  paperCss: {
    width: '100%',
    padding: '2% 0 2% 2%'
  },
  gridCss: {
    float: 'left',
    width: '48%',
    marginRight: "2%",
    marginBottom:20,
  },
  firstChild: {
    margin:0,
  },
  gridTextInputCss: {
    width: '100%',
  },
  gridButtonCss: {
    width: '100%',
    float: 'left',
  },
  selectBoxCss: {
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: '16.5px 14px',
    }
  },
  paperRoles: {
    height: 60,
    marginBottom: "15px",
    alignItems: "center",
    padding: "10px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between"
  },
  paperusers: {
    fontSize:18,
    fontWeight:"600",
    padding: "5px"
  }
}));