import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, FormControl, Autocomplete, CircularProgress, Typography } from '@mui/material';
import { InsertCtaBanner, EditCtaBanner } from '../../api/ctaBanner';
import { useSnackbar } from 'notistack';
import { ApiConfig } from "../../api/config/ApiConfig";
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Style';
import { mediaBaseUrl } from '../../constants';
import { GetTrip } from "../../api/trip";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const BannerForm = (props) => {
    const { baseUrl, getTripList } = ApiConfig;

    const [tripList, setTripList] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading)
    const [formData, setFormData] = useState({
        title: "",
        button_text: "",
        button_link: '',
        trips: [],
        image_upload: "",
        banner_image: "",
    });

    const [data, setData] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { classes } = useStyles();

    async function fetchTripList() {
        const url = new URL(
            getTripList,
            baseUrl,
            );
            dispatch(startLoading());
            const tripData = await GetTrip(url);
        switch (tripData.status) {
            case "success": 
                dispatch(stopLoading());
                setTripList(tripData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(tripData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: tripData });
              navigate("/admin/error");
              break;
        }
    }

    useEffect(() => {
        fetchTripList();
        if (props.ctaBannerId) {
            setIsEditing(true);
            setFormData(props.ctaBannerData);
        }
    }, []);

    const insertData = async (formData) => {
        try {
            dispatch(startLoading());
            const newData = { ...formData };
            setData((prevData) => [...prevData, newData]);
            // Make the API call to update the data
            const response = await InsertCtaBanner(newData);
            // Check if the API call was successful
            switch (response.status) {
                case "success":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/admin/ctabanners');
                    break;
                default:
                    dispatch(stopLoading());
                    console.log('Error insert data');
                    break;
            }
        } catch (error) {
            // Handle any network or API call errors
            console.log('An error occurred', error);
        }
    };

    const updateData = async (formData) => {
        try {
            dispatch(startLoading());
            // Make the API call to update the data
            const response = await EditCtaBanner(formData);
            // Check if the API call was successful
            switch (response.status) {
                case "success":
                    dispatch(stopLoading());
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/admin/ctabanners');
                    break;
                default:
                    dispatch(stopLoading());
                    console.log('Error updating data');
                    break;
            }
        } catch (error) {
            // Handle any network or API call errors
            console.log('An error occurred', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image_upload") {
          setFormData((prevData) => ({
            ...prevData,
            image_upload: files[0], // Store the selected file in the formData
          }));
        } else {
          // For other inputs (non-file inputs), update the form data as before
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
    
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isFormValid = validateForm(formData);
        if (isFormValid) {
            if (isEditing) {
                // Handle update logic
                updateData(formData);
            } else {
                // Handle create logic
                insertData(formData);
            }
        } else {
            enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
        }
    };

    const validateForm = (formData) => {
        // Perform validation checks based on your requirements
        const { title, button_text, button_link, image_upload, banner_image} = formData;

        // Check if required fields are filled
        if (!title || !button_text || !button_link || (!image_upload && !banner_image)) {
            return false;
        }

        // Additional validation checks...

        return true;
    };
    
    return (
        <>
            <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
                <Typography gutterBottom className={classes.paperusers}>
                    {isEditing ? 'Edit' : 'Create'} CTA Banner
                </Typography>
            </Paper>
            <Grid container spacing={0}>
                {isLoading && <Loader />}
                <Paper className={classes.paperCss}>
                    <Box noValidate sx={{ mt: 1 }}>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                label="Title"
                                value={formData.title}
                                onChange={handleInputChange}
                                id="title"
                                name="title"
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                label="Button Text"
                                value={formData.button_text}
                                onChange={handleInputChange}
                                id="button_text"
                                name="button_text"
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                label="Button Link"
                                value={formData.button_link}
                                onChange={handleInputChange}
                                id="button_link"
                                name="button_link"
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={tripList}
                                getOptionLabel={(trip) => `${trip.trip_id} - ${trip.title}`}
                                value={tripList.filter((trip) => formData.trips.includes(trip.id))}
                                onChange={(event, newValue) => {
                                    if (newValue && newValue.length > 0) {
                                      const selectedIds = newValue.map((selectedTrip) => selectedTrip.id);
                                      handleInputChange({ target: { name: 'trips', value: selectedIds } });
                                    } else {
                                      setFormData((prev) => ({ ...prev, trips: [] }));
                                    }
                                  }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    name="trips"
                                    label="Trips"
                                    />
                                )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                inputProps={{ accept: 'image/*' }}
                                label="Banner Image"
                                onChange={handleInputChange}
                                name="image_upload"
                                type="file"
                                required
                            />
                            {formData.banner_image &&
                                    <img src={mediaBaseUrl + 'uploads/' + formData.banner_image} width={200} />
                             }
                        </Grid>
                    </Box>
                    <Grid className={classes.gridButtonCss}>
                        <Button onClick={handleSubmit} variant="contained" color="primary">
                            {isEditing ? 'Update' : 'Create'}
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default BannerForm