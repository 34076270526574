import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetJob = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertJob = async (props) => {
  const { baseUrl, job } = ApiConfig;

  const url =
    baseUrl +
    job

    let params = qs.stringify({
      "title": props.title,
      "description": props.description,
      "posted_at": props.posted_at,
      "deadline": props.deadline,
      "is_active": (props.is_active == true || props.is_active == 'true') ? 1 : 0
    });
  
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};


export const EditJob = async (props) => {
  const { baseUrl, job } = ApiConfig;

  const url =
    baseUrl +
    job + `?id=${props.id}`

    let params = qs.stringify({
      "title": props.title,
      "description": props.description,
      "posted_at": props.posted_at,
      "deadline": props.deadline,
      "is_active": (props.is_active == true || props.is_active == 'true') ? 1 : 0
    });

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (id) => {
  const { baseUrl, job } = ApiConfig;

  const url =
    baseUrl +
    job + `?id=${id}`

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallGet(url, headers);
};

export const DeleteJob = async (id) => {
  const { baseUrl, job } = ApiConfig;

  const url =
    baseUrl +
    job + `?id=${id}`

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};