import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetJobApplicant = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const SingleList = async (id) => {
  const { baseUrl, applicant } = ApiConfig;

  const url =
    baseUrl +
    applicant + `?id=${id}`

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallGet(url, headers);
};

export const DeleteJobApplicant = async (id) => {
  const { baseUrl, applicant } = ApiConfig;

  const url =
    baseUrl +
    applicant + `?id=${id}`

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};