import { ApiCallGet, ApiCallPost, ApiCallDelete } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";

export const getStateListing = async(props) => {
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };
  const { baseUrl, getStates } = ApiConfig;
  const url = `${baseUrl}${getStates}/?country_id=${props.country_id}&per_page=1000`;
  return await ApiCallGet(url, headers);
}
