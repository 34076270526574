import React, { useState, useEffect } from 'react';
import UserForm from "./form";


const CreateUser = () => {
    return (
    <>
      <UserForm />
    </>  
  )
}

export default CreateUser