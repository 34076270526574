import React, { useState, useEffect } from "react";
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Paper, Box, Typography, Autocomplete, CircularProgress } from "@mui/material";
import { useStyles } from "./style";
import { useNavigate } from "react-router-dom";
import { CreateReview, EditReview } from "../../api/review";
import { useSnackbar } from "notistack";
import { GetDestination } from '../../api/destination';
import { GetEntity } from "../../api/entity";
import { ApiConfig } from "../../api/config/ApiConfig";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from "../../common/Loader";

const NewReview = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading)
  const [usersList, setUsersList] = useState([]);
  const [data, setData] = useState([]);
  const [destinationList, setDestinationList] = useState([])
  const [entityList, setEntityList] = useState([])
  const [isActive, setIsActive] = useState(true);
  const active = isActive ? 'active' : 'inactive';
  const [detail, setDetail] = useState({
    reviewer_id: "",
    rating: "",
    details: "",
    item_id: 0,
    type: "",
    destination: "",
    status: "",
  });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { baseUrl, getUsersList, getdestination, getEntity } = ApiConfig;

  async function fetchDestinationData() {
    const url = new URL(
      getdestination,
      baseUrl,
    );
    const destinationData = await GetDestination(url);
    switch (destinationData.status) {
      case "success": 
          dispatch(stopLoading());
          setDestinationList(destinationData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(destinationData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: destinationData });
        navigate("/admin/error");
        break;
    }
  }

  async function fetchEntityData() {
    const url = new URL(
      getEntity,
      baseUrl,
    );
    dispatch(startLoading());
    const responseData = await GetEntity(url);
    switch (responseData.status) {
      case "success": 
          dispatch(stopLoading());
          setEntityList(responseData.data);
        break;
      case "failed":
        dispatch(stopLoading());
        enqueueSnackbar(responseData.message, { variant: "error" });
        break;
      default:
        dispatch(stopLoading());
        dispatch({ type: "API_ERROR", payload: responseData });
        navigate("/admin/error");
        break;
    }
  }

  const fetchUsersList = async () => {
      const url = new URL(getUsersList, baseUrl);
      dispatch(startLoading());
      const userList = await GetDestination(url);
      switch (userList.status) {
        case "success": 
            dispatch(stopLoading());
            setUsersList(userList.data);
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(userList.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          dispatch({ type: "API_ERROR", payload: userList });
          navigate("/admin/error");
          break;
      }
  }

  useEffect(() => {
    fetchUsersList();
    fetchEntityData()
    fetchDestinationData()
    if (props.reviewId) {
      setIsEditing(true);
      setDetail(props.reviewData);
    }
  }, []);

  const insertData = async (detail) => {
    try {
      dispatch(startLoading());
      const newData = { ...detail };
      setData((prevData) => [...prevData, newData]);
      // Make the API call to update the data
      const response = await CreateReview(newData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" });
          navigate('/admin/review');
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const updateData = async (detail) => {
    try {
      dispatch(startLoading());
      // Make the API call to update the data
      const response = await EditReview(detail);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" });
          navigate('/admin/review');
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const handleSignInClick = (e) => {
    e.preventDefault();
    const isFormValid = validateForm(detail);
    if (isFormValid) {
      if (isEditing) {
        // Handle update logic
        updateData(detail);
      } else {
        // Handle create logic
        insertData(detail);
      }
    } else {
      enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
    }
  };

  const { classes } = useStyles();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDetail((prevData) => ({
      ...prevData, [name]: value,
    }));
  };

  const validateForm = (detail) => {
    // Perform validation checks based on your requirements
    const { reviewer_id, details, type, item_id, status } = detail;

    // Check if required fields are filled
    if (!reviewer_id || !details || !type || !item_id || !status) {
      return false;
    }

    // Additional validation checks...

    return true;
  };
  
  return (
    <>
      <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
        <Typography gutterBottom className={classes.paperusers}>
          {isEditing ? 'Edit Review' : 'Create Review'}
        </Typography>
      </Paper>
      <Grid container spacing={0}>
        {isLoading && <Loader />}
        <Paper className={classes.paperCss}>
          <Box component="form" noValidate sx={{ mt: 1 }}>
          <Grid className={classes.gridCss}>
                <FormControl style={{ width: "100%" }} size="small">
                    <Autocomplete
                    id="tags-outlined"
                    options={usersList}
                    getOptionLabel={(usersList) => `${usersList.first_name} ${usersList.last_name} (${usersList.username}) `}
                    value={usersList.find((reviewer) => reviewer.id === detail.reviewer_id) || null}
                    onChange={(event, newValue) => {
                      if (newValue?.id) {
                        handleInputChange({ target: { name: 'reviewer_id', value: newValue ? newValue.id : '' } });
                      } else {
                        setDetail(prev => ({ ...prev, reviewer_id: "" }))
                      }
                        }}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        name="reviewers"
                        label="Reviewers"
                        />
                    )}
                    />
                </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={classes.gridTextInputCss}
                label="Details"
                value={detail.details ? detail.details : ''}
                onChange={handleInputChange}
                name="details"
                type="text"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="stay-type-label">Type</InputLabel>
                <Select
                  labelId="Type"
                  className={classes.selectBoxCss}
                  label="Type"
                  value={detail.type}
                  onChange={handleInputChange}
                  name="type"
                >
                  <MenuItem value="destination">Destination</MenuItem>
                  <MenuItem value="entity">Entity</MenuItem>
                </Select>
              </FormControl>
            </Grid>


            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="stay-type-label">{detail.type}</InputLabel>
                <Select
                  labelId="Type"
                  className={classes.selectBoxCss}
                  label="Type"
                  value={detail.item_id ? detail.item_id : ""}
                  onChange={handleInputChange}
                  name="item_id"
                >
                  {detail.type === "destination" &&
                    destinationList.map((destination) => (
                      <MenuItem value={destination.id} key={destination.id}>
                        {destination.name}
                      </MenuItem>
                    ))}
                  {detail.type === "entity" &&
                    entityList.map((entity) => (
                      <MenuItem value={entity.id} key={entity.id}>
                        {entity.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
                <FormControl style={{ width: "100%" }} size="small">
                    <InputLabel id="stay-status-label">Rating</InputLabel>
                    <Select
                    labelId="Rating"
                    className={classes.selectBoxCss}
                    label="Rating"
                    value={detail.rating}
                    onChange={handleInputChange}
                    name="rating"
                    >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid className={classes.gridCss}>
                <FormControl style={{ width: "100%" }} size="small">
                    <InputLabel id="stay-status-label">Status</InputLabel>
                    <Select
                    labelId="Status"
                    className={classes.selectBoxCss}
                    label="Status"
                    value={detail.status}
                    onChange={handleInputChange}
                    name="status"
                    >
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="unapproved">Unapproved</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
          </Box>
          <Grid className={classes.gridButtonCss}>
            <Button onClick={handleSignInClick} variant="contained" color="primary">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default NewReview;
