import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { TextField, Button, Grid, Paper, Box, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { useStyles } from './Style';
import { PasswordChange } from '../../api/user'

const ChangePassword = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
});

  const updateData = async (formData) => {
    try {
        const response = await PasswordChange(formData);
        switch (response.status) {
            case "success":
              enqueueSnackbar(response.message, { variant: "success" });
              resetForm()
              break;
            case "failed":
              enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
              break;
            case "error":
              enqueueSnackbar(response.message, { variant: "error" });
              break;
            default:
              enqueueSnackbar('Something went wrong', { variant: 'error' });
              break;
        }
        setIsSubmitting(false)
    } catch (error) {
        console.log('An error occurred', error);
    }
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      setIsSubmitting(true);
      updateData(formData)
    }
  };

  const validateForm = (formData) => {
    const { old_password, new_password, confirm_password  } = formData;
    if (!old_password || !new_password || !confirm_password) {
      enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
      return false;
    } else if(old_password == new_password) {
      enqueueSnackbar(`Old password and new password is same`, { variant: "warning" });
      return false;
    } else if(new_password != confirm_password) {
      enqueueSnackbar(`Confirm Password is not match`, { variant: "warning" });
      return false;
    }
    return true;
  }

  const resetForm = () => {
    setFormData((prevData) => ({
      ...prevData,
      old_password: "",
      new_password: "",
      confirm_password: "",
    }));
  }
    return (
    <>
      <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
        <Typography gutterBottom className={classes.paperusers}>
          Change Password
        </Typography>
      </Paper>
      <Grid container spacing={0}>
        <Paper className={classes.paperCss}>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid className={classes.gridCss}>
              <TextField
                autoComplete="off"
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                label="Old Password"
                value={formData.old_password}
                onChange={handleInputChange}
                name="old_password"
                margin="normal"
                type="password"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                autoComplete="off"
                className={classes.gridTextInputCss}
                label="New Password"
                value={formData.new_password}
                onChange={handleInputChange}
                name="new_password"
                type="password"
                required
              />
            </Grid>

            <Grid className={classes.gridCss}>
              <TextField
                autoComplete="off"
                className={classes.gridTextInputCss}
                label="Confirm Password"
                value={formData.confirm_password}
                onChange={handleInputChange}
                name="confirm_password"
                type="password"
                required
              />
            </Grid>
          </Box>
          <Grid className={classes.gridButtonCss}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>  
  )
}

export default ChangePassword