import { makeStyles } from 'tss-react/mui';
// import { makeStyles } from "@mui/material";

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: '4rem',
    marginBottom: '2rem',
  },
  errorStatus: {
    fontSize: '3rem',
    marginBottom: '1rem',
  },
  errorDescription: {
    fontSize: '2rem',
    marginBottom: '2rem',
  },
  errorData: {
    fontSize: '2rem',
    marginBottom: '2rem',
  },
}));