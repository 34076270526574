import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

  export const EditSettings = async (props) => {
    const { baseUrl, settings } = ApiConfig;
  
    const url =
      baseUrl +
      settings

      let params = qs.stringify({
        "admin_email": props.admin_email,
        'signup_user_role': props.signup_user_role,
        'facebook': props.facebook,
        'twitter': props.twitter,
        'youtube': props.youtube,
        'instagram': props.instagram,
      });
      
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };
  
    return await ApiCallPut(url, params, headers);
  };

  export const SingleList = async () => {
    const { baseUrl, settings } = ApiConfig;
  
    const url =
      baseUrl +
      settings
      
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };
  
    return await ApiCallGet(url, headers);
  };