import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, Typography, CircularProgress } from '@mui/material';
import { InsertCountry, EditCountry } from '../../api/country';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../Country/Style';
import { mediaBaseUrl } from '../../constants';
import { slugify } from '../../helper';
import DescriptionEditor from "../../common/TextEditor/DescriptionEditor";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const CountryForm = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading)

  const [formData, setFormData] = useState({
    sort_name: "",
    name: "",
    slug: "",
    image_upload: "",
    featured_image_name: "",
    description: ""
  });

  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { classes } = useStyles();

  useEffect(() => {
    if (props.countryId) {
      setIsEditing(true);
      setFormData(props.countryData);
    }
  }, []);



  const insertData = async (formData) => {
    try {
      dispatch(startLoading());
      const newData = { ...formData };
      setData((prevData) => [...prevData, newData]);
      // Make the API call to update the data
      const response = await InsertCountry(newData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" })
          navigate('/admin/country');
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;  
        default:
          dispatch(stopLoading());
          console.log('Error insert data');
          break;
      }
    } catch (error) {
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const updateData = async (formData) => {
    try {
      dispatch(startLoading());
      // Make the API call to update the data
      const response = await EditCountry(formData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" })
          navigate('/admin/country');
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;  
        default:
          console.log('Error updating data');
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image_upload") {
      setFormData((prevData) => ({
        ...prevData,
        image_upload: files[0], // Store the selected file in the formData
      }));
    } else if( name === 'slug') {
      setFormData((prevData) => ({
        ...prevData,
        slug: slugify(value),
      }));
    } else {
      // For other inputs (non-file inputs), update the form data as before
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

  };

  const handleTextEditorChange = (content) => {
    setFormData((prevData) => ({
        ...prevData,
        description: content,
    }));
  }; 

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      if (isEditing) {
        // Handle update logic
        updateData(formData);
      } else {
        // Handle create logic
        insertData(formData);
      }
    } else {
      enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
    }
  };

  const validateForm = (formData) => {
    // Perform validation checks based on your requirements
    const { sort_name, name, slug } = formData;

    // Check if required fields are filled
    if (!sort_name || !name || !slug) {
      return false;
    }
    return true;
  };

  return (
    <>
    <Paper sx={{height: 60,marginBottom:2,padding:1.3,}}>
        <Typography  gutterBottom className={classes.paperusers}>
        {isEditing ? 'Edit Country' : 'Create Country'}
        </Typography>
      </Paper>
      <Grid container spacing={0}>
        {isLoading && <Loader />}
        <Paper className={classes.paperCss}>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid className={classes.gridCss}>
              <TextField
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                label="Sort Name"
                value={formData.sort_name}
                onChange={handleInputChange}
                id="sort_name"
                name="sort_name"
                margin="normal"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                label="Country"
                value={formData.name}
                onChange={handleInputChange}
                id="name"
                name="name"
                margin="normal"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
                <TextField
                    className={classes.gridTextInputCss}
                    label="Slug"
                    value={formData.slug}
                    onChange={handleInputChange}
                    id="slug"
                    name="slug"
                    required
                />
            </Grid>
            <Grid className={classes.gridCss} style={{ width: '98%' }}>
                <DescriptionEditor content={formData.description ? formData.description : ''} handleEditorChange={handleTextEditorChange} />
            </Grid>
            <Grid className={classes.gridCss}>
              <TextField
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                inputProps={{ accept: 'image/*' }}
                label="Image"
                onChange={handleInputChange}
                name="image_upload"
                multiple
                type="file"
              />
              {formData.featured_image_name &&
                <img src={mediaBaseUrl + 'uploads/' + formData.featured_image_name} width={200} />
              }
            </Grid>
          </Box>
          <Grid className={classes.gridButtonCss}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default CountryForm