import { ApiCallGet,ApiCallPost, ApiCallDelete} from "./config/ApiCall";
import { ApiConfig, } from "./config/ApiConfig";
import qs from "qs";

export const GetMedialisting = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertMediaupdate = async (props) => {
  const { baseUrl, mediaupdatename } = ApiConfig;

  const url =
    baseUrl +
    mediaupdatename

  let params = qs.stringify({
    "media_id": props.media_id,
    "new_media_name": props.new_media_name,
    "media_alt": props.media_alt,
  });  

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallPost(url, params, headers);
};


export const Deletemedia = async (id) => {
  const { baseUrl, mediadeletename } = ApiConfig;

  const url =
    baseUrl +
    mediadeletename + id;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};

