import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

function EditButton({ entityType, entityId, titleName }) {
  const navigate = useNavigate();
  const userPermissions = useSelector((state)=>state.userPermissions)
  const canEdit = userPermissions[entityType] && userPermissions[entityType].edit;
  if (canEdit) {
    return (
      <Tooltip arrow placement="left" title={`Edit ${titleName}`}>
        <IconButton onClick={() => navigate(`update/${entityId}`)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return null;
}

export default EditButton;