import React, { useState, useEffect } from 'react';
import BannerForm from './Form';


const CreateCtaBanner = () => {
    return (
    <>
      <BannerForm />
    </>  
  )
}

export default CreateCtaBanner