import React, { useState, useEffect } from 'react';
import LongWeekendForm from './form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/longweekend';

const UpdateLongWeekend = () => {
  const { id } = useParams();
  const [longWeekendData, setLongWeekendData] = useState();

  const fetchLongWeekendSingleData = async () => {
    try {
      const response = await SingleList(id);
      setLongWeekendData(response.data);
    } catch (error) {
      console.error('Error fetching LongWeekend data:', error);
    }
  };

  useEffect(() => {
    fetchLongWeekendSingleData();
  }, []);

  if (longWeekendData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <LongWeekendForm longWeekendId={id} longWeekendData={longWeekendData} />
    </>
  );
};

export default UpdateLongWeekend;
