import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        background: 'rgba(255, 255, 255, 0.7)',
        zIndex: 1000,
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Loader;
