import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';

function MoveToUsersButton({ entityType, buttonName, buttonClick }) {
  const navigate = useNavigate();
  const userPermissions = useSelector((state)=>state.userPermissions)
  const canMove = userPermissions[entityType] && userPermissions[entityType].move_to_users;
  if (canMove) {
    return (
      <Button color="primary" onClick={buttonClick} variant="contained" >{buttonName}</Button>
    );
  }

  return null;
}

export default MoveToUsersButton;