import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import { GetGuestUser, DeleteGuestUser } from "../../api/guestUser";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { ApiConfig } from "../../api/config/ApiConfig";
import { Box, Button,Paper, Typography } from "@mui/material";
import { useStyles } from './style';
import MoveToUsersButton from '../../common/GuestUsersToUsersButton/MoveToUsersButton'

const GuestUser = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [validationErrors, setValidationErrors] = useState({});
  const MemoizedMaterialReactTable = memo(MaterialReactTable);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { baseUrl, getGuestUser, moveGuestUsersToUsers } = ApiConfig;

  const fetchInfo = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const page = pagination.pageIndex+1;
    const per_page = pagination.pageSize;

    const url = new URL(
      getGuestUser,
      baseUrl,
    );
    url.searchParams.set('page', `${page}`);
    url.searchParams.set('per_page', `${per_page}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('globalFilter', globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    const response = await GetGuestUser(url);
    switch (response.status) {
      case "success":
        const { data, totalCount } = await response;
        setData(data);
        setRowCount(totalCount);
        break;
      case "failed":
        enqueueSnackbar(response.message, { variant: "error" });
        break;
      default:
        dispatch({ type: "API_ERROR", payload: response });
        navigate("/admin/error");
        break;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  useEffect(() => {
    fetchInfo();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,

  ]);

  // should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "created_at",
        header: "Created",
      },
    ],
    []
  );

  const moveToUsers = async () => {
    const isConfirmed = window.confirm('Are you sure you want to perform this action?');
    if(isConfirmed) {
      const url = new URL(
        moveGuestUsersToUsers,
        baseUrl,
      );
      const response = await GetGuestUser(url);
      switch (response.status) {
        case "success":
          enqueueSnackbar(response.message, { variant: "success" });
          navigate("/admin/users");
          break;
        case "failed":
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch({ type: "API_ERROR", payload: response });
          navigate("/admin/error");
          break;
      }
    }
  }

  return (
    <>
    <Paper className={classes.paperRoles}>
        <Typography  gutterBottom className={classes.paperusers}>
          Guest Users
        </Typography>
        {data.length > 0 && (
          <MoveToUsersButton entityType="guestUser" buttonName="Move to users" buttonClick={moveToUsers} />
        )}
      </Paper>
      <MaterialReactTable
        columns={columns}
        data={data}
        getRowId={(row) => row.id}
        initialState={{ showColumnFilters: false }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error loading data',
            }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
    </>
  )
}

export default GuestUser

