import { makeStyles } from 'tss-react/mui';
// import { makeStyles } from "@mui/material";

export const useStyles = makeStyles()((theme) => {
   return {
     root: {
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
     },
     image: {
      margin: 1,
      backgroundColor: '#bd5353' 
     },
     btn:{ 
      marginTop: 3,
       marginBottom: 2
       }
   };
 });
 