import React, { useState, useEffect } from 'react';
import RoleForm from "./form";


const CreateRole = () => {
    return (
    <>
      <RoleForm />
    </>  
  )
}

export default CreateRole