import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";

export const GetVillage = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertVillage = async (props) => {
  const { baseUrl, village } = ApiConfig;

  const url =
    baseUrl +
    village

    var params = new FormData();
    params.append("name", props.name)
    params.append("slug", props.slug)
    params.append("country_id", props.country_id)
    params.append("state_id", props.state_id)
    params.append("city_id", props.city_id)
    params.append("file", props.image_upload)
    params.append("description", props.description)
    params.append("latitude", props.latitude);
    params.append("longitude", props.longitude);

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};


export const EditVillage = async (props) => {
  const { baseUrl, editVillage } = ApiConfig;

  const url =
    baseUrl +
    editVillage + props.id

  var params = new FormData();
  params.append("name", props.name)
  params.append("slug", props.slug)
  params.append("country_id", props.country_id)
  params.append("state_id", props.state_id)
  params.append("city_id", props.city_id)
  params.append("file", props.image_upload)
  params.append("description", props.description)
  params.append("latitude", props.latitude);
  params.append("longitude", props.longitude);

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (village_id) => {
  const { baseUrl, editVillage } = ApiConfig;

  const url =
    baseUrl +
    editVillage + village_id

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const DeleteVillage = async (id) => {
  const { baseUrl, village } = ApiConfig;

  const url =
    baseUrl +
    village +
    `?id=${id}`;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};