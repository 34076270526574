import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';

function CreateButton({ entityType, titleName }) {
  const navigate = useNavigate();
  const userPermissions = useSelector((state)=>state.userPermissions)
  const canEdit = userPermissions[entityType] && userPermissions[entityType].add;
  if (canEdit) {
    return (
      <Button color="primary" onClick={() => navigate("create")} variant="contained" >{`Create ${titleName}`}</Button>
    );
  }

  return null;
}

export default CreateButton;