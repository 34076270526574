import React, { useState, useEffect } from 'react';
import StateForm from '../State/Form';


const CreateState = () => {
    return (
    <>
      <StateForm />
    </>  
  )
}

export default CreateState