import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetLongWeekend = async (url) => {
    const headers = {
        'content-type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallGet(url, headers);
};

export const InsertLongWeekend = async (props) => {
    const { baseUrl, longWeekend } = ApiConfig;

    const url =
        baseUrl +
        longWeekend

    let params = qs.stringify({
        'name': props.name,
        "start_date": props.start_date,
        "end_date": props.end_date,
        "sort_description": props.sort_description,
        "description": props.description,
    });

    const headers = {
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallPost(url, params, headers);
};


export const EditLongWeekend = async (props) => {
    const { baseUrl, editLongWeekend } = ApiConfig;

    const url =
        baseUrl +
        editLongWeekend + props.id

    let params = qs.stringify({
        'name': props.name,
        "start_date": props.start_date,
        "end_date": props.end_date,
        "sort_description": props.sort_description,
        "description": props.description,
    });

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallPut(url, params, headers);
};

export const SingleList = async (longWeekend_id) => {
    const { baseUrl, editLongWeekend } = ApiConfig;

    const url =
        baseUrl +
        editLongWeekend + longWeekend_id

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallGet(url, headers);
};

export const DeleteLongWeekend = async (id) => {
    const { baseUrl, longWeekend } = ApiConfig;

    const url =
        baseUrl +
        longWeekend +
        `?id=${id}`;

    const headers = {
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallDelete(url, headers);
};