import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import { GetReview, DeleteReview, ApproveReview } from "../../api/review";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useStyles } from './style';
import { ApiConfig } from "../../api/config/ApiConfig";
import ResponsiveDialog from "../../common/Popup/Confirm";
import EditButton from '../../common/EditButton/EditButton';
import DeleteButton from '../../common/DeleteButton/DeleteButton';
import CreateButton from '../../common/CreateButton/CreateButton';
import { useSelector } from 'react-redux';

const Review = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState({});
  const userPermissions = useSelector((state)=>state.userPermissions);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { baseUrl, getReview } = ApiConfig;

  const fetchInfo = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const page = pagination.pageIndex + 1;
    const per_page = pagination.pageSize;

    const url = new URL(
      getReview,
      baseUrl,
    );
    url.searchParams.set('page', `${page}`);
    url.searchParams.set('per_page', `${per_page}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('globalFilter', globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    const response = await GetReview(url);
    switch (response.status) {
      case "success":
        const { data, totalCount } = await response;
        setData(data);
        setRowCount(totalCount);
        break;
      case "failed":
        enqueueSnackbar(response.message, { variant: "error" });
        break;
      default:
        dispatch({ type: "API_ERROR", payload: response });
        navigate("/admin/error");
        break;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  useEffect(() => {
    fetchInfo();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    // approvalStatus
  ]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "reviewer_name",
        header: "Reviewer",
      },
      {
        accessorKey: "instagram_handle", //normal accessorKey
        header: "Instagram Handle",
        size: 40,
      },
      {
        accessorKey: "rating", //normal accessorKey
        header: "rating",
        size: 40,
      },
      {
        accessorKey: "type", //normal accessorKey
        header: "Type",
        size: 40,
      },
    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (clicked, id, index,fullName) => {
      if (clicked) {
        
        const response = await DeleteReview(id,fullName);
        // ResponsiveDialog("delete",id)
        switch (response.status) {
          case "success":
            enqueueSnackbar(response.message, { variant: "success" });
            data.splice(index, 1);
            setData([...data]);
            fetchInfo();
            break;
          case "failed":
            enqueueSnackbar(response.message, { variant: "warning" });
            break;
          case "error":
            enqueueSnackbar(response.message, { variant: "error" });

            break;
          default:
            dispatch({ type: "API_ERROR", payload: response });
            navigate("/admin/error");
            break;
        }
      } 
    },
    [dispatch, navigate, data,]
  );

  const handleApproveButton = useCallback(
    async (id) => {
      const response = await ApproveReview(id);
      switch (response.status) {
        case "success":
          enqueueSnackbar(response.message, { variant: "success" });
          fetchInfo();
          break;
        case "failed":
          enqueueSnackbar(response.message, { variant: "warning" });
          break;
        case "error":
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch({ type: "API_ERROR", payload: response });
          navigate("/admin/error");
          break;
      }
    },
    [dispatch, navigate]
  );

  return (
    <>
      <Paper className={classes.paperRoles}>
        <Typography gutterBottom className={classes.paperusers}>
          Reviews
        </Typography>
        <CreateButton entityType="reviews" titleName="Review" />
      </Paper>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableEditing={
          userPermissions?.reviews &&
          userPermissions.reviews.delete ||
          userPermissions.reviews.edit
            ? true
            : false
        }
        getRowId={(row) => row.id}
        initialState={{ showColumnFilters: false }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error loading data',
            }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <EditButton entityType="reviews" entityId={row.original.id} titleName="Review" />
            <DeleteButton entityType="reviews" entityId={row.original.id} entityName={row.original.fullName} index={row.index} handleDeleteRow={handleDeleteRow} titleName="Review" />
            <ResponsiveDialog
            name={row.original.fullName}
              id={row.original.id}
              index={row.index} 
              head={                
                  <Typography className={row.original.status === 'approved' ? classes.approved_button : classes.approve_button}>{row.original.status === 'approved' ? 'approved' : 'approve'}</Typography>
              }
              action={row.original.status}
              onPress={() => handleApproveButton(row.original.id)}
            />

          </Box>
        )}
      />
    </>
  )
}

export default Review