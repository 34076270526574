import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";

export const GetCountry = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertCountry = async (props) => {
  const { baseUrl, country } = ApiConfig;

  const url =
    baseUrl +
    country

  var params = new FormData();
  params.append("name", props.name)
  params.append("slug", props.slug)
  params.append("sort_name", props.sort_name)
  params.append("file", props.image_upload)
  params.append("description", props.description)
  
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};


export const EditCountry = async (props) => {
  const { baseUrl, editCountry } = ApiConfig;

  const url =
    baseUrl +
    editCountry + props.id

    var params = new FormData();
    params.append("name", props.name)
    params.append("slug", props.slug)
    params.append("sort_name", props.sort_name)
    params.append("file", props.image_upload)
    params.append("description", props.description)

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (countries_id) => {
  const { baseUrl, editCountry } = ApiConfig;

  const url =
    baseUrl +
    editCountry + countries_id

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallGet(url, headers);
};

export const DeleteCountry = async (id) => {
  const { baseUrl, country } = ApiConfig;

  const url =
    baseUrl +
    country +
    `?country_id=${id}`;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};