import React, { useState, useEffect } from 'react';
import VillageForm from './form';


const CreateVillage = () => {
    return (
    <>
      <VillageForm />
    </>  
  )
}

export default CreateVillage