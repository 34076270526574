import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetEntity = async (url) => {

  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };
  return await ApiCallGet(url, headers);

};

export const InsertEntity = async (props) => {
  const { baseUrl, entity } = ApiConfig;

  const url =
    baseUrl +
    entity

  let params = qs.stringify({
    'name': props.name,
    'country': props.country,
    'city_id': props.city_id,
    'state_id': props.state_id,
    'mobile_no': props.mobile_no,
    'stay_type': props.stay_type,
    'parking_available': props.parking_available,
    'lattitude': props.lattitude,
    'longitude': props.longitude,
    'price': props.price,
    'room_carpet_area_feet': props.room_carpet_area_feet,
  });

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};


export const EditEntity = async (props) => {
  const { baseUrl, editEntity } = ApiConfig;

  const url =
    baseUrl +
    editEntity + props.id

  let params = qs.stringify({
    'name': props.name,
    'country': props.country,
    'city_id': props.city_id,
    'state_id': props.state_id,
    'mobile_no': props.mobile_no,
    'stay_type': props.stay_type,
    'parking_available': props.parking_available,
    'lattitude': props.lattitude,
    'longitude': props.longitude,
    'price': props.price,
    'room_carpet_area_feet': props.room_carpet_area_feet,
  });

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (entities_id) => {
  const { baseUrl, editEntity } = ApiConfig;

  const url =
    baseUrl +
    editEntity + entities_id

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const DeleteEntity = async (id) => {
  const { baseUrl, entity } = ApiConfig;

  const url =
    baseUrl +
    entity +
    `?id=${id}`;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};