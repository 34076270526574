import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetTrip = async (url) => {
    const headers = {
        'content-type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallGet(url, headers);
};

export const SingleList = async (longWeekend_id) => {
    const { baseUrl, editTrip } = ApiConfig;

    const url =
        baseUrl +
        editTrip + longWeekend_id

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallGet(url, headers);
};

export const EditTrip = async (props) => {
    const { baseUrl, editTrip } = ApiConfig;

    const url =
        baseUrl +
        editTrip + props.trip_id

    var params = new FormData();
    params.append("user_id", props.user_id)
    params.append("title", props.title)
    params.append("file", props.image_upload)
    params.append("description", props.description)
    params.append("status", props.status)
    params.append("instagram_link", props.instagram_link || "")
    params.append("itineraries", JSON.stringify(props.itineraries));
    props.seasons.forEach((season) => {
        params.append('seasons[]', season);
    });

    props.travel_modes.forEach((mode) => {
        params.append('travel_modes[]', mode);
      });

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallPut(url, params, headers);
};

export const GetItineraries = async (url) => {
    const headers = {
        'content-type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallGet(url, headers);
};