import React, {useState, useEffect, useMemo, memo, useCallback} from 'react';
import { GetUsers, DeleteUser, ResetPasswordByID } from '../../api/user';
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { Box, IconButton, Modal, Button, Paper, Typography, Tooltip } from "@mui/material";
import { useStyles } from './Style';
import { ApiConfig } from "../../api/config/ApiConfig";
import EditButton from '../../common/EditButton/EditButton';
import DeleteButton from '../../common/DeleteButton/DeleteButton';
import CreateButton from '../../common/CreateButton/CreateButton';
import { useSelector } from 'react-redux';
import { LockReset } from '@mui/icons-material';

const UserListing = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const userPermissions = useSelector((state)=>state.userPermissions)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { baseUrl, getUsers } = ApiConfig;

  const fetchInfo = async () => { 
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const page=pagination.pageIndex+1;
    const per_page=pagination.pageSize;
    const url = new URL(
      getUsers,
      baseUrl,
    );
    url.searchParams.set(
      'page',
      `${page}`,
    );
    url.searchParams.set('per_page', `${per_page}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('globalFilter', globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    const response = await GetUsers(url);
    switch (response.status) {
      case "success":
        const { data, totalCount } = await response;
        setData(data);
        setRowCount(totalCount);
        break;
      case "failed":
        enqueueSnackbar(response.message, { variant: "error" });
        break;
      default:
        dispatch({ type: "API_ERROR", payload: response });
        navigate("/admin/error");
        break;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  const updatePassword = async () => {
    if (newPassword !== confirmPassword) {
      enqueueSnackbar("Passwords do not match", { variant: "warning" });
      return;
    }

    try {
      const response = await ResetPasswordByID({ id: selectedUserId, new_password: newPassword });
      switch (response.status) {
        case "success":
          enqueueSnackbar(response.message, { variant: "success" });
          setIsModalOpen(false);
          fetchInfo();
          break;
        case "failed":
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          enqueueSnackbar("Something went wrong", { variant: "error" });
          break;
      }
    } catch (error) {
      // Handle any network or API call errors
      console.log("An error occurred", error);
    }
  }
  
  useEffect(() => {
    fetchInfo();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "first_name",
        header: "First Name",
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "username",
        header: "username",
      },
      {
        accessorKey: "phone_number",
        header: "Phone Number",
      },
      {
        accessorKey: "user_role",
        header: "Role",
      },
    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (clicked, id, index,first_name) => {
      if (clicked) {
        const response = await DeleteUser(id,first_name);
        switch (response.status) {
          case "success":
            enqueueSnackbar(response.message, { variant: "success" });
            data.splice(index, 1);
            setData([...data]);
            fetchInfo();
            break;
          case "failed":
            enqueueSnackbar(response.message, { variant: "warning" });
            break;
          case "error":
            enqueueSnackbar(response.message, { variant: "error" });

            break;
          default:
            dispatch({ type: "API_ERROR", payload: response });
            navigate("/admin/error");
            break;
        }
      }
    },
    [dispatch, navigate, data]
  );
  
  return (
    <>
    <Paper className={classes.paperRoles}>
        <Typography  gutterBottom className={classes.paperusers}>
          Users
        </Typography>
        <CreateButton entityType="users" titleName="User" />
      </Paper>
      <MaterialReactTable
      columns={columns}
      data={data}
      enableEditing={
        userPermissions?.users &&
        userPermissions.users.delete ||
        userPermissions.users.edit ||
        userPermissions.users.reset_password
          ? true
          : false
      }
      getRowId={(row) => row.id}
      initialState={{ showColumnFilters: false }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <EditButton entityType="users" entityId={row.original.id} titleName="User" />
          <DeleteButton entityType="users" entityId={row.original.id} entityName={row.original.first_name} index={row.index} handleDeleteRow={handleDeleteRow} titleName="User" />
          {userPermissions?.users && userPermissions.users.reset_password && (
            <Tooltip arrow placement="left" title="Reset Password">
              <IconButton onClick={() => {
                setSelectedUserId(row.original.id)
                setIsModalOpen(true);
              }}>
                <LockReset />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    />
    <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="reset-password-modal"
        aria-describedby="reset-password-description"
      >
        <Box Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
          <Typography id="reset-password-modal" variant="h6" component="h2" style={{ marginTop: '0.6rem' }}>
            New Password
          </Typography>
          <Typography id="reset-password-description" sx={{ mt: 2 }}>
            <input
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              style={{ width: '100%', padding: '0.6rem' }}
            />
          </Typography>
          <Typography id="reset-password-modal" variant="h6" component="h2" style={{ marginTop: '0.5rem' }}>
            Confirm Password
          </Typography>
          <Typography id="reset-password-description" sx={{ mt: 2 }}>
            <input
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{ width: '100%', padding: '0.5rem' }}
            />
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={updatePassword}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default UserListing