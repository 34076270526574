import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, FormControlLabel, Checkbox, Typography, CircularProgress, } from '@mui/material';
import { InsertRole } from '../../api/userRole';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Style';
import { permissons } from '../../common/permissions';
import { EditRole } from '../../api/userRole';
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const RoleForm = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading)
  const [permissionsData, setPermissionsData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
  });
  const [checkAll, setCheckAll] = useState({});

  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { classes } = useStyles();

  useEffect(() => {
    setPermissionsData(permissons);
    if (props.roleId) {
      setIsEditing(true);
      setFormData(props.roleData);
      setPermissionsData(props.roleData.permissions);
  
      // Set initial state for checkAll based on props.roleData.permissions
      const checkAllState = {};
      Object.keys(props.roleData.permissions).forEach((key) => {
        const subKeys = Object.keys(props.roleData.permissions[key]);
        const allChecked = subKeys.every((subKey) => props.roleData.permissions[key][subKey]);
        checkAllState[key] = allChecked;
      });
      setCheckAll(checkAllState);
    }
  }, []);

  const insertData = async (formData) => {
    try {
      dispatch(startLoading());
      const newData = {
        ...formData,
        permissions: permissionsData
      };

      setData((prevData) => [...prevData, newData]);

      // Make the API call to update the data
      const response = await InsertRole(newData);

      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" });
          navigate('/admin/roles');
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const updateData = async (formData) => {
    try {
      dispatch(startLoading());
      const newData = {
        ...formData,
        permissions: permissionsData,
        id: props.roleId
      };

      setData((prevData) => [...prevData, newData]);
      // Make the API call to update the data
      const response = await EditRole(newData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" });
          navigate('/admin/roles');
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData, [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      if (isEditing) {
        // Handle update logic
        delete formData.password
        updateData(formData);
      } else {
        // Handle create logic
        insertData(formData);
      }
    } else {
      enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
    }
  };

  const validateForm = (formData) => {
    // Perform validation checks based on your requirements
    const { name } = formData;

    // Check if required fields are filled
    if (!name) {
      return false;
    }

    // Additional validation checks...

    return true;
  };

  const handleallCheckBoxDataChange = (section, subKey) => (event) => {
    const { checked } = event.target;

    setCheckAll((prevCheckAll) => ({
      ...prevCheckAll,
      [section]: checked,
    }));

    setPermissionsData((prevPermissions) => ({
      ...prevPermissions,
      [section]: {
        ...prevPermissions[section],
        [subKey]: checked,
      },
    }));
  };

  const handleCheckAll = (e, section) => {
    const checked = e.target.checked;
    setCheckAll((prevCheckAll) => ({
      ...prevCheckAll,
      [section]: checked,
    }));
    
    const updatedPermissionsData = { ...permissionsData };
    const sectionPermissions = updatedPermissionsData[section];
    const sectionKeys = Object.keys(sectionPermissions);

    sectionKeys.forEach((subKey) => {
      sectionPermissions[subKey] = checked;
    });
    setPermissionsData({ ...updatedPermissionsData, [section]: sectionPermissions });
  }

  return (
    <>
      <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
        <Typography gutterBottom className={classes.paperusers}>
          {isEditing ? 'Edit Role' : 'Create Role'}
        </Typography>
      </Paper>
      <Grid container spacing={0}>
        {isLoading && <Loader />}
        <Paper className={classes.paperCss}>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid className={classes.gridCss}>
              <TextField
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                label="Role Name"
                value={formData.name}
                onChange={handleInputChange}
                id="name"
                name="name"
                margin="normal"
                required
              />
            </Grid>
            <Grid container className={classes.gridCssForCheckbox} spacing={2}>
              {Object.keys(permissionsData).map((key) => (
                <Grid item key={key} xs={12} sm={6} md={4}>
                  <div>
                    <h3>{key}
                      <Checkbox
                        checked={checkAll[key]}
                        onChange={(e) => handleCheckAll(e, key)}
                      /></h3>
                    {Object.keys(permissionsData[key]).map((subKey) => (
                      <FormControlLabel
                        key={subKey}
                        control={
                          <Checkbox
                            checked={Boolean(permissionsData[key][subKey])}
                            onChange={handleallCheckBoxDataChange(key, subKey)}
                            name={`${key}-${subKey}`}
                          />
                        }
                        label={subKey}
                      />
                    ))}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid className={classes.gridButtonCss}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default RoleForm