import { ApiCallGet, ApiCallPost, ApiCallPut, ApiCallDelete } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetDestination = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertDestination = async (props) => {
  const { baseUrl, destination } = ApiConfig;

  const url =
    baseUrl +
    destination 
    let params = new FormData()
    params.append("name", props.name);
    params.append("slug", props.slug);
    params.append("details", props.details);
    params.append("state_id", props.state_id);
    params.append("city_id", props.city_id);
    params.append("village_id", props.village_id);
    params.append("latitude", props.latitude);
    params.append("longitude", props.longitude);
    // params.append("destination_type", JSON.stringify(props.destination_type));
    params.append("parking_available", props.parking_available );
    params.append("locker_available", props.locker_available);
    params.append("address", props.address);
    params.append("mondayOpeningTime", props.mondayOpeningTime);
    params.append("mondayClosingTime", props.mondayClosingTime);
    params.append("tuesdayOpeningTime", props.tuesdayOpeningTime);
    params.append("tuesdayClosingTime", props.tuesdayClosingTime);
    params.append("wednesdayOpeningTime", props.wednesdayOpeningTime);
    params.append("wednesdayClosingTime", props.wednesdayClosingTime);
    params.append("thursdayOpeningTime", props.thursdayOpeningTime);
    params.append("thursdayClosingTime", props.thursdayClosingTime);
    params.append("fridayOpeningTime", props.fridayOpeningTime);
    params.append("fridayClosingTime", props.fridayClosingTime);
    params.append("saturdayOpeningTime", props.saturdayOpeningTime);
    params.append("saturdayClosingTime", props.saturdayClosingTime);
    params.append("sundayOpeningTime", props.sundayOpeningTime);
    params.append("sundayClosingTime", props.sundayClosingTime);
    params.append("file", props.image_upload);
    params.append("visit_hour", props.visit_hour);
    params.append("is_parent", (props.is_parent) ? 1 : 0);
    props.destination_type.forEach((type) => {
      params.append('destination_type[]', type);
    });
    params.append("tags", JSON.stringify(props.destination_tags));
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallPost(url, params, headers);
};

export const EditDestination = async (props) => {
  const { baseUrl, editdestination } = ApiConfig;

  const url =
    baseUrl +
    editdestination + props.id

    var params = new FormData();
    params.append("name", props.name);
    params.append("slug", props.slug);
    params.append("details", props.details);
    params.append("state_id", props.state_id);
    params.append("city_id", props.city_id);
    params.append("village_id", props.village_id);
    params.append("latitude", props.latitude);
    params.append("longitude", props.longitude);
    params.append("parking_available", props.parking_available);
    params.append("locker_available", props.locker_available);
    params.append("address", props.address);
    params.append("mondayOpeningTime", props.mondayOpeningTime);
    params.append("mondayClosingTime", props.mondayClosingTime);
    params.append("tuesdayOpeningTime", props.tuesdayOpeningTime);
    params.append("tuesdayClosingTime", props.tuesdayClosingTime);
    params.append("wednesdayOpeningTime", props.wednesdayOpeningTime);
    params.append("wednesdayClosingTime", props.wednesdayClosingTime);
    params.append("thursdayOpeningTime", props.thursdayOpeningTime);
    params.append("thursdayClosingTime", props.thursdayClosingTime);
    params.append("fridayOpeningTime", props.fridayOpeningTime);
    params.append("fridayClosingTime", props.fridayClosingTime);
    params.append("saturdayOpeningTime", props.saturdayOpeningTime);
    params.append("saturdayClosingTime", props.saturdayClosingTime);
    params.append("sundayOpeningTime", props.sundayOpeningTime);
    params.append("sundayClosingTime", props.sundayClosingTime);
    params.append("file", props.image_upload);
    params.append("visit_hour", props.visit_hour);
    params.append("is_parent", (props.is_parent) ? 1 : 0);
    props.destination_type.forEach((type) => {
      params.append('destination_type[]', type);
    });
    params.append("tags", JSON.stringify(props.destination_tags));
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
    'Content-Type': 'multipart/form-data',
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (destination_id) => {
  const { baseUrl, editdestination } = ApiConfig;

  const url =
    baseUrl +
    editdestination + destination_id
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const DeleteDestination = async (id) => {
  const { baseUrl, destination } = ApiConfig;

  const url =
    baseUrl +
    destination +
    `?id=${id}`;

  const headers = {
      'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

  return await ApiCallDelete(url, headers);
};

export const GetDestinationUpload = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertMedia = async (destination_id, files) => {
  const { baseUrl, insertMedia } = ApiConfig;

  const params = new FormData();
  params.append("destination_id", destination_id);
  files.forEach( (file) => {
    params.append('files', file);
  });
  const url =
    baseUrl +
    insertMedia 
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallPost(url, params, headers);
};

export const DeleteMedia = async (destination_id, media_id) => {
  const { baseUrl, deleteMedia } = ApiConfig;
  const qs = require('qs');
  let params = qs.stringify({
    'destination_id': destination_id,
    'media_id': media_id
  });

  const url =
    baseUrl +
    deleteMedia;

  const headers = {
      'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

  return await ApiCallPost(url, params, headers);
};

export const Mediaupdate = async (props) => {
  const { baseUrl, updateMediaName } = ApiConfig;
  const url =
    baseUrl +
    updateMediaName
  const tagsName = props.tags.map((tag) => tag.name);
  const params = qs.stringify(
    {
      media_id: props.media_id,
      new_media_name: props.new_media_name,
      media_alt: props.media_alt,
      tags: tagsName,
    },{ arrayFormat: 'brackets' } // Format the array as tags[]=value1&tags[]=value2
  );

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallPost(url, params, headers);
};

export const DeleteFaq = async (id, destination_id) => {
  const { baseUrl, deleteDestinaitonFaq } = ApiConfig;

  const url =
    baseUrl +
    deleteDestinaitonFaq +
    `?id=${id}&destination_id=${destination_id}`;

  const headers = {
      'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

  return await ApiCallGet(url, headers);
};

export const InsertFaq = async (props, destinationId) => {
  const { baseUrl, createDestinaitonFaq } = ApiConfig;

  const url =
    baseUrl +
    createDestinaitonFaq

  let params = qs.stringify({
    "destination_id": destinationId,
    "question": props.question,
    "answer": props.answer,
  });
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
}

export const UpdateFaq = async (props, destinationId) => {
  const { baseUrl, updateDestinaitonFaq } = ApiConfig;

  const url =
    baseUrl +
    updateDestinaitonFaq

  let params = qs.stringify({
    "faq_id": props.id,
    "destination_id": destinationId,
    "question": props.question,
    "answer": props.answer,
  });
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
}

export const SingleListFaq = async (id) => {
  const { baseUrl, singleDestinaitonFaq } = ApiConfig;

  const url =
    baseUrl +
    singleDestinaitonFaq + `?id=${id}`
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};