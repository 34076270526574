import React, {useState} from 'react';
import { Routes, Route } from "react-router-dom";
import Login from "../Login";
import { ErrorPage } from "../../common/Error";
import Dashboard from "../Dashboard";
import { ServerError } from "../../common/Error/serverError";

import ListReview from "../../Components/Review";
import NewReview from "../../Components/Review/CreateReview";
import UpdateReview from '../Review/UpdateReview';
import ListDestination from "../../Components/Destination";
import CreateDestination from '../Destination/CreateDestination';
import UpdateDestination from '../Destination/UpdateDestination';
import Entity from '../Entity/Entity';
import CreateEntity from '../Entity/CreateEntity';
import UpdateEntity from '../Entity/UpdateEntity';
import UserListing from '../User';
import CreateUser from "../User/CreateUser"
import UpdateUser from "../User/UpdateUser"
import RoleListing from "../Roles"
import CreateRole from "../Roles/CreateRole"
import UpdateRole from "../Roles/UpdateRole"
import Country from '../Country/Country';
import CreateCountry from '../Country/CreateCountry';
import UpdateCountry from '../Country/UpdateCountry';
import State from '../State/State';
import CreateState from '../State/CreateState';
import UpdateState from '../State/UpdateState';
import City from '../City/City';
import CreateCity from '../City/CreateCity';
import UpdateCity from '../City/UpdateCity';
import Village from '../Village/Village';
import CreateVillage from '../Village/CreateVillage';
import UpdateVillage from '../Village/UpdateVillage';
import UpdateSettings from '../Settings/UpdateSettings';
import GuestUser from '../GuestUser/Index';
import CreateLongWeekend from '../LongWeekend/CreateLongWeekend';
import UpdateLongWeekend from '../LongWeekend/UpdateLongWeekend';
import LongWeekend from '../LongWeekend/LongWeekend';
import Medialisting from '../MediaListing/index';
import SeasonsListing from '../Season/Season';
import CreateSeason from "../Season/CreateSeason";
import UpdateSeason from "../Season/UpdateSeason";
import DestinationTypesListing from '../DestinationType/Listing';
import CreateDestinationType from "../DestinationType/CreateDestinationType";
import UpdateDestinationType from "../DestinationType/UpdateDestinationType";
import Tags from '../Tags/Tags';
import CreateTag from '../Tags/CreateTag';
import UpdateTag from '../Tags/UpdateTag';
import Trips from '../Trip/TripListing';
import UpdateTrip from '../Trip/UpdateTrip';
import CtaBanners from '../CtaBanner/CtaBanners';
import CreateCtaBanner from '../CtaBanner/CreateCtaBanner';
import UpdateCtaBanner from '../CtaBanner/UpdateCtaBanner';
import QuoteListing from '../Quotes/Quotes';
import CreateQuote from "../Quotes/CreateQuote";
import UpdateQuote from "../Quotes/UpdateQuote";
import JobsListing from '../Job/Job';
import CreateJob from "../Job/CreateJob";
import UpdateJob from "../Job/UpdateJob";
import ApplicantsListing from '../JobApplicant/Applicants';
import ChangePassword from '../User/ChangePassword';
import { useSelector } from 'react-redux';

function RouteNav() {
  const userPermissions = useSelector((state)=>state.userPermissions)

  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/error" element={<ServerError />}></Route>
      <Route path="/admin" element={<Dashboard />}>
      <Route path='change-password' element={<ChangePassword />}/>
      {userPermissions?.users && userPermissions.users.listing && (
        <Route path="users" element={<UserListing />}/>
      )}
      {userPermissions?.users && userPermissions.users.add && (
        <Route path="users/create" element={<CreateUser />}/>
      )}
      {userPermissions?.users && userPermissions.users.edit && (
        <Route path="users/update/:id" element={<UpdateUser />}/>
      )}
      {userPermissions?.seasons && userPermissions.seasons.listing && (
        <Route path="seasons" element={<SeasonsListing />}/>
      )}
      {userPermissions?.seasons && userPermissions.seasons.add && (
        <Route path="seasons/create" element={<CreateSeason />}/>        
      )}
      {userPermissions?.seasons && userPermissions.seasons.edit && (
        <Route path="seasons/update/:id" element={<UpdateSeason />}/>
      )}
      {userPermissions?.destinationType && userPermissions.destinationType.listing && (
        <Route path="destination-types" element={<DestinationTypesListing />}/>
      )}
      {userPermissions?.destinationType && userPermissions.destinationType.add && (
        <Route path="destination-types/create" element={<CreateDestinationType />}/>        
      )}
      {userPermissions?.destinationType && userPermissions.destinationType.edit && (
        <Route path="destination-types/update/:id" element={<UpdateDestinationType />}/>      
      )}
      {userPermissions?.roles && userPermissions.roles.listing && (
        <Route path="roles" element={<RoleListing />}/>
      )}
      {userPermissions?.roles && userPermissions.roles.add && (
        <Route path="roles/create" element={<CreateRole />}/>        
      )}
      {userPermissions?.roles && userPermissions.roles.edit && (
        <Route path="roles/update/:id" element={<UpdateRole />}/>        
      )}  
      {userPermissions?.destinations && userPermissions.destinations.listing && (
        <Route path="destination" element={<ListDestination />}/>
      )}
      {userPermissions?.destinations && userPermissions.destinations.add && (
        <Route path="destination/create" element={<CreateDestination />}/>        
      )}
      {userPermissions?.destinations && userPermissions.destinations.edit && (
        <Route path="destination/update/:id" element={<UpdateDestination />}/>        
      )}
      {userPermissions?.trips && userPermissions.trips.listing && (
        <Route path="trips" element={<Trips />}/>
      )}
      {userPermissions?.trips && userPermissions.trips.edit && (
        <Route path='trips/update/:id' element={<UpdateTrip />}/>        
      )}
      {userPermissions?.tags && userPermissions.tags.listing && (
        <Route path="tags" element={<Tags />}/>
      )}
      {userPermissions?.tags && userPermissions.tags.add && (
        <Route path="tags/create" element={<CreateTag />}/>        
      )}
      {userPermissions?.tags && userPermissions.tags.edit && (
        <Route path="tags/update/:id" element={<UpdateTag />}/>      
      )}
      {userPermissions?.reviews && userPermissions.reviews.listing && (
        <Route path="review" element={<ListReview />}/>
      )}
      {userPermissions?.reviews && userPermissions.reviews.add && (
        <Route path="review/create" element={<NewReview />} />        
      )}
      {userPermissions?.reviews && userPermissions.reviews.edit && (
        <Route path="review/update/:id" element={<UpdateReview />}></Route>        
      )}
      {userPermissions?.entities && userPermissions.entities.listing && (
        <Route path="entity" element={<Entity />}></Route>
      )}
      {userPermissions?.entities && userPermissions.entities.add && (
        <Route path="entity/create" element={<CreateEntity />}></Route>        
      )}
      {userPermissions?.entities && userPermissions.entities.edit && (
        <Route path="entity/update/:id" element={<UpdateEntity />}></Route>      
      )}
      {userPermissions?.countries && userPermissions.countries.listing && (
        <Route path='country' element={<Country />}/>
      )}
      {userPermissions?.countries && userPermissions.countries.add && (
        <Route path='country/create' element={<CreateCountry />}/>        
      )}
      {userPermissions?.countries && userPermissions.countries.edit && (
        <Route path='country/update/:id' element={<UpdateCountry />}/>      
      )}
      {userPermissions?.states && userPermissions.states.listing && (
        <Route path='state' element={<State />}/>
      )}
      {userPermissions?.states && userPermissions.states.add && (
        <Route path='state/create' element={<CreateState />}/>        
      )}
      {userPermissions?.states && userPermissions.states.edit && (
        <Route path='state/update/:id' element={<UpdateState />}/>      
      )}
      {userPermissions?.cities && userPermissions.cities.listing && (
        <Route path='city' element={<City />}/>
      )}
      {userPermissions?.cities && userPermissions.cities.add && (
        <Route path='city/create' element={<CreateCity />}/>        
      )}
      {userPermissions?.cities && userPermissions.cities.edit && (
        <Route path='city/update/:id' element={<UpdateCity />}/>      
      )}
      {userPermissions?.village && userPermissions.village.listing && (
        <Route path='village' element={<Village />}/>
      )}
      {userPermissions?.village && userPermissions.village.add && (
        <Route path='village/create' element={<CreateVillage />}/>        
      )}
      {userPermissions?.village && userPermissions.village.edit && (
        <Route path='village/update/:id' element={<UpdateVillage />}/>      
      )}
      {userPermissions?.settings && userPermissions.settings.edit && (
        <Route path='settings/update' element={<UpdateSettings />}/>
      )}
      {userPermissions?.guestUser && userPermissions.guestUser.listing && (
        <Route path='guestUser' element={<GuestUser />}/>
      )}
      {userPermissions?.longWeekend && userPermissions.longWeekend.listing && (
        <Route path='longWeekend' element={<LongWeekend />}/>
      )}
      {userPermissions?.longWeekend && userPermissions.longWeekend.add && (
        <Route path='longWeekend/create' element={<CreateLongWeekend />}/>        
      )}
      {userPermissions?.longWeekend && userPermissions.longWeekend.edit && (
        <Route path='longWeekend/update/:id' element={<UpdateLongWeekend />}/>      
      )}
      {userPermissions?.mediaListing && userPermissions.mediaListing.listing && (
        <Route path='medialisting' element={<Medialisting />}/>
      )}
      {userPermissions?.ctabanners && userPermissions.ctabanners.listing && (
        <Route path='ctabanners' element={<CtaBanners />}/>
      )}
      {userPermissions?.ctabanners && userPermissions.ctabanners.add && (
        <Route path='ctabanners/create' element={<CreateCtaBanner />}/>
      )}
      {userPermissions?.ctabanners && userPermissions.ctabanners.edit && (
        <Route path='ctabanners/update/:id' element={<UpdateCtaBanner />}/>
      )}
      {userPermissions?.quotes && userPermissions.quotes.listing && (
        <Route path="quotes" element={<QuoteListing />}/>
      )}
      {userPermissions?.quotes && userPermissions.quotes.add && (
        <Route path="quotes/create" element={<CreateQuote />}/>        
      )}
      {userPermissions?.quotes && userPermissions.quotes.edit && (
        <Route path="quotes/update/:id" element={<UpdateQuote />}/>        
      )}
      {userPermissions?.jobs && userPermissions.jobs.listing && (
        <Route path="jobs" element={<JobsListing />}/>
      )}
      {userPermissions?.jobs && userPermissions.jobs.add && (
        <Route path="jobs/create" element={<CreateJob />}/>
      )}
      {userPermissions?.jobs && userPermissions.jobs.edit && (
        <Route path="jobs/update/:id" element={<UpdateJob />}/>
      )}
      {userPermissions?.applicants && userPermissions.applicants.listing && (
        <Route path="applicants" element={<ApplicantsListing />}/>
      )}

      </Route>
      <Route path="*" element={<ErrorPage />}></Route>
    </Routes>
  )
}

export default RouteNav