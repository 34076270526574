import { stringify } from "qs";
import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";

export const GetCtaBanner = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertCtaBanner = async (props) => {
  const { baseUrl, ctaBanners } = ApiConfig;

  const url =
    baseUrl +
    ctaBanners

  var params = new FormData();
  params.append("title", props.title)
  params.append("button_text", props.button_text,)
  params.append("button_link", props.button_link)
  params.append("file", props.image_upload)
  props.trips.forEach((trip) => {
    params.append('trips[]', trip);
  });

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};


export const EditCtaBanner = async (props) => {
  const { baseUrl, ctaBanners } = ApiConfig;
  
  const url =
  baseUrl +
  ctaBanners + `?id=${props.id}`;
  
  var params = new FormData();
  params.append("title", props.title)
  params.append("button_text", props.button_text,)
  params.append("button_link", props.button_link)
  params.append("file", props.image_upload)
  props.trips.forEach((trip) => {
    params.append('trips[]', trip);
  });
  
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (id) => {
  const { baseUrl, ctaBanners } = ApiConfig;

  const url =
    baseUrl +
    ctaBanners + `?id=${id}`;

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const DeleteCtaBanner = async (id) => {
  const { baseUrl, ctaBanners } = ApiConfig;

  const url =
    baseUrl +
    ctaBanners +
    `?id=${id}`;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};