import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, FormControl, InputLabel, Select, Typography, MenuItem, Autocomplete, CircularProgress } from '@mui/material';
import { EditTrip } from '../../api/trip';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './style';
import { mediaBaseUrl } from '../../constants';
import { GetDestination } from '../../api/destination';
import { ApiConfig } from "../../api/config/ApiConfig";
import { GetSeason } from '../../api/season';
import { GetItineraries } from '../../api/trip';
import DescriptionEditor from "../../common/TextEditor/DescriptionEditor"
import ComparisonTripData from "./ComparisonTripData";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const TripForm = (props) => {
    const { baseUrl, getUsersList, getSeasonListing, getItinerariesList, getTravelModeList } = ApiConfig;

    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading)
    const [usersList, setUsersList] = useState([]);
    const [itinerariesList, setItinerariesList] = useState([]);
    const [seasonsList, setSeasonsList] = useState([]);
    const [travelModeList, setTravelModeList] = useState([]);
    const [textFields, setTextFields] = useState(['']);
    const [formData, setFormData] = useState({
        title: "",
        user_id: 0,
        description: "",
        image_upload: "",
        image_name: "",
        status: "unapproved",
        instagram_link: "",
        seasons: [],
        itineraries: [],
        travel_modes: [],
    });

    const [data, setData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { classes } = useStyles();

    const fetchUsersList = async () => {
        const url = new URL(getUsersList, baseUrl);
        dispatch(startLoading());
        const userList = await GetDestination(url);
        switch (userList.status) {
          case "success": 
              dispatch(stopLoading());
              setUsersList(userList.data);
            break;
          case "failed":
            dispatch(stopLoading());
            enqueueSnackbar(userList.message, { variant: "error" });
            break;
          default:
            dispatch(stopLoading());
            dispatch({ type: "API_ERROR", payload: userList });
            navigate("/admin/error");
            break;
        }
    }

    const fetchItinerariesList = async () => {
        dispatch(startLoading());
        const url = new URL(getItinerariesList, baseUrl);
        const itinerariesData = await GetItineraries(url);
        switch (itinerariesData.status) {
            case "success": 
                dispatch(stopLoading());
                setItinerariesList(itinerariesData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(itinerariesData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: itinerariesData });
              navigate("/admin/error");
              break;
        }
    }

    const fetchSeasonList = async () => {
        const url = new URL(getSeasonListing, baseUrl);
        const tripData = await GetSeason(url);
        switch (tripData.status) {
            case "success": 
                dispatch(stopLoading());
                setSeasonsList(tripData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(tripData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: tripData });
              navigate("/admin/error");
              break;
        }
      }

    async function fetchTravelModeData() {
        const url = new URL(
            getTravelModeList,
            baseUrl,
          );
          dispatch(startLoading());
        const travelModeData = await GetSeason(url);
        switch (travelModeData.status) {
            case "success": 
                dispatch(stopLoading());
                setTravelModeList(travelModeData.data);
              break;
            case "failed":
              dispatch(stopLoading());
              enqueueSnackbar(travelModeData.message, { variant: "error" });
              break;
            default:
              dispatch(stopLoading());
              dispatch({ type: "API_ERROR", payload: travelModeData });
              navigate("/admin/error");
              break;
        }
    }  


    useEffect(() => {
        fetchUsersList();
        fetchItinerariesList();
        fetchSeasonList();
        fetchTravelModeData();
        if (props.tripId) {
            setIsEditing(true);
            if(props.tripTempData?.id) {
                setFormData((prevData) => ({
                    ...prevData,
                    ...props.tripTempData,
                }));
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    ...props.tripData,
                }));
            }
        }
    }, []);

    const updateData = async (formData) => {
        try {
            dispatch(startLoading());
            const response = await EditTrip(formData);
            switch (response.status) {
                case "success":
                  dispatch(stopLoading());
                  enqueueSnackbar(response.message, { variant: "success" });
                  navigate('/admin/trips');
                  break;
                case "failed":
                  dispatch(stopLoading());
                  enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
                  break;
                case "error":
                  dispatch(stopLoading());
                  enqueueSnackbar(response.message, { variant: "error" });
                  break;
                default:
                  dispatch(stopLoading());
                  enqueueSnackbar('Something went wrong', { variant: 'error' });
                  break;
            }
        } catch (error) {
            console.log('An error occurred', error);
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value, files } = e.target;
        if (name === "image_upload") {
            setFormData((prevData) => ({
                ...prevData,
                image_upload: files[0],
            }));
        } else if (name.startsWith("itineraries-")) {
            const fieldIndex = parseInt(name.replace("itineraries-", ""), 10);
            const newItineraries = [...formData.itineraries];
            newItineraries[fieldIndex] = value;
            setFormData((prevData) => ({
                ...prevData,
                itineraries: newItineraries,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    }

    const handleTextEditorChange = (content) => {
        setFormData((prevData) => ({
            ...prevData,
            description: content,
        }));
      };    

    const handleAddMoreItinerary = () => {
        setFormData((prevData) => ({
            ...prevData,
            itineraries: [...prevData.itineraries, ''],
        }));
    };

    const handleDeleteItinerary = (index) => {
        const newItineraries = [...formData.itineraries];
        newItineraries.splice(index, 1); // Remove the itinerary at the specified index
        setFormData((prevData) => ({
          ...prevData,
          itineraries: newItineraries,
        }));
    };
    

    const handleSubmit = (e) => {
        e.preventDefault();
        const isFormValid = validateForm(formData);
        if (isFormValid) {
            if (isEditing) {
                updateData(formData);
            }
        } else {
            enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
        }
    };
    
    const validateForm = (formData) => {
        const { title, description } = formData;
        if (!title || !description) {
            return false;
        }        
        return true;
    };
    
    return (
        <>
            <Paper sx={{ height: 60, marginBottom: 2, padding: 1.3, }}>
                <Typography gutterBottom className={classes.paperusers}>
                    {isEditing ? 'Edit Trip' : 'Create Trip'}
                </Typography>
            </Paper>
            <Grid container spacing={0}>
                {isLoading && <Loader />}
                <Paper className={classes.paperCss}>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                label="Title"
                                value={formData.title}
                                onChange={handleInputChange}
                                id="title"
                                name="title"
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <Autocomplete
                                id="tags-outlined"
                                options={usersList}
                                getOptionLabel={(usersList) => `${usersList.first_name} ${usersList.last_name} (${usersList.username}) `}
                                value={usersList.find((user) => user.id === formData.user_id) || null}
                                onChange={(event, newValue) => {
                                    if (newValue?.id) {
                                        handleInputChange({ target: { name: 'user_id', value: newValue ? newValue.id : '' } });
                                      } else {
                                        setFormData(prev => ({ ...prev, user_id: '' }))
                                      }
                                    }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    name="user_id"
                                    label="Trip Admin"
                                    />
                                )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCss} style={{ width: '98%' }}>
                            <DescriptionEditor content={formData.description ? formData.description : ''} handleEditorChange={handleTextEditorChange} />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <InputLabel id="stay-type-label">Seasons</InputLabel>
                                <Select
                                multiple
                                labelId="stay-type-label"
                                className={classes.selectBoxCss}
                                label="Seasons"
                                value={formData.seasons}
                                onChange={handleInputChange}
                                name="seasons"
                                >
                                {seasonsList.map((season) => (
                                    <MenuItem value={season.id} key={season.id}>{season.name}</MenuItem>  
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={travelModeList}
                                getOptionLabel={(travelMode) => travelMode.name}
                                value={travelModeList.filter((travelMode) => formData.travel_modes.includes(travelMode.id))}
                                onChange={(event, newValue) => {
                                    if (newValue && newValue.length > 0) {
                                        const selectedIds = newValue.map((selectedTravelMode) => selectedTravelMode.id);
                                        handleInputChange({ target: { name: 'travel_modes', value: selectedIds } });
                                    } else {
                                        setFormData((prev) => ({ ...prev, travel_modes: [] }));
                                    }
                                    }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    name="travel_modes"
                                    label="Travel Mode"
                                    />
                                )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <FormControl style={{ width: "100%" }} size="small">
                                <InputLabel id="stay-status-label">Status</InputLabel>
                                <Select
                                labelId="Status"
                                className={classes.selectBoxCss}
                                label="Status"
                                value={formData.status}
                                onChange={handleInputChange}
                                name="status"
                                >
                                <MenuItem value="approved">Approved</MenuItem>
                                <MenuItem value="unapproved">Unapproved</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                label="Instagram Link"
                                value={formData.instagram_link}
                                onChange={handleInputChange}
                                id="instagram_link"
                                name="instagram_link"
                                margin="normal"
                            />
                        </Grid>
                        <Grid className={classes.gridCss}>
                            <TextField
                                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                                inputProps={{ accept: 'image/*' }}
                                label="Image"
                                onChange={handleInputChange}
                                name="image_upload"
                                multiple
                                type="file"
                            />
                            {props.tripTempData.image_name &&
                                <img src={mediaBaseUrl + 'uploads/' + props.tripTempData.image_name} width={200} />
                            }
                            {!props.tripTempData?.image_name && props.tripData.image_name &&
                                <img src={mediaBaseUrl + 'uploads/' + props.tripData.image_name} width={200} />
                            }
                        </Grid>                        
                        <Grid className={classes.gridCss} style={{ width: '100%' }}>
                            {formData.itineraries.map((itinerary, index) => (
                                <Grid container key={index} spacing={7}>
                                <Grid item xs={10} style={{ marginBottom: "30px" }}>
                                    <Autocomplete
                                    options={itinerariesList}
                                    getOptionLabel={(option) => option.name}
                                    value={itinerariesList.find((itinerary) => itinerary.name === formData.itineraries[index]) || null}
                                    onChange={(event, newValue) => {
                                        const fieldIndex = index;
                                        const value = newValue.name;
                                        handleInputChange({ target: { name: `itineraries-${fieldIndex}`, value } });
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        const fieldIndex = index;
                                        handleInputChange({ target: { name: `itineraries-${fieldIndex}`, value: newInputValue } });
                                    }}
                                    freeSolo
                                    renderInput={(params) => <TextField {...params} label="Itinerary" />}
                                    />
                                </Grid>
                                {formData.itineraries.length > 1 && (
                                    <Grid item xs={2} style={{ marginTop: "10px" }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleDeleteItinerary(index)}
                                        style={{ backgroundColor: 'red', color: 'white' }}
                                    >
                                        Delete
                                    </Button>
                                    </Grid>
                                )}
                                </Grid>
                            ))}

                            {/* Display a blank Autocomplete when there are no itineraries */}
                            {formData.itineraries.length === 0 && (
                                <Grid container spacing={7}>
                                <Grid item xs={10} style={{ marginBottom: "30px" }}>
                                    <Autocomplete
                                    options={itinerariesList}
                                    getOptionLabel={(option) => option.name}
                                    value={null}
                                    onChange={(event, newValue) => {
                                        const value = newValue.name;
                                        handleInputChange({ target: { name: `itineraries-0`, value } });
                                    }}
                                    freeSolo
                                    renderInput={(params) => <TextField {...params} label="Itinerary" />}
                                    />
                                </Grid>
                                </Grid>
                            )}

                            <Grid className={classes.gridCss} style={{ float: "right", width: "18%" }}>
                                <Button variant="contained" onClick={handleAddMoreItinerary}>
                                Add More Itinerary
                                </Button>
                            </Grid>
                        </Grid>

                    </Box>
                    <Grid className={classes.gridButtonCss}>
                        <Button onClick={handleSubmit} variant="contained" color="primary">
                            {isEditing ? 'Update' : 'Create'}
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
            {props.tripTempData?.id && (
                <ComparisonTripData seasonsList={seasonsList} travelModeList={travelModeList} tripTempData={props.tripTempData} tripData={props.tripData} />
            )}
        </>
    )
}

export default TripForm