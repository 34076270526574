import { stringify } from "qs";
import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";

export const GetState = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertState = async (props) => {
  const { baseUrl, state } = ApiConfig;

  const url =
    baseUrl +
    state

  var params = new FormData();
  params.append("name", props.name)
  params.append("slug", props.slug)
  params.append("country_id", props.country_id,)
  params.append("file", props.image_upload)
  params.append("description", props.description)
  params.append("latitude", props.latitude);
  params.append("longitude", props.longitude);
  params.append("seasons", JSON.stringify(props.seasonData))

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};


export const EditState = async (props) => {
  const { baseUrl, editState } = ApiConfig;
  
  const url =
  baseUrl +
  editState + props.id
  
  var params = new FormData();
  params.append("name", props.name)
  params.append("slug", props.slug)
  params.append("country_id", props.country_id,)
  params.append("starting_city_id", props.starting_city_id,)
  params.append("file", props.image_upload)
  params.append("description", props.description)
  params.append("latitude", props.latitude);
  params.append("longitude", props.longitude);
  params.append("seasons", JSON.stringify(props.seasonData))
  
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (states_id) => {
  const { baseUrl, editState } = ApiConfig;

  const url =
    baseUrl +
    editState + states_id

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const DeleteState = async (id) => {
  const { baseUrl, state } = ApiConfig;

  const url =
    baseUrl +
    state +
    `?id=${id}`;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};