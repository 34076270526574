import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import { GetMedialisting, InsertMediaupdate, Deletemedia } from '../../api/mediaListing';
import { enqueueSnackbar } from "notistack";
import { Box, IconButton, Modal, Button, Paper, Typography, Tooltip, CircularProgress } from "@mui/material";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useStyles } from './style';
import { Edit } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { ApiConfig } from "../../api/config/ApiConfig";
import DeleteButton from '../../common/DeleteButton/DeleteButton';
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';

const Medialisting = (props) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const isLoadingEditImage = useSelector((state) => state.loading);
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { id } = useParams();
  const [validationErrors, setValidationErrors] = useState({});
  const MemoizedMaterialReactTable = memo(MaterialReactTable);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [selectedMediaName, setSelectedMediaName] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedMediaName, setEditedMediaName] = useState("");
  const [selectedMediaId, setSelectedMediaId] = useState(null);
  const [mediaAlt, setMediaAlt] = useState("");
  const userPermissions = useSelector((state)=>state.userPermissions);
  
  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { baseUrl, medialisting } = ApiConfig;

  const fetchInfo = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const page = pagination.pageIndex + 1;
    const per_page = pagination.pageSize;

    const url = new URL(
      medialisting,
      baseUrl,
    );
    url.searchParams.set('page', `${page}`);
    url.searchParams.set('per_page', `${per_page}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('globalFilter', globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    const response = await GetMedialisting(url);
    switch (response.status) {
      case "success":
        const { data, totalCount } = await response;
        setData(data);
        setRowCount(totalCount);
        break;
      case "failed":
        enqueueSnackbar(response.message, { variant: "error" });
        break;
      default:
        dispatch({ type: "API_ERROR", payload: response });
        navigate("/admin/error");
        break;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  useEffect(() => {
    fetchInfo();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const insertData = async () => {
    try {
      const updatedData = data.map(item =>
        item.media_name === selectedMediaName
          ? { ...item, media_name: editedMediaName }
          : item
      );
      if(!editedMediaName && !mediaAlt) {
        return;
      }
      setData(updatedData);
      dispatch(startLoading());
      const response = await InsertMediaupdate({ new_media_name: editedMediaName, media_id: selectedMediaId, media_alt: mediaAlt });
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" });
          setIsModalOpen(false);
          fetchInfo();
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar("Something went wrong", { variant: "error" });
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log("An error occurred", error);
    }
  };

  const handleDeletemedia = useCallback(
    async (clicked, id, index,media_name) => {
      if (clicked) {
        const response = await Deletemedia(id,media_name);
        switch (response.status) {
          case "success":
            enqueueSnackbar(response.message, { variant: "success" });
            data.splice(index, 1);
            setData([...data]);
            fetchInfo();
            break;
          case "failed":
            enqueueSnackbar(response.message, { variant: "warning" });
            break;
          case "error":
            enqueueSnackbar(response.message, { variant: "error" });

            break;
          default:
            dispatch({ type: "API_ERROR", payload: response });
            navigate("/admin/error");
            break;
        }
      }
    },
    [dispatch, navigate, data]
  );


  const columns = useMemo(
    () => [
      {
        accessorKey: "media_name",
        header: "Media Name",
        Cell: ({ row }) => (
          <Box className={classes.media_name_box}>
            <Typography variant="body1">
              {row.original.media_name || "Default Name"}
            </Typography>
            {userPermissions?.mediaListing && userPermissions.mediaListing.edit && (
            <Tooltip arrow placement="left" title="Edit Media">
              <IconButton onClick={() => {
                setSelectedMediaName(row.original.media_name)
                setMediaAlt(row.original.media_alt || "");
                setSelectedMediaId(row.original.id)
                setIsModalOpen(true);
              }}>
                <Edit />
              </IconButton>
            </Tooltip>
            )}
          </Box>
        ),
      },
      {
        accessorKey: "media_alt",
        header: "Media Alt"
      },
      {
        accessorKey: "media_url",
        header: "Image",
        Cell: ({ row }) => (
          <Box className={classes.media_url_box}>
            {(row.original.media_type == "video/mp4" || row.original.media_type == "video/webm") ? (
              <video style={{width: '300px', height: '150px'}} controls>
              <source src={row.original.media_url} type={row.original.media_type} />
              Your browser does not support the video tag.
            </video>
            ) : (
              <img
              alt={row.original.media_alt}
              height={80}
              src={row.original.media_url}
              loading="lazy"
              style={{ width: '30%' }}
            />  
            )}
            <DeleteButton entityType="mediaListing" entityId={row.original.id} entityName={row.original.media_name} index={row.index} handleDeleteRow={handleDeletemedia} titleName="Media" />
          </Box>
        ),
      },
    ],
    []
  );
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        getRowId={(row) => row.id}
        initialState={{ showColumnFilters: false }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error loading data',
            }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="media-name-modal"
        aria-describedby="media-name-description"
      >
        <Box Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
          <Typography id="media-name-modal" component="h2">
            {selectedMediaName}
          </Typography>
          <Typography id="media-name-modal" variant="h6" component="h2">
            New Media Name
          </Typography>
          <Typography id="media-name-description" sx={{ mt: 2 }}>
            <input
              type="text"
              // value={editedMediaName}
              onChange={(e) => setEditedMediaName(e.target.value)}
              style={{ width: '100%', padding: '0.5rem', marginTop: '0.5rem' }}
            />
          </Typography>
          <Typography id="media-name-modal" variant="h6" component="h2" sx={{ mt: 2 }}>
            Media Alt
          </Typography>
          <Typography id="media-name-description" sx={{ mt: 2 }}>
            <input
              type="text"
              value={mediaAlt}
              onChange={(e) => setMediaAlt(e.target.value)}
              style={{ width: '100%', padding: '0.5rem' }}
            />
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={insertData}
              disabled={isLoadingEditImage}
            >
              {isLoadingEditImage ? <CircularProgress size={16} color="inherit" /> : null}
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default Medialisting

