import React, { useState, useEffect } from 'react';
import RoleForm from './form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/userRole';
import { permissons } from '../../common/permissions';

const UpdateRole = () => {
  const { id } = useParams();
  const [roleData, setRoleData] = useState();
  const [permissionsData, setPermissionsData] = useState({});

  const updatePermissions = (permissions, jsonData) => {
    try {
      if(Object.keys(permissions).length > 0) {
        const updatePermissionData = JSON.parse(jsonData);
        {Object.keys(updatePermissionData).map((key) => {
          if(permissions.hasOwnProperty(key)){
            {Object.keys(updatePermissionData[key]).map((subKey) => {
              if(permissions[key].hasOwnProperty(subKey)){
                permissions[key][subKey] = Boolean(updatePermissionData[key][subKey]);
              }
            })}
          } 
        })}
        
      } 
    } catch (error) {
      
    }
    setPermissionsData(permissions);
  };

  const fetchRoleSingleData = async () => {
    try {
      const response = await SingleList(id);
      setRoleData(response.data);
      const permissionsData = permissons();
      updatePermissions(permissionsData, response.data.permission);
    } catch (error) {
      console.error('Error fetching destination data:', error);
    }
  };

  useEffect(() => {
    fetchRoleSingleData();
  }, []);

  if (roleData === undefined) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <RoleForm roleId={id} roleData={{"name": roleData?.name, permissions: permissionsData}} />
    </>
  );
};

export default UpdateRole;
