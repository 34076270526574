
import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useStyles } from './login.style';
import { LoginApi } from '../../api/login';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';

const theme = createTheme();


export default function Login() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { classes } = useStyles();

  useEffect(() => {
    const appToken = localStorage.getItem("app-token");
    if (appToken) {
      navigate('/admin');
    }
  }, [])
  const [signInState, setSignInState] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState({
    message: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSignInState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSignInClick = async (e) => {
    const isFormValid = validateForm(signInState);
    // Perform any sign-in logic here using the email and password values
    if (isFormValid) {
    const response = await LoginApi(signInState.email, signInState.password);
    switch (response.status) {
      case 'success':
        const userPermissionsObject = JSON.parse(response.data.user_permissions);
        dispatch({ type: "ADD_TOKEN", payload: response.data.access_token });
        dispatch({ type: "USER_ROLE", payload: response.data.user_role });
        dispatch({ type: "USER_PERMISSIONS", payload: userPermissionsObject });
        localStorage.setItem("app-token", response.data.access_token);
        localStorage.setItem("user-role", response.data.user_role);
        localStorage.setItem("user-permissions", response.data.user_permissions);
        localStorage.setItem("user-email", response.data.userEmail);
        navigate('/admin');
        setSignInState(true);
        break;
      case 'error':
        const { status, message } = response;
        setErrorMessage((prevState) => ({ ...prevState, "status": status, "message": message }));
        break;
      default:
        dispatch({ type: 'API_ERROR', payload: response });
        navigate('/error');
        break;
    }
  } 
  };


  const validateForm = (signInState) => {
    const { email, password,} = signInState;
    if (!email || !password ) {
      return false;
    }
    return true;
  };


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          className={classes.root}
        >
          <Avatar className={classes.image}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {errorMessage.message && <Alert severity='error' >{(errorMessage.message)}</Alert>}
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              id="email"
              autoComplete="email"
              autoFocus
              value={signInState.email}
              onChange={handleInputChange}
              onKeyDown={e => e.key === 'Enter' ? handleSignInClick(): ""}
            />
            <TextField
              value={signInState.password}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleInputChange}
              onKeyDown={e => e.key === 'Enter' ? handleSignInClick(): ""}
            />
            <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              onClick={handleSignInClick}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}