import React, { useState, useEffect } from 'react';
import TripForm from './form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/trip';

const UpdateTrip = () => {
  const { id } = useParams();
  const [tripData, setTripData] = useState();
  const [tripTempData, setTripTempData] = useState();

  const fetchTripData = async () => {
    try {
      const response = await SingleList(id);
      setTripData(response.data);
      setTripTempData(response.temp_data);
    } catch (error) {
      console.error('Error fetching Trip data:', error);
    }
  };

  useEffect(() => {
    fetchTripData();
  }, []);

  if (tripData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <TripForm tripId={id} tripTempData={tripTempData} tripData={tripData} />
    </>
  );
};

export default UpdateTrip;
