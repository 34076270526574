import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const GetTags = async (url) => {
    const headers = {
        'content-type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallGet(url, headers);
};

export const InsertTags = async (props) => {
    const { baseUrl, tag } = ApiConfig;

    const url =
        baseUrl +
        tag

    let params = qs.stringify({
        'name': props.name,
    });

    const headers = {
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallPost(url, params, headers);
};


export const EditTag = async (props) => {
    const { baseUrl, editTag } = ApiConfig;

    const url =
        baseUrl +
        editTag + props.id

    let params = qs.stringify({
        'name': props.name,
    });

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallPut(url, params, headers);
};

export const SingleList = async (longWeekend_id) => {
    const { baseUrl, editTag } = ApiConfig;

    const url =
        baseUrl +
        editTag + longWeekend_id

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallGet(url, headers);
};

export const DeleteTags = async (id) => {
    const { baseUrl, tag } = ApiConfig;

    const url =
        baseUrl +
        tag +
        `?id=${id}`;

    const headers = {
        'authorization': `Bearer ${localStorage.getItem("app-token")}`
    };

    return await ApiCallDelete(url, headers);
};