import React, { useState, useEffect } from 'react';
import TagForm from './form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/tags';

const UpdateTag = () => {
  const { id } = useParams();
  const [tagData, setTag] = useState();

  const fetchTagData = async () => {
    try {
      const response = await SingleList(id);
      setTag(response.data);
    } catch (error) {
      console.error('Error fetching Tag data:', error);
    }
  };

  useEffect(() => {
    fetchTagData();
  }, []);

  if (tagData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <TagForm tagId={id} tagData={tagData} />
    </>
  );
};

export default UpdateTag;
