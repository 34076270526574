import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Paper, List, Box, Button, Tooltip, Typography, IconButton, Modal, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useStyles } from './Style';
import { ApiConfig } from "../../api/config/ApiConfig";
import { GetDestination, InsertFaq, DeleteFaq, UpdateFaq, SingleListFaq } from '../../api/destination';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import MaterialReactTable from 'material-react-table';
import EditButton from '../../common/EditButton/EditButton';
import DeleteButton from '../../common/DeleteButton/DeleteButton';
import EyeButton from '../../common/EyeButton/EyeButton';
import { startLoading, stopLoading } from '../../store/reducer';
import EditIcon from '@mui/icons-material/Edit';

const DestinationFaq = (props) => {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rowCount, setRowCount] = useState(0);
  const { baseUrl, getDestinaitonFaq } = ApiConfig;
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "question",
        header: "Question",
      },
      {
        accessorKey: "answer",
        header: "Answer",
      }
    ],
    []
  );

  const fetchInfo = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const page = pagination.pageIndex + 1;
    const per_page = pagination.pageSize;
    const url = new URL(
      getDestinaitonFaq,
      baseUrl,
    );
    url.searchParams.set(
      'page',
      `${page}`,
    );
    url.searchParams.set('destination_id', props.destinationId);
    url.searchParams.set('per_page', `${per_page}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('globalFilter', globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    const response = await GetDestination(url);
    switch (response.status) {
      case "success":
        const { data, totalCount } = await response;
        setData(data);
        setRowCount(totalCount);
        break;
      case "failed":
        enqueueSnackbar(response.message, { variant: "error" });
        break;
      default:
        dispatch({ type: "API_ERROR", payload: response });
        navigate("/admin/error");
        break;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleDeleteRow = useCallback(
    async (clicked, id, index, name) => {
      if (clicked) {
        const response = await DeleteFaq(id, props.destinationId);
        switch (response.status) {
          case "success":
            enqueueSnackbar(response.message, { variant: "success" });
            data.splice(index, 1);
            setData([...data]);
            fetchInfo();
            break;
          case "failed":
            enqueueSnackbar(response.message, { variant: "warning" });
            break;
          case "error":
            enqueueSnackbar(response.message, { variant: "error" });

            break;
          default:
            dispatch({ type: "API_ERROR", payload: response });
            navigate("/admin/error");
            break;
        }
      }
    },
    [dispatch, navigate, data]
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const insertData = async (formData) => {
    try {      
      dispatch(startLoading());
      const newData = { ...formData };
      setData((prevData) => [...prevData, newData]);
      // Make the API call to update the data
      const response = await InsertFaq(newData, props.destinationId);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          fetchInfo(0);
          enqueueSnackbar(response.message, { variant: "success" });
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  }

  const updateData = async (formData) => {
    try {
      dispatch(startLoading());
      // Make the API call to update the data
      const response = await UpdateFaq(formData, props.destinationId);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          fetchInfo(0);
          enqueueSnackbar(response.message, { variant: "success" });
          break;
        case "failed":
          dispatch(stopLoading());
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          dispatch(stopLoading());
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      dispatch(stopLoading());
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  }

  const fetchFaqSingleData = async (id) => {
    try {
      const response = await SingleListFaq(id);
      setFormData(response.data);
      setIsEditing(true);
    } catch (error) {
      console.error('Error fetching destination data:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      updateData(formData);
      setIsModalOpen(false);
    } else {
      insertData(formData);
      setIsModalOpen(false);
    }
  }

  useEffect(() => {
    fetchInfo(0);
  }, []);

  return (
    <>
      <Paper className={classes.paperRoles} sx={{ marginTop: 10, padding: 1.3, }}>
        <Typography gutterBottom className={classes.paperusers}>
          Destinations
        </Typography>
        <Button color="primary" onClick={() => {
          setIsModalOpen(true)
          }} variant="contained" >{`Create FAQ`}</Button>
      </Paper >
      <MaterialReactTable
        columns={columns}
        data={data}
        enableEditing={true}
        getRowId={(row) => row.id}
        initialState={{ showColumnFilters: false }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error loading data',
            }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title={`Edit Faq`}>
              <IconButton onClick={() => {
                fetchFaqSingleData(row.original.faq_id);
                setIsModalOpen(true)
              }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <DeleteButton entityType="destinations" entityId={row.original.faq_id} entityName={row.original.name} index={row.index} handleDeleteRow={handleDeleteRow} titleName="FAQ" />
          </Box>
        )}
      />
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
          <Typography variant="h6" component="h2">
          Question
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <input
              type="text"
              name='question'
              value={formData.question}
              onChange={handleInputChange}
              placeholder="Question"
              style={{ width: '100%', padding: '0.5rem' }}
            />
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
          Answer
          </Typography>
          <Typography sx={{ mt: 1 }}>
          <textarea
                    className={`${classes.gridTextInputCss}`}
                    rows="5"
                    type="text"
                    placeholder="Write your answer"
                    maxLength={250}
                    name='answer'
                    value={formData.answer}
                    onChange={handleInputChange}
                />
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button variant="outlined" onClick={() => {
              setFormData("");
              setIsEditing(false);
              setIsModalOpen(false)
              }}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default DestinationFaq