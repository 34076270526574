import axios from 'axios';

const tag = 'ApiCall';
const shouldDisplayLogs =true;

const ConsoleLogs = (tag, message) => {
    if (shouldDisplayLogs) {
      console.log(`${tag}, ${message}`);
    }
  };
  
  export const ApiCallPost = async (url, parameters, headers) => {
   
    try {
    const response = await axios.post(url, parameters, {headers: headers});
    //  ConsoleLogs(
    //    tag + ', ApiCallPost',
    //    `apiDebug, response : ${JSON.stringify(response.data)}`,
    //  );
    return response.data;
  } catch (error) {
    ConsoleLogs(
      tag + ', ApiCallPost',
      `apiDebug, response error: ${JSON.stringify(error)}`,
      );
    
    return error;
  }
};

export const ApiCallGet = async (url, headers) => {
  try {
    const response = await axios.get(url, {headers: headers});
    // ConsoleLogs(
    //   tag + ', ApiCallGet',
    //   `apiDebug, response : ${JSON.stringify(response.data)}`,
    // );
    return response.data;
  } catch (error) {
    ConsoleLogs(
      tag + ', ApiCallGet',
      `apiDebug, response error : ${JSON.stringify(error)}`,
    );
    return error;
  }
};
export const ApiCallDelete = async (url, headers) => {
  try {
    const response = await axios.delete(url, {headers: headers});
    // ConsoleLogs(
    //   tag + ', ApiCallDelete',
    //   `apiDebug, response : ${JSON.stringify(response.data)}`,
    // );
    return response.data;
  } catch (error) {
    ConsoleLogs(
      tag + ', ApiCallDelete',
      `apiDebug, response error : ${JSON.stringify(error)}`,
    );
    return error;
  }
};
export const ApiCallPut = async (url,parameters, headers) => {
  try {
    const response = await axios.put(url, parameters,{headers: headers});
    // ConsoleLogs(
    //   tag + ', ApiCallDelete',
    //   `apiDebug, response : ${JSON.stringify(response.data)}`,
    // );
    return response.data;
  } catch (error) {
    ConsoleLogs(
      tag + ', ApiCallPut',
      `apiDebug, response error : ${JSON.stringify(error)}`,
    );
    return error;
  }
};
