import { ApiCallGet, ApiCallPost, ApiCallDelete, ApiCallPut } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";

export const GetCity = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertCity = async (props) => {
  const { baseUrl, city } = ApiConfig;

  const url =
    baseUrl +
    city
    
  var params = new FormData();
  params.append("name", props.name)
  params.append("slug", props.slug)
  params.append("state_id", props.state_id)
  params.append("file", props.image_upload)
  params.append("description", props.description)
  params.append("latitude", props.latitude);
  params.append("longitude", props.longitude);

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};


export const EditCity = async (props) => {
  const { baseUrl, editCity } = ApiConfig;

  const url =
    baseUrl +
    editCity + props.id

    var params = new FormData();
    params.append("name", props.name)
    params.append("slug", props.slug)
    params.append("state_id", props.state_id)
    params.append("file", props.image_upload)
    params.append("description", props.description)
    params.append("latitude", props.latitude);
    params.append("longitude", props.longitude);

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (cities_id) => {
  const { baseUrl, editCity } = ApiConfig;

  const url =
    baseUrl +
    editCity + cities_id

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const DeleteCity = async (id) => {
  const { baseUrl, city } = ApiConfig;

  const url =
    baseUrl +
    city +
    `?id=${id}`;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};