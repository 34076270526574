import React from 'react';
import CityForm from './form';


const CreateCity = () => {
    return (
    <>
      <CityForm />
    </>  
  )
}

export default CreateCity