import React, { useState, useEffect } from 'react';
import JobForm from './Form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/job';

const UpdateJob = () => {
  const { id } = useParams();
  const [jobData, setJobData] = useState();

  const fetchData = async () => {
    try {
      const response = await SingleList(id);
      setJobData(response.data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (jobData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <JobForm jobId={id} jobData={jobData} />
    </>
  );
};

export default UpdateJob;
