import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Paper, List, Box, Button, Tooltip, Typography, IconButton, Modal, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useStyles } from './Style';
import { mediaBaseUrl, mrdiauploadUrl } from '../../constants';
import ModalImage from '../../common/Popup/Imageclick';
import TablePagination from '@mui/material/TablePagination';
import { ApiConfig } from "../../api/config/ApiConfig";
import { GetDestinationUpload, InsertMedia, DeleteMedia, Mediaupdate } from '../../api/destination';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import ResponsiveDialog from "../../common/Popup/Confirm";
import { DeleteForever, Edit } from '@mui/icons-material';
import { GetTags } from '../../api/tags';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const DestinationMedia = (props) => {
  const formRef = useRef(null);

  const { classes } = useStyles();
  const [uploadImage, setUploadImage] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const { baseUrl, uploadMedialisting, getTags } = ApiConfig;
  const dispatch = useDispatch();
  const [selectedMediaName, setSelectedMediaName] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedMediaName, setEditedMediaName] = useState("");
  const [selectedMediaId, setSelectedMediaId] = useState(null);
  const [tagsList, setTagsList] = useState([]);
  const [selectedMediaTags, setSelectedMediaTags] = useState([]);
  const [mediaAlt, setMediaAlt] = useState("");

  const [isMediaSubmitting, setIsMediaSubmitting] = useState(false);


  const handleChangePage = async(event, newPage) => {
    setPage(newPage);
    fetchInfo(newPage);
  };

  const fetchInfo = async (newPage) => {
    const url = new URL(
      uploadMedialisting + props.destinationId,
      baseUrl,
    );
    url.searchParams.set(
      'page',
      `${newPage+1}`,
    );
    url.searchParams.set('per_page', `${rowsPerPage}`);
    const response = await GetDestinationUpload(url);
    setRowCount(response.totalCount)
    if (response?.data) {
      const uploadImageData = response.data.map((item) => ({
        destinationMediaId: item.destination_media_id,
        mediaId: item.media_id,
        imageUrl: item.media_name,
        media_alt: item.media_alt,
        media_type: item.media_type,
        destination_tags: item.destination_tags,
      }));
      setUploadImage(uploadImageData);
      
    }
  };

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };  
  
  const insertData = async (selectedFiles) => {
    try {
      const response = await InsertMedia(props.destinationId, selectedFiles);
      switch (response.status) {
        case "success":
          enqueueSnackbar(response.message, { variant: "success" });
          resetUploadForm();
          fetchInfo(0);
          break;
        case "failed":
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          break;
      }
    } catch (error) {
      console.log('An error occurred', error);
    }
    setIsMediaSubmitting(false)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsMediaSubmitting(true);
    insertData(selectedFiles);
  }

  const handleDeleteRow = useCallback(
    async (clicked, media_id, index,name) => {
      if (clicked) {
        const response = await DeleteMedia(props.destinationId,media_id,name);
        switch (response.status) {
          case "success":
            enqueueSnackbar(response.message, { variant: "success" });
            uploadImage.splice(index, 1);
            setUploadImage([...uploadImage]);
            fetchInfo(0);
            break;
          case "failed":
            enqueueSnackbar(response.message, { variant: "warning" });
            break;
          case "error":
            enqueueSnackbar(response.message, { variant: "error" });

            break;
          default:
            dispatch({ type: "API_ERROR", payload: response });
            navigate("/admin/error");
            break;
        }
      }
    },
    [dispatch, navigate, uploadImage]
  );
  
  const updatMedia = async () => {
    try {
      const updatedData = uploadImage.map(item =>
        item.media_name === selectedMediaName
          ? { ...item, media_name: editedMediaName }
          : item
      );
      if(!editedMediaName && !mediaAlt) {
        return;
      }
      setUploadImage(updatedData);
      const response = await Mediaupdate({ new_media_name: editedMediaName, media_id: selectedMediaId, media_alt: mediaAlt, tags: selectedMediaTags });
      switch (response.status) {
        case "success":
          enqueueSnackbar(response.message, { variant: "success" });
          setIsModalOpen(false);
          fetchInfo(0);
          break;
        case "failed":
          enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
          break;
        case "error":
          enqueueSnackbar(response.message, { variant: "error" });
          break;
        default:
          enqueueSnackbar("Something went wrong", { variant: "error" });
          break;
      }
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  const handleTagChange = (event, newValue) => {
    setSelectedMediaTags(newValue);
  };  

  const fetchTagsList = async () => {
    const url = new URL(
      getTags,
        baseUrl,
      );
      const tagData = await GetTags(url);
    if (tagData?.data) {
        setTagsList(tagData.data);
    }
  }

  const resetUploadForm = () => {
    formRef.current.reset();
    
  }

  useEffect(() => {
    fetchTagsList();
    fetchInfo(0);
  }, []);
  
  return (
    <Paper sx={{ marginTop: 10, padding: 1.3, }}>
        <Box ref={formRef} component="form" noValidate sx={{ mt: 1 }}>
          <List className={classes.imageupload_style}>
              <input type="file" multiple name="destination_media" accept='image/*, .mp4, .webm' onChange={handleFileChange} />
              <Button disabled={isMediaSubmitting} type="submit" name="upload" variant="contained" color="success" size="small" onClick={handleSubmit}>{isMediaSubmitting ? 'Uploading...' : 'Upload'}</Button>
          </List> 
        </Box>
        <div className={classes.image_style}>
          {uploadImage.map((uploadedImages, index) => (
            <div className={classes.image_wrapper} key={index}>
              <Box className={classes.media_name_box}>
                <Tooltip arrow placement="left" title="Edit Media">
                  <IconButton onClick={() => {
                    const fileNameWithoutExt = uploadedImages.imageUrl.split('.').slice(0, -1).join('.');
                    setSelectedMediaName(fileNameWithoutExt)
                    setMediaAlt(uploadedImages.media_alt || "");
                    setSelectedMediaTags(uploadedImages.destination_tags);
                    setSelectedMediaId(uploadedImages.mediaId)
                    setIsModalOpen(true);
                  }}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Box>
              <ResponsiveDialog
                id={uploadedImages.mediaId}
                name={mediaBaseUrl + 'uploads/' + uploadedImages.imageUrl}
                index={selectedImageIndex}
                head={
                  <Tooltip arrow placement="left" title="Delete Media">
                    <DeleteForever />
                  </Tooltip>
                }
                action="delete"
                onPress={handleDeleteRow}
                className={classes.responsive_dialog}
              />
              {(uploadedImages.media_type == "video/mp4" || uploadedImages.media_type == 'video/webm') ? (
                <video style={{width: '100%', height: '150px'}} controls>
                <source src={mediaBaseUrl + 'uploads/' + uploadedImages.imageUrl} type={uploadedImages.media_type} />
                Your browser does not support the video tag.
              </video>
              ) : (
                <img
                src={mediaBaseUrl + 'uploads/' + uploadedImages.imageUrl}
                key={index}
                className={classes.image_effect}
                onClick={() => setSelectedImageIndex(index)}
                alt={uploadedImages.media_alt}
              />
              )}
            </div>
          ))}
          <TablePagination
            component="div"
            count={rowCount}
            page={page}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </div>
        
        {selectedImageIndex !== null && (
        <ModalImage
            imageUrl={mediaBaseUrl + 'uploads/' + uploadImage[selectedImageIndex].imageUrl}
            onClose={() => setSelectedImageIndex(null)}
        />
        )}

        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="media-name-modal"
          aria-describedby="media-name-description"
        >
          <Box  sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
            <Typography id="media-name-modal" component="h2">
              {selectedMediaName}
            </Typography>
            <Typography id="media-name-modal" variant="h6" component="h2">
              New Media Name
            </Typography>
            <Typography id="media-name-description" sx={{ mt: 1 }}>
              <input
                type="text"
                onChange={(e) => setEditedMediaName(e.target.value)}
                style={{ width: '100%', padding: '0.5rem' }}
              />
            </Typography>
            <Typography id="media-name-modal" variant="h6" component="h2" sx={{ mt: 2 }}>
              Media Alt
            </Typography>
            <Typography id="media-name-description" sx={{ mt: 1 }}>
              <input
                type="text"
                value={mediaAlt}
                onChange={(e) => setMediaAlt(e.target.value)}
                style={{ width: '100%', padding: '0.5rem' }}
              />
            </Typography>
            <Typography id="media-name-modal" variant="h6" component="h2" sx={{ mt: 2 }}>
              Tags
            </Typography>
            <Typography id="media-name-description" sx={{ mt: 1 }}>
              <FormControl style={{ width: '100%' }} size="small">
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={tagsList}
                  getOptionLabel={(tag) => tag.name}
                  value={selectedMediaTags}
                  onChange={handleTagChange}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Destination Tag"
                    />
                  )}
                />
              </FormControl>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 2 }}
                onClick={updatMedia}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Modal>
    </Paper>
  )
}

export default DestinationMedia