import React, { useState, useEffect } from 'react';
import EntityForm from "./form";


const CreateEntity = () => {
    return (
    <>
      <EntityForm />
    </>  
  )
}

export default CreateEntity