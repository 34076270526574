import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Box, Typography, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { InsertDestinationType, EditDestinationType } from '../../api/destinationType';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Style';
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from '../../store/reducer';
import Loader from '../../common/Loader';

const DestinationTypeForm = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading)

  const [formData, setFormData] = useState({
    name: "",
    type: "",
  });

  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { classes } = useStyles();

  useEffect(() => {
    if (props.typeId) {
      setIsEditing(true);
      setFormData(props.typeData);
    }
  }, []);



  const insertData = async (formData) => {
    try {
      dispatch(startLoading());
      const newData = { ...formData };
      setData((prevData) => [...prevData, newData]);
      // Make the API call to update the data
      const response = await InsertDestinationType(newData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" })
          navigate('/admin/destination-types');
          break;
        default:
          dispatch(stopLoading());
          console.log('Error insert data');
          break;
      }
    } catch (error) {
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const updateData = async (formData) => {
    try {
      dispatch(startLoading());
      // Make the API call to update the data
      const response = await EditDestinationType(formData);
      // Check if the API call was successful
      switch (response.status) {
        case "success":
          dispatch(stopLoading());
          enqueueSnackbar(response.message, { variant: "success" })
          navigate('/admin/destination-types');
          break;
        default:
          dispatch(stopLoading());
          console.log('Error updating data');
          break;
      }
    } catch (error) {
      // Handle any network or API call errors
      console.log('An error occurred', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image_upload") {
      setFormData((prevData) => ({
        ...prevData,
        image_upload: files[0], // Store the selected file in the formData
      }));
    } else {
      // For other inputs (non-file inputs), update the form data as before
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      if (isEditing) {
        // Handle update logic
        updateData(formData);
      } else {
        // Handle create logic
        insertData(formData);
      }
    } else {
      enqueueSnackbar(`Please fill required fields`, { variant: "warning" });
    }
  };

  const validateForm = (formData) => {
    // Perform validation checks based on your requirements
    const { name, type } = formData;

    // Check if required fields are filled
    if (!name || !type) {
      return false;
    }
    return true;
  };

  return (
    <>
    <Paper sx={{height: 60,marginBottom:2,padding:1.3,}}>
        <Typography  gutterBottom className={classes.paperusers}>
        {isEditing ? 'Edit Destination Type' : 'Create Destination Type'}
        </Typography>
      </Paper>
      <Grid container spacing={0}>
        {isLoading && <Loader />}
        <Paper className={classes.paperCss}>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Grid className={classes.gridCss}>
              <TextField
                className={`${classes.gridTextInputCss} ${classes.firstChild}`}
                label="Name"
                value={formData.name}
                onChange={handleInputChange}
                id="name"
                name="name"
                margin="normal"
                required
              />
            </Grid>
            <Grid className={classes.gridCss}>
              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="stay-type-label">Type</InputLabel>
                <Select
                  labelId="Type"
                  className={classes.selectBoxCss}
                  label="Type"
                  value={formData.type ? formData.type : ''}
                  onChange={handleInputChange}
                  name="type"
                >
                  <MenuItem value="destination">Destination</MenuItem>
                  <MenuItem value="trip">Trip</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Box>
          <Grid className={classes.gridButtonCss}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default DestinationTypeForm