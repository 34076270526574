import React, { useState, useEffect } from 'react';
import StateForm from './Form';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../api/statepage';

const UpdateState = () => {
  const { id } = useParams();
  const [stateData, setStateData] = useState();

  const fetchStateSingleData = async () => {
    try {
      const response = await SingleList(id);
      setStateData(response.data);
    } catch (error) {
      console.error('Error fetching state data:', error);
    }
  };

  useEffect(() => {
    fetchStateSingleData();
  }, []);

  if (stateData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <StateForm stateId={id} stateData={stateData} />
    </>
  );
};

export default UpdateState;
