import { ApiCallGet, ApiCallPost, ApiCallPut, ApiCallDelete } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";

export const getUserRoles = async(props) => {
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };
  const { baseUrl, getRoles } = ApiConfig;
  const url = `${baseUrl}${getRoles}`;
  return await ApiCallGet(url, headers);
}

export const GetRolesListing = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertRole = async (props) => {
  const { baseUrl, createRole } = ApiConfig;

  const url = baseUrl + createRole;

  const params = {
    name: props.name,
    permission: JSON.stringify(props.permissions),
  };

  const headers = {
    "Content-Type": "application/json", // Set the content type to JSON
    authorization: `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallPost(url, JSON.stringify(params), headers);
};


export const EditRole = async (props) => {
  const { baseUrl, updateRole } = ApiConfig;

  const url =
    baseUrl +
    updateRole + props.id

    let params = qs.stringify({
      'name': props.name,
      permission: JSON.stringify(props.permissions),
    });
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};

export const SingleList = async (id) => {
  const { baseUrl, editRole } = ApiConfig;

  const url =
    baseUrl +
    editRole + id
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const DeleteRole = async (id) => {
  const { baseUrl, deleteRole } = ApiConfig;

  const url =
    baseUrl +
    deleteRole + id;

  const headers = {
      'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};
