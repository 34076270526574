import React, { useState, useEffect } from 'react';
import JobForm from './Form';


const CreateJob = () => {
    return (
    <>
      <JobForm />
    </>  
  )
}

export default CreateJob