import { ApiCallGet, ApiCallPost, ApiCallPut, ApiCallDelete } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";


export const GetUsers = async (url) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const InsertUser = async (props) => {
  const { baseUrl, createUser } = ApiConfig;

  const url =
    baseUrl +
    createUser

  let params = new FormData();
  params.append("first_name", props.first_name)
  params.append("last_name", props.last_name)
  params.append("email", props.email)
  params.append("username", props.username)
  params.append("phone_number", props.phone_number)
  params.append("password", props.password)
  params.append("user_role", props.user_role)
  params.append("instagram_link", props.instagram_link,)
  params.append("file", props.image_upload)
  params.append("description", props.description)
  params.append("is_active", props.is_active)
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};

export const EditUser = async (props) => {
  const { baseUrl, editUser } = ApiConfig;

  const url =
    baseUrl +
    editUser + props.id

    let params = new FormData();
  params.append("first_name", props.first_name)
  params.append("last_name", props.last_name)
  params.append("email", props.email)
  params.append("username", props.username)
  params.append("phone_number", props.phone_number)
  //params.append("password", props.password)
  params.append("user_role", props.user_role)
  params.append("instagram_link", props.instagram_link,)
  params.append("file", props.image_upload)
  params.append("description", props.description)
  params.append("is_active", props.is_active)
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};

export const PasswordChange = async (props) => {
  const { baseUrl, passwordChange } = ApiConfig;

  const url =
    baseUrl +
    passwordChange

  let params = qs.stringify({
    "oldPassword": props.old_password,
    "newPassword": props.new_password,
  });
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};

export const SingleList = async (userId) => {
  const { baseUrl, getUser } = ApiConfig;

  const url =
    baseUrl +
    getUser + userId
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallGet(url, headers);
};

export const DeleteUser = async (id) => {
  const { baseUrl, deleteUser } = ApiConfig;

  const url =
    baseUrl +
    deleteUser + id;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};

export const ResetPasswordByID = async (props) => {
  const { baseUrl, resetPasswordByID } = ApiConfig;

  const url =
    baseUrl +
    resetPasswordByID + props.id

    let params = qs.stringify({
      "newPassword": props.new_password,
    });
    
  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPost(url, params, headers);
};